import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { AUTH_LOGIN_ROUTE } from './app-routing.module';
import { enableGrooveHQWidget } from './groovehq';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    let url: string = `/${next.routeConfig.path}`;
    let params: object = state.root.queryParams;
    return this.checkLogin(url, params);
  }

  canLoad(): boolean {
    return this.auth.isAuthenticated();
  }

  async checkLogin(url: string, params: object) {
    if (this.auth.isAuthenticated()) {
      if (!this.auth.currentOrganization?.id) {
        await this.auth.init();

        if (!window['groove'] && environment.enableGrooveHQ) {
          enableGrooveHQWidget(
            this.auth.getCurrentLanguage(),
            this.auth.getUser().email,
            this.auth.currentOrganization.code,
          );
        }
      }
      return true;
    }

    this.auth.redirectUrl = url;
    this.auth.redirectParams = params;

    this.router.navigate([AUTH_LOGIN_ROUTE]);
    return false;
  }
}
