import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import Highcharts from 'highcharts';

@Directive({
  selector: '[appGauge]',
})
export class GaugeDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() value: any;
  @Input() min: number = 0;
  @Input() max: number = 127;
  gauge: any;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.drawGauge();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.drawGauge();
  }

  ngOnInit() {
    this.drawGauge();
  }

  drawGauge() {
    if (this.gauge) {
      this.gauge.destroy();
    }

    const gaugeOptions: any = {
      chart: {
        renderTo: this.el.nativeElement,
        type: 'solidgauge',
        height: '300px',
      },
      title: null,
      pane: {
        center: ['50%', '85%'],
        size: '140%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        stops: [
          [0.1, '#55BF3B'], // green
          [0.5, '#DDDF0D'], // yellow
          [0.9, '#DF5353'], // red
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -70,
        },
        labels: {
          y: 16,
        },
        min: this.min,
        max: this.max,
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          data: [this.value],
          dataLabels: {
            format: '<div style="text-align:center">' + '<span style="font-size:25px">{y}</span><br/>' + '</div>',
          },
        },
      ],
    };

    this.gauge = Highcharts.chart(gaugeOptions);
  }

  ngOnDestroy(): void {
    this.gauge.destroy();
  }
}
