import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ADMIN_CLUSTER_DELEGATION_SUFFIX,
  ADMIN_CLUSTER_IA_PARAMETERS_SUFFIX,
  ADMIN_CLUSTER_MAINTENANCE_SUFFIX,
  ADMIN_CLUSTER_METRICS_SUFFIX,
  ADMIN_CLUSTER_PREFIX,
  ADMIN_CLUSTER_SITES_SUFFIX,
  ADMIN_PREFIX,
  API_DOC_ROUTE,
  DASHBOARD_ROUTE,
  JOURNAL_ROUTE,
  MY_ACCOUNT_PREFIX,
  MY_LOGS_ROUTE,
  MY_ORGANIZATION_SUFFIX,
  MY_PROFILE_SUFFIX,
  MY_SITES_ROUTE,
} from 'app/app-routing.module';
import { CLUSTERS_SUFFIX, CLUSTER_TYPE_SUFFIX, ELASTICSEARCH_SUFFIX, IA_RECOMMENDED_PARAMETERS_SUFFIX, ORGANIZATIONS_SUFFIX, SITES_SUFFIX, USERS_SUFFIX } from 'app/theme/admin/admin-routing.module';
import { Features, SHOW_ADMIN, SHOW_ADMIN_CLUSTER, SHOW_API_DOC } from 'app/services/auth.service';

export interface BadgeItem {
  type: string;
  value: string;
}

interface BaseItem {
  name: string;
  state: string;
  target?: boolean;
  acl?: Features[];
  beta?: boolean;
  short_label?: string;
  badge?: BadgeItem[];
}

export interface ChildrenItems extends BaseItem {
  type?: string;
  children?: ChildrenItems[];
  icon?: string;
}

export interface MainMenuItems extends BaseItem {
  main_state?: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
  href?: string;
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

@Injectable()
export class MenuItems {
  MENUITEMS: Menu[] = [
    {
      label: 'Navigation',
      main: [
        {
          state: DASHBOARD_ROUTE,
          short_label: 'TB',
          name: 'Tableau de bord',
          type: 'link',
          icon: 'icon-layout',
        },
        {
          state: MY_LOGS_ROUTE,
          short_label: 'TB',
          name: 'Tracker',
          type: 'link',
          acl: [],
          icon: 'icon-search',
        },
        {
          state: MY_SITES_ROUTE,
          short_label: 'TB',
          name: 'Paramètre',
          type: 'link',
          acl: [],
          icon: 'icon-settings',
        },
        {
          state: MY_ACCOUNT_PREFIX,
          short_label: 'TB',
          name: 'MyAccount',
          type: 'sub',
          icon: 'icon-user',
          children: [
            {
              state: MY_PROFILE_SUFFIX,
              name: 'MyProfile',
            },
            {
              state: MY_ORGANIZATION_SUFFIX,
              name: 'MyOrganization',
            },
          ],
        },
        {
          state: JOURNAL_ROUTE,
          short_label: 'TB',
          name: 'Journal',
          type: 'link',
          beta: true,
          acl: [],
          icon: 'icon-agenda',
        },
        {
          state: API_DOC_ROUTE,
          short_label: 'TB',
          name: 'API Doc',
          type: 'link',
          acl: [SHOW_API_DOC],
          icon: 'icon-book',
        },
        {
          state: ADMIN_PREFIX,
          short_label: 'PM',
          name: 'Administration',
          type: 'sub',
          acl: [SHOW_ADMIN],
          icon: 'icofont icofont-settings-alt',
          children: [
            {
              state: ORGANIZATIONS_SUFFIX,
              name: 'Organizations',
            },
            {
              state: USERS_SUFFIX,
              name: 'Utilisateurs',
            },
            {
              state: CLUSTERS_SUFFIX,
              name: 'Clusters',
            },
            {
              state: CLUSTER_TYPE_SUFFIX,
              name: 'ClusterType',
            },
            {
              state: ELASTICSEARCH_SUFFIX,
              name: 'Elasticsearch',
            },
            {
              state: IA_RECOMMENDED_PARAMETERS_SUFFIX,
              name: 'Paramètres IA recommandés',
            },
            {
              state: SITES_SUFFIX,
              name: 'Sites',
            },
          ],
        },
        {
          state: ADMIN_CLUSTER_PREFIX,
          short_label: 'AC',
          name: 'Admin cluster',
          type: 'sub',
          acl: [SHOW_ADMIN_CLUSTER],
          icon: 'icon-server',
          children: [
            {
              state: DASHBOARD_ROUTE,
              short_label: 'TB',
              name: 'Tableau de bord',
              type: 'link',
              acl: [],
              icon: 'icon-home',
            },
            {
              state: MY_LOGS_ROUTE,
              short_label: 'TB',
              name: 'Logs',
              type: 'link',
              acl: [],
              icon: 'icon-search',
            },
            {
              state: ADMIN_CLUSTER_SITES_SUFFIX,
              name: 'Sites',
            },
            {
              state: ADMIN_CLUSTER_DELEGATION_SUFFIX,
              name: 'Delegation',
            },
            {
              state: JOURNAL_ROUTE,
              name: 'Journal',
            },
            {
              state: ADMIN_CLUSTER_METRICS_SUFFIX,
              name: 'Metrics',
            },
            {
              state: ADMIN_CLUSTER_IA_PARAMETERS_SUFFIX,
              name: 'Paramètres IA',
              acl: [SHOW_ADMIN],
            },
            {
              state: ADMIN_CLUSTER_MAINTENANCE_SUFFIX,
              name: 'Maintenance',
              acl: [SHOW_ADMIN],
            },
          ],
        },
      ],
    },
  ];

  constructor(private translate: TranslateService) {}

  getAll() {
    this.translate.get('Navigation').subscribe((value) => {
      this.MENUITEMS[0].label = value;
    });
    this.translate.get('TableauDeBord').subscribe((value) => {
      this.MENUITEMS[0].main[0].name = value;
    });
    this.translate.get('Tracker').subscribe((value) => {
      this.MENUITEMS[0].main[1].name = value;
    });
    this.translate.get('Parametrage').subscribe((value) => {
      this.MENUITEMS[0].main[2].name = value;
    });
    this.translate.get('MyAccount').subscribe((value) => {
      this.MENUITEMS[0].main[3].name = value;
    });
    this.translate.get('MyProfile').subscribe((value) => {
      this.MENUITEMS[0].main[3].children[0].name = value;
    });
    this.translate.get('MyOrganization').subscribe((value) => {
      this.MENUITEMS[0].main[3].children[1].name = value;
    });
    this.translate.get('Journal').subscribe((value) => {
      this.MENUITEMS[0].main[4].name = value;
    });
    this.translate.get('API Doc').subscribe((value) => {
      this.MENUITEMS[0].main[5].name = value;
    });
    this.translate.get('admin').subscribe((value) => {
      this.MENUITEMS[0].main[6].name = value;
    });
    this.translate.get('Organization').subscribe((value) => {
      this.MENUITEMS[0].main[6].children[0].name = value + 's';
    });
    this.translate.get('utilisateurs').subscribe((value) => {
      this.MENUITEMS[0].main[6].children[1].name = value;
    });
    this.translate.get('Clusters').subscribe((value) => {
      this.MENUITEMS[0].main[6].children[2].name = value;
    });
    this.translate.get('ClusterType').subscribe((value) => {
      this.MENUITEMS[0].main[6].children[3].name = value;
    });
    this.translate.get('Elasticsearchs').subscribe((value) => {
      this.MENUITEMS[0].main[6].children[4].name = value;
    });
    this.translate.get('RecommendedParameters').subscribe((value) => {
      this.MENUITEMS[0].main[6].children[5].name = value;
    });
    this.translate.get('site').subscribe((value) => {
      this.MENUITEMS[0].main[6].children[6].name = value;
    });
    this.translate.get('TableauDeBord').subscribe((value) => {
      this.MENUITEMS[0].main[7].children[0].name = value;
    });
    this.translate.get('Delegation').subscribe((value) => {
      this.MENUITEMS[0].main[7].children[3].name = value;
    });
    this.translate.get('Journal').subscribe((value) => {
      this.MENUITEMS[0].main[7].children[4].name = value;
    });
    this.translate.get('Metrics').subscribe((value) => {
      this.MENUITEMS[0].main[7].children[5].name = value;
    });
    this.translate.get('IAParameters').subscribe((value) => {
      this.MENUITEMS[0].main[7].children[6].name = value;
    });
    this.translate.get('Maintenance').subscribe((value) => {
      this.MENUITEMS[0].main[7].children[7].name = value;
    });
    return this.MENUITEMS;
  }
}
