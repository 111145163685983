import { SitesService } from 'app/services/sites.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from './global';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { destroyGrooveHQWidget } from './groovehq';
import { DASHBOARD_ROUTE } from './app-routing.module';
import { firstValueFrom } from 'rxjs';
import { SpringPage } from 'app/shared/page';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private sites: SitesService,
    private router: Router,
  ) {}

  async getOrganizations(searchTerm: string = '', page = null): Promise<SpringPage<any>> {
    let params = new HttpParams()
      .set('page', page)
      .set('companyName', searchTerm)
      .set('ownerEmail', searchTerm)
      .set('ownerLastName', searchTerm)
      .set('ownerFirstName', searchTerm)
      .set('code', searchTerm);

    return firstValueFrom(this.http.get<SpringPage<any>>(`${Global.baseUrl}admin/organizations`, { params }));
  }

  public async getOrganizationUsers(id, page = 0, size = 10, searchTerm = ''): Promise<Object> {
    let url = `${Global.baseUrl}admin/organizations/${id}/users?page=${page}&size=${size}`;
    if (searchTerm) url += `&email=${searchTerm}`;
    return firstValueFrom(this.http.get(url));
  }

  async changeSiteOrganizationOwner(siteName: string, organizationId: string): Promise<Object> {
    return firstValueFrom(this.http.put(Global.baseUrl + `admin/sites/${siteName}/owner`, { id: organizationId }));
  }

  async deleteOrganization(organizationId): Promise<Object> {
    return firstValueFrom(this.http.delete(`${Global.baseUrl}admin/organizations/${organizationId}`));
  }

  async connectAsUser(userId, organizationId) {
    this.http
      .post(Global.baseUrl + 'utilisateur/connectAs', {
        data: { id: userId },
      })
      .subscribe((res: any) => {
        this.auth.purgeContext();
        this.auth.purgeSession();
        this.sites.purgeContext();
        this.auth.setCacheOrganizationId(userId, organizationId);
        this.auth.setUser(res.item);
        destroyGrooveHQWidget();
        window.location.href = `/#/${DASHBOARD_ROUTE}`;
        window.location.reload();
      });
  }
}

export interface OrganizationListItem {
  id: number;
  code: string;
  companyName: string;
  street: string;
  zipCode: string;
  city: string;
  countryCode: string;
  owner: {
    id: any;
    email: string;
    firstName: string;
    lastName: string;
  };

  createdAt: number;
  updatedAt: number;
  nbSites: number;
}
