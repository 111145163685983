/**
 * Created by stephaneouattara on 26/02/2019.
 */
import { UntypedFormControl, UntypedFormGroup, NgForm, FormGroupDirective } from '@angular/forms';

export class PasswordValidator {
  static areEqual(formGroup: UntypedFormGroup) {
    let value;
    let valid = true;
    for (let key in formGroup.controls) {
      if (key == 'password' || key == 'confirm_password') {
        if (formGroup.controls.hasOwnProperty(key)) {
          let control: UntypedFormControl = <UntypedFormControl>formGroup.controls[key];

          if (value === undefined) {
            value = control.value;
          } else {
            if (value !== control.value) {
              valid = false;
              break;
            }
          }
        }
      }
    }

    if (valid) {
      return null;
    }

    return {
      areEqual: true,
    };
  }
}
