<div class="card order-card" [ngClass]="bgColor">
  <div class="counts">
    <div class="flex space-between">
      <div class="counts-text">
        <h6
          class="m-b-20  {{ totalKey != ROBOT ? 'clickable' : '' }} text-ellipsis"
          (click)="totalKey != ROBOT && trackAction.emit(action)"
        >
          {{ title }}
        </h6>
        <ng-container *ngIf="!isLoading">
          <h2 class="text-ellipsis" *ngIf="totals" [title]="totals[totalKey] | numberFormat">
            {{ totals[totalKey] | numberFormat }}
          </h2>
          <h2 class="text-ellipsis" *ngIf="!totals">0</h2>
        </ng-container>
        <span *ngIf="isLoading" class="text-placeholder cards">
          <span class="activity"></span>
        </span>
      </div>
      <app-donut [data]="breakdown?.[totalKey]" (sliceClicked)="selectSite.emit($event.name)"></app-donut>
    </div>
  </div>
</div>
