import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Global } from '../../../../global';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AUTH_LOGIN_ROUTE, EXTERNAL_COMPANY_WEBSITE_LINK } from 'app/app-routing.module';
import { validatePasswordRestrictions } from '../../../../shared/validators/validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  user: any = {};
  email: string;
  token: string;
  tokenFound: boolean = true;

  EXTERNAL_COMPANY_WEBSITE_LINK = EXTERNAL_COMPANY_WEBSITE_LINK;

  // tslint:disable-next-line:max-line-length
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private http: HttpClient,
    private actRoute: ActivatedRoute,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.translate.use('fr');
    this.email = this.actRoute.snapshot.paramMap.get('email');
    this.token = this.actRoute.snapshot.paramMap.get('token');
    this.validateToken();
  }

  validateToken() {
    this.http
      .post(Global.baseUrl + 'utilisateur/validateToken/reset', {
        data: {
          email: this.email,
          token: this.token,
        },
      })
      .subscribe((res: any) => {
        if (res.hasError) {
          this.tokenFound = false;
        }
      });
  }

  resetPassword(user: NgForm) {
    if (!validatePasswordRestrictions(user.value.password)) {
      this.toastr.error(this.translate.instant('MotDePasseFort'));
      return;
    }
    if (user.value.password !== user.value.confirm) {
      this.toastr.error(
        this.translate.instant('MotDePasseNeCorrespondentPas'),
        this.translate.instant(this.translate.instant('Erreur')),
      );
      return;
    }
    this.http
      .post(Global.baseUrl + 'utilisateur/changePassword', {
        data: {
          email: this.email,
          password: user.value.password,
          token: this.token,
        },
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.toastr.success(this.translate.instant('MDPChangeSucces'), this.translate.instant('Succes'));
          this.router.navigate([AUTH_LOGIN_ROUTE]);
        } else {
          const errorMsg = res.status.message || this.translate.instant('ErreurSurvenue');
          this.toastr.error(errorMsg, this.translate.instant('Erreur'));
        }
      });
  }
}
