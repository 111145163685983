import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth.guard';
import { ClusterTypeComponent } from './cluster-type/cluster-type.component';
import { ClusterComponent } from './cluster/cluster.component';
import { ElasticsearchComponent } from './elasticsearch/elasticsearch.component';
import { HoteComponent } from './hote/hote.component';
import { IaRecommendedParametersComponent } from './ia-recommended-parameters/ia-recommended-parameters.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';

export const USERS_SUFFIX = 'users';
export const ORGANIZATIONS_SUFFIX = 'organizations';
export const SITES_SUFFIX = 'sites';
export const CLUSTERS_SUFFIX = 'clusters';
export const CLUSTER_TYPE_SUFFIX = 'cluster-type';
export const ELASTICSEARCH_SUFFIX = 'elasticsearch';
export const IA_RECOMMENDED_PARAMETERS_SUFFIX = 'ia-recommended-parameters';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Administration',
      status: false,
    },
    children: [
      {
        path: USERS_SUFFIX,
        component: UtilisateurComponent,
        canActivate: [AuthGuard],
      },
      {
        path: ORGANIZATIONS_SUFFIX,
        component: OrganizationsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: SITES_SUFFIX,
        component: HoteComponent,
        canActivate: [AuthGuard],
      },
      {
        path: CLUSTERS_SUFFIX,
        component: ClusterComponent,
        canActivate: [AuthGuard],
      },
      {
        path: CLUSTER_TYPE_SUFFIX,
        component: ClusterTypeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: ELASTICSEARCH_SUFFIX,
        component: ElasticsearchComponent,
        canActivate: [AuthGuard],
      },
      {
        path: IA_RECOMMENDED_PARAMETERS_SUFFIX,
        component: IaRecommendedParametersComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
