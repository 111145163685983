import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../global';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  roles = {
    viewer: 'VIEWER',
    siteAdmin: 'SITE_ADMIN',
    clusterAdmin: 'CLUSTER_ADMIN',
    accountAdmin: 'ORGANIZATION_ADMIN',
    owner: 'OWNER',
    admin: 'admin',
  };

  public rolesMap = [
    { libelle: 'Viewer', value: this.roles.viewer },
    { libelle: 'SiteAdmin', value: this.roles.siteAdmin },
    { libelle: 'ClusterAdmin', value: this.roles.clusterAdmin },
    { libelle: 'AccountAdmin', value: this.roles.accountAdmin },
    { libelle: 'Owner', value: this.roles.owner },
  ];

  constructor(private http: HttpClient) {}

  async loadUsersPaginated(searchTerm, index, size) {
    return firstValueFrom(this.http.get(Global.baseUrl + `admin/users?page=${index}&size=${size}&filter=${searchTerm}`, {}));
  }
}
