import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/services/auth.service';
import { CountryService } from 'app/services/country.service';
import {
  ActionEnum,
  CacheStatusFilters,
  CauseEnum,
  Filter,
  FiltersEnum,
  FilterType,
  isIntervalFilter,
  isMultiInNotInFilter,
  isSingleInputFilter,
  LogFiltersType,
} from 'app/services/logs.service';
import { TooltipMenuComponent } from 'app/shared/tooltip-menu/tooltip-menu.component';
import { copyObject, defined, sortAlphabetically } from 'app/shared/utils/data-utils';
import { ToastrService } from 'ngx-toastr';
import {
  validateHttpCode,
  validateHttpMethod,
  validateIp,
  validatePositiveInt,
  validatePositiveInt0to100,
} from '../../../shared/validators/validators';

export interface FilterDataInNotIn {
  [LogFiltersType.IN];
  [LogFiltersType.NOTIN];
}

export interface FilterDataLteGte {
  [LogFiltersType.LTE];
  [LogFiltersType.GTE];
}

enum Mode {
  ADD = 'add',
  EDIT = 'edit',
}

@Component({
  selector: 'app-my-logs-filters',
  templateUrl: './my-logs-filters.component.html',
  styleUrls: ['./my-logs-filters.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class MyLogsFiltersComponent implements OnInit {
  @Input() isLoading: boolean = false;

  @Output() filtersEmitter = new EventEmitter();

  @ViewChild('tooltipNewFilter') tooltipNewFilter: TooltipMenuComponent;
  @ViewChild('tooltipFilterMenu') tooltipFilterMenu: TooltipMenuComponent;

  lang: string;

  Mode = Mode;
  mode: Mode;

  currentFilterElementRef: HTMLElement = null;
  currentFilter: Filter = null;
  selectedFilterSlug: string = '';

  FilterType = FilterType;
  LogFiltersType = LogFiltersType;

  filters: Filter[] = [];

  storeFilters: Filter[] = [
    {
      key: FiltersEnum.IP,
      slug: this.translate.instant('IPs'),
      type: FilterType.MULTI_IN_NOTIN,
      tooltip: this.translate.instant('TooltipIPsMyLogs'),
      active: true,
      canAddChoice: true,
      data: { [LogFiltersType.IN]: [], [LogFiltersType.NOTIN]: [] },
    },
    {
      key: FiltersEnum.STATUS_CODE,
      slug: this.translate.instant('StatusCodes'),
      type: FilterType.MULTI_IN_NOTIN,
      tooltip: this.translate.instant('StatusCodesTooltip'),
      active: true,
      canAddChoice: true,
      choices: [
        { slug: '2XX', value: '2XX' },
        { slug: '200', value: '200' },
        { slug: '201', value: '201' },
        { slug: '204', value: '204' },
        { slug: '206', value: '206' },
        { slug: '3XX', value: '3XX' },
        { slug: '301', value: '301' },
        { slug: '302', value: '302' },
        { slug: '304', value: '304' },
        { slug: '4XX', value: '4XX' },
        { slug: '400', value: '400' },
        { slug: '401', value: '401' },
        { slug: '403', value: '403' },
        { slug: '404', value: '404' },
        { slug: '405', value: '405' },
        { slug: '499', value: '499' },
        { slug: '4XX', value: '4XX' },
        { slug: '5XX', value: '5XX' },
        { slug: '500', value: '500' },
        { slug: '502', value: '502' },
        { slug: '503', value: '503' },
        { slug: '504', value: '504' },
      ],
      data: { [LogFiltersType.IN]: [], [LogFiltersType.NOTIN]: [] },
    },
    {
      key: FiltersEnum.ACTION,
      slug: this.translate.instant('Action'),
      type: FilterType.MULTI_IN_NOTIN,
      tooltip: this.translate.instant('ActionTooltip'),
      choices: [
        {
          slug: this.translate.instant('Autorisees'),
          value: ActionEnum.AUTHORIZED,
        },
        {
          slug: this.translate.instant('Suspectes'),
          value: ActionEnum.SUSPICIOUS,
        },
        {
          slug: this.translate.instant('Bloquees'),
          value: ActionEnum.BLOCKED,
        },
      ],
      active: true,
      data: { [LogFiltersType.IN]: [], [LogFiltersType.NOTIN]: [] },
    },
    {
      key: FiltersEnum.CAUSE,
      slug: this.translate.instant('Cause'),
      type: FilterType.MULTI_IN_NOTIN,
      tooltip: this.translate.instant('CauseTooltip'),
      choices: [
        {
          slug: this.translate.instant('AuthorizedByAI'),
          value: CauseEnum.ANALYZED_OK,
        },
        {
          slug: this.translate.instant('PassthroughByRule'),
          value: CauseEnum.PASSTHROUGH_RULE,
        },
        {
          slug: this.translate.instant('WhitelistedIp'),
          value: CauseEnum.WHITELISTED_IP,
        },
        {
          slug: this.translate.instant('urlException'),
          value: CauseEnum.URL_EXCEPTION,
        },
        {
          slug: this.translate.instant('BlockedByAI'),
          value: CauseEnum.ANALYZED_KO,
        },
        {
          slug: this.translate.instant('Geoblocked'),
          value: CauseEnum.GEO_BLOCKED,
        },
        {
          slug: this.translate.instant('BlockedByRule'),
          value: CauseEnum.BLOCKED_BY_RULE,
        },
      ],
      active: true,
      data: { [LogFiltersType.IN]: [], [LogFiltersType.NOTIN]: [] },
    },
    {
      key: FiltersEnum.COUNTRY,
      slug: this.translate.instant('Pays'),
      type: FilterType.MULTI_IN_NOTIN,
      choices: [],
      active: true,
      data: { [LogFiltersType.IN]: [], [LogFiltersType.NOTIN]: [] },
    },
    {
      key: FiltersEnum.PATH,
      slug: this.translate.instant('ExactPath'),
      type: FilterType.MULTI_IN_NOTIN,
      active: true,
      canAddChoice: true,
      data: { [LogFiltersType.IN]: [], [LogFiltersType.NOTIN]: [] },
    },
    {
      key: FiltersEnum.PATH_STARTS_WITH,
      slug: this.translate.instant('PathStartsWith'),
      type: FilterType.MULTI_IN_NOTIN,
      active: true,
      canAddChoice: true,
      data: { [LogFiltersType.IN]: [], [LogFiltersType.NOTIN]: [] },
    },
    {
      key: FiltersEnum.RESPONSE_TIME_GTE,
      slug: this.translate.instant('ResponseTimeGte'),
      type: FilterType.SINGLE_INPUT,
      suffix: 'ms',
      active: true,
      data: 0,
    },
    {
      key: FiltersEnum.CONTENT_LENGTH_GTE,
      slug: this.translate.instant('ContentLengthGte'),
      type: FilterType.SINGLE_INPUT,
      suffix: this.translate.instant('bytes'),
      active: true,
      data: 0,
    },
    {
      key: FiltersEnum.CACHE_STATUS,
      slug: this.translate.instant('CacheStatus.FilterLabel'),
      type: FilterType.MULTI_IN_NOTIN,
      tooltip: this.translate.instant('CacheTooltip'),
      choices: [
        { slug: this.translate.instant('CacheStatus.HIT'), value: CacheStatusFilters.HIT },
        { slug: this.translate.instant('CacheStatus.MISS'), value: CacheStatusFilters.MISS },
        { slug: this.translate.instant('CacheStatus.NONE'), value: CacheStatusFilters.NONE },
      ],
      active: true,
      data: { [LogFiltersType.IN]: [], [LogFiltersType.NOTIN]: [] },
    },
    {
      key: FiltersEnum.METHOD,
      slug: this.translate.instant('Method'),
      type: FilterType.MULTI_IN_NOTIN,
      choices: [
        { slug: 'GET', value: 'GET' },
        { slug: 'POST', value: 'POST' },
        { slug: 'PUT', value: 'PUT' },
        { slug: 'DELETE', value: 'DELETE' },
      ],
      canAddChoice: true,
      active: true,
      data: { [LogFiltersType.IN]: [], [LogFiltersType.NOTIN]: [] },
    },
    {
      key: FiltersEnum.CREDIBILITY_BETWEEN,
      slug: this.translate.instant('CredibilityBetween'),
      type: FilterType.INTERVAL,
      canAddChoice: true,
      active: true,
      suffix: '%',
      data: { [LogFiltersType.GTE]: 0, [LogFiltersType.LTE]: 0 },
    },
  ];

  get availablesFilters(): Filter[] {
    return this.mode == Mode.ADD // do not propose filters already set
      ? this.storeFilters.filter((availableFilter) => !this.filters.find((f) => f.key == availableFilter.key))
      : this.storeFilters; // give whole list for edit mode, in order to retrieve currently edited field name
  }

  get canAddEditFilter(): boolean {
    switch (this.currentFilter?.type) {
      case FilterType.SINGLE_INPUT:
        const data = this.currentFilter?.data as string;
        return !!data.length;
      case FilterType.INTERVAL:
        return (
          defined(this.currentFilter?.data[LogFiltersType.GTE]) && defined(this.currentFilter?.data[LogFiltersType.LTE])
        );
      case FilterType.MULTI_IN_NOTIN:
        return (
          !!this.currentFilter?.data[LogFiltersType.IN].length ||
          !!this.currentFilter?.data[LogFiltersType.NOTIN].length
        );
    }
  }

  get hasFilterActive(): boolean {
    return this.filters.some((f) => f.active);
  }

  constructor(
    public translate: TranslateService,
    private auth: AuthService,
    private toastr: ToastrService,
    private countryService: CountryService,
  ) {}

  ngOnInit(): void {
    this.lang = this.auth.getCurrentLanguage();
    this.getCountries();
  }

  async getCountries(): Promise<void> {
    if (this.storeFilters.find((f) => f.key == FiltersEnum.COUNTRY)?.choices?.length) return;

    await this.countryService.init();
    this.storeFilters.find((f) => f.key == FiltersEnum.COUNTRY).choices = this.countryService
      .getCountries()
      .map((c) => {
        return { slug: `${c.code} ${c.name}`, value: c.code };
      })
      .sort((a, b) => sortAlphabetically(a.slug, b.slug));
  }

  async createFilter(type: LogFiltersType, key: FiltersEnum, value: string | number): Promise<Boolean> {
    if (key == FiltersEnum.COUNTRY) {
      await this.getCountries();
    }

    let reference = copyObject(this.storeFilters.find((f) => f.key == key));

    const existingFilter = this.filters.find((f) => f.key == key);
    if (existingFilter) {
      reference = existingFilter;
    }

    const dataToAdd = {
      slug: reference.choices?.find((c) => c.value == value)?.slug || value,
      value,
    };

    if (reference) {
      if (isMultiInNotInFilter(reference.type)) {
        // already exists
        if (reference.data[type].find((d) => d.value == dataToAdd.value)) return false;

        // exists in, must transfer to notIn
        if (type == LogFiltersType.NOTIN) {
          const toTransfer = reference.data[LogFiltersType.IN].findIndex((d) => d.value == dataToAdd.value);
          if (toTransfer != -1) {
            reference.data[LogFiltersType.IN].splice(toTransfer, 1);
          }
        }

        reference.data[type].push(dataToAdd);
      } else if (isSingleInputFilter(reference.type)) {
        reference.data = value;
      }

      if (!existingFilter) {
        this.filters.push(reference);
      }

      return true;
    }
  }

  onNewFilterFieldChange(): void {
    this.currentFilter = copyObject(this.availablesFilters.find((f) => f.slug === this.selectedFilterSlug));
  }

  openEditFilterMenu(event, filter): void {
    this.currentFilter = copyObject(filter);
    this.currentFilterElementRef = event.target.closest('button');
    this.tooltipFilterMenu.toggleMenu(event, event.target.closest('button'));
  }

  emitFilters(): void {
    return this.filtersEmitter.emit(this.filters.filter((f) => f.active));
  }

  resetCurrentFilter(): void {
    this.currentFilter = null;
    this.selectedFilterSlug = '';
  }

  toggleActiveFilter(filter: Filter, event: any): void {
    filter.active = !filter.active;
    this.updateCurrentFilter(filter);

    this.tooltipFilterMenu.toggleMenu(event, this.currentFilterElementRef);
    this.emitFilters();
  }

  toggleNewFilter(event: any): void {
    this.resetCurrentFilter();
    this.mode = Mode.ADD;
    this.tooltipNewFilter.toggleMenu(event, event.target.closest('button'));
  }

  toggleEditFilter(event: any): void {
    this.mode = Mode.EDIT;
    this.selectedFilterSlug = this.currentFilter.slug;
    this.tooltipFilterMenu.toggleMenu(event);
    this.tooltipNewFilter.toggleMenu(event, this.currentFilterElementRef);
  }

  editFilter(event: any): void {
    if (!this.validateBeforeSave(this.currentFilter)) {
      return;
    }

    this.updateCurrentFilter(this.currentFilter);

    this.tooltipNewFilter.toggleMenu(event);
    this.currentFilter.active && this.emitFilters();
    this.resetCurrentFilter();
  }

  deleteFilter(filter: Filter, event: any): void {
    const index = this.filters.findIndex((f) => f.key == filter.key);
    if (index != -1) {
      this.filters.splice(index, 1);
    }

    this.tooltipFilterMenu.toggleMenu(event);
    this.emitFilters();
  }

  addFilter(event: any): void {
    if (!this.validateBeforeSave(this.currentFilter)) {
      return;
    }

    this.filters.push(this.currentFilter);

    this.tooltipNewFilter.toggleMenu(event);
    this.resetCurrentFilter();
    this.emitFilters();
  }

  validateBeforeSave(filter: Filter): boolean {
    return isSingleInputFilter(filter.type) || isIntervalFilter(filter.type) ? !!this.validateField(filter.data) : true;
  }

  cancelAddEditFilter(event: any): void {
    this.tooltipNewFilter.toggleMenu(event);
    this.resetCurrentFilter();
  }

  disableAllFilters(): void {
    this.filters.forEach((f) => (f.active = false));
    this.emitFilters();
  }

  enableAllFilters(): void {
    this.filters.forEach((f) => (f.active = true));
    this.emitFilters();
  }

  invertInNotIn(currentFilter: Filter): void {
    const tmp = currentFilter.data[LogFiltersType.NOTIN];
    currentFilter.data[LogFiltersType.NOTIN] = currentFilter.data[LogFiltersType.IN];
    currentFilter.data[LogFiltersType.IN] = tmp;
  }

  invertInNotInEvent(currentFilter: Filter, event): void {
    this.invertInNotIn(currentFilter);
    this.updateCurrentFilter(currentFilter);
    this.tooltipFilterMenu.toggleMenu(event);
    this.emitFilters();
  }

  updateCurrentFilter(filter: Filter): void {
    let filterPreviousState = this.filters.find((f) => f.key == filter.key);
    Object.assign(filterPreviousState, this.currentFilter);
  }

  formatValuesToPrint(data: Array<any>): string {
    return data.map((d) => d.slug).join(', ');
  }

  handlePaste(event, currentFilter: Filter, type: LogFiltersType): void {
    const value = event.clipboardData.getData('text/plain');
    if (value != null && value.match(/[,;]/)) {
      event.preventDefault();
      const split = value.split(/[,;]/);
      split.forEach((s) => {
        if (currentFilter.data[type].find((d) => d.value == s)) return;
        if (!this.validateField(s)) return;
        currentFilter.data[type] = [{ slug: s, value: s }, ...currentFilter.data[type]];
      });
      event.target.value = '';
    }
  }

  validateField = (data): Object => {
    switch (this.currentFilter.key) {
      case FiltersEnum.IP:
        if (!validateIp(data)) {
          this.toastr.error(this.translate.instant('WrongIp'));
          return null;
        }
        break;
      case FiltersEnum.RESPONSE_TIME_GTE:
        if (!validatePositiveInt(data)) {
          this.toastr.error(this.translate.instant('WrongResponseTimeGte'));
          return null;
        }
        break;
      case FiltersEnum.CONTENT_LENGTH_GTE:
        if (!validatePositiveInt(data)) {
          this.toastr.error(this.translate.instant('WrongResponseTimeGte'));
          return null;
        }
        break;
      case FiltersEnum.CREDIBILITY_BETWEEN:
        if (
          !validatePositiveInt0to100(data[LogFiltersType.GTE]) ||
          !validatePositiveInt0to100(data[LogFiltersType.LTE]) ||
          Number(data[LogFiltersType.GTE]) >= Number(data[LogFiltersType.LTE])
        ) {
          this.toastr.error(this.translate.instant('WrongCredibilityBetween'));
          return null;
        }
        break;
      case FiltersEnum.STATUS_CODE:
        if (!validateHttpCode(data)) {
          this.toastr.error(this.translate.instant('WrongHttpCode'));
          return null;
        }
        break;
      case FiltersEnum.METHOD:
        if (!validateHttpMethod(data)) {
          this.toastr.error(this.translate.instant('WrongMethodCode'));
          return null;
        }
        break;
    }

    return { slug: data, value: data };
  };
}
