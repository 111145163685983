import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, DatatableComponent } from '@siemens/ngx-datatable';
import moment from 'moment';

import { firstValueFrom, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BANDWIDTH, RESPONSES_CODES, RESPONSE_TIME, TRAFIC } from 'app/shared/highcharts/graph/graph';
import { AuthService } from '../../services/auth.service';
import { Global } from '../../global';
import { CONTEXT, ContextEnum, JournalFilters, SitesService } from '../../services/sites.service';
import { EXTERNAL_HELP_ENGLISH_LINK, EXTERNAL_HELP_FRENCH_LINK, MY_LOGS_ROUTE } from 'app/app-routing.module';
import { formatHumanReadableDate } from '../../shared/utils/data-utils';
import { DATE_NOW, DATE_LAST_7_DAYS, DATE_TODAY } from 'app/shared/date-range-selector/calendar-data.service';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { buildHttpParams } from 'app/shared/utils/request-utils';
import { CountryNames, CountryService } from '../../services/country.service';
import { FiltersEnum } from 'app/services/logs.service';
import _ from 'lodash';

interface BrainDrive {
  driveUuid: string;
  uid?: string;
  label: string;
}

@Component({
  selector: 'app-journal-bis',
  templateUrl: './journalbis.component.html',
  styleUrls: ['./journalbis.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
})
export class JournalBisComponent implements OnInit, AfterViewInit, OnDestroy {
  lang: string;

  EXTERNAL_HELP_FRENCH_LINK = EXTERNAL_HELP_FRENCH_LINK;
  EXTERNAL_HELP_ENGLISH_LINK = EXTERNAL_HELP_ENGLISH_LINK;

  @ViewChild('search') search: ElementRef;
  @ViewChild('logsTable') logsTable;

  ctx: ContextEnum = CONTEXT;

  mapGraphTypeTrack = {
    newIpBlocked: TRAFIC,
    highBannedVolume: TRAFIC,
    increasedResponseTime: RESPONSE_TIME,
    increasedBandwidth: BANDWIDTH,
    '4XX': RESPONSES_CODES,
    '5XX': RESPONSES_CODES,
    traficSpike: TRAFIC,
    increased4xx5xxRate: RESPONSES_CODES,
  };

  logs = [];

  brainDrives: { [prop: string]: BrainDrive } = {};
  isLoading: boolean = false;

  total = 0;
  size = 25;

  countries: CountryNames;

  index = 0;
  filters: JournalFilters = {
    period: { start: undefined, end: undefined, recomputeEndDateToNow: undefined, autoRefresh: false },
    search: '',
    id: undefined,
  };

  severity = {
    1: 'LOW',
    2: 'MEDIUM',
    3: 'HIGH',
  };

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private sites: SitesService,
    private countryService: CountryService,
    private toaster: ToastrService,
  ) {}

  async ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();

    this.route.queryParams.subscribe((params) => {
      // If data is passed from query-string
      if (Object.keys(params).length) {
        if (params['before']) {
          this.filters.period = {
            start: moment(params['before']).subtract(30, 'seconds'),
            end: moment(params['before']).add(1, 'milliseconds'),
            recomputeEndDateToNow: false,
            autoRefresh: false,
          };
        }

        if (params['severity']) {
          this.filters.severity = params['severity'];
        }

        if (params['site']) {
          this.filters.search = params['site'];
        }

        if (params['clusterId']) {
          this.sites[this.ctx].selectClusterByClusterId(params['clusterId']);
        }

        if (params['type']) {
          this.filters.type = params['type'];
        }

        if (params['id']) {
          this.filters.id = params['id'];
        }
      } else {
        // else if data is stored in context from previous navigation
        if (this.sites[this.ctx].current.journalFilters) {
          const { period, ...rest } = this.sites[this.ctx].current.journalFilters;
          this.filters = {
            ...rest,
            period: period.recomputeEndDateToNow ? { ...period, end: DATE_NOW() } : period,
          };
          // else default
        } else {
          this.filters.period = {
            start: DATE_TODAY(),
            end: DATE_NOW(),
            recomputeEndDateToNow: true,
            autoRefresh: false,
          };
        }
      }
    });

    await this.sites.load(this.ctx);
    await this.countryService.init();
    this.countries = this.countryService.getCountryNames();
    await this.getCodesAttacks();
    await this.refresh();
  }

  ngAfterViewInit() {
    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(debounceTime(1000))
      .subscribe(() => this.refresh());
  }

  ngOnDestroy() {
    this.sites[this.ctx].current.journalFilters = this.filters;
    this.filters.id = null;
  }

  refresh() {
    this.index = 0;
    this.getLogs();
  }

  onPeriodEmitted(p: any) {
    this.filters.period = {
      start: moment(p.start),
      end: moment(p.end),
      recomputeEndDateToNow: p.recomputeEndDateToNow,
      autoRefresh: p.autoRefresh,
    };
    this.refresh();
  }

  toggleExpandRow(row) {
    this.logsTable.rowDetail.toggleExpandRow(row);
  }

  changePage(e: any) {
    this.index = e.page - 1;
    this.getLogs();
  }

  async getCodesAttacks() {
    this.brainDrives = _.keyBy(
      await firstValueFrom(this.http.get<BrainDrive[]>(Global.baseUrl + 'v2/brain-drives')),
      (drive) => drive.uid ?? drive.driveUuid,
    );
  }

  getSearchParameters() {
    return buildHttpParams({
      page: this.index,
      size: this.size,
      after: this.filters?.period?.start,
      before: this.filters.period.recomputeEndDateToNow ? DATE_NOW() : this.filters?.period?.end,
      clusterId: this.sites[this.ctx]?.current?.cluster?.clusterId,
      severity: this.filters?.severity,
      type: this.filters?.type,
      site: this.filters?.search,
    });
  }

  async getLogs() {
    if (!this.sites[this.ctx].current?.cluster?.clusterId) return;

    this.isLoading = true;
    try {
      const params = this.getSearchParameters();

      const res = await firstValueFrom(this.http.get(Global.baseUrl + 'v2/events', { params }));
      this.handleLogsResponse(res);
    } finally {
      this.isLoading = false;
    }
  }

  handleLogsResponse(res) {
    this.total = res.totalElements;
    this.logs = res.content.map((log) => this.formatLog(log));

    // find log passed from query-string to open its details
    if (this.filters.id) {
      const log = this.logs.find((log) => log.id == this.filters.id);
      if (log) log.highlight = true;
    }
  }

  formatLog(log: any) {
    if (log.date) {
      log.outOfDate = moment().diff(log.date, 'days') > 89;
    }

    if (log.clusterId) {
      log.cluster = this.sites[this.ctx].store.clusters.find((c) => c.clusterId == log.clusterId) || '-';
    }

    if (log.event.drives) {
      log.event.drives.forEach((drive) => {
        drive.label = this.brainDrives[drive.uid]?.label || 'DRIVE_NOT_FOUND: ' + drive.uid;
        delete drive.uid;
      });
    }

    if (log.event.firstSuspicion) {
      log.event.firstSuspicion = formatHumanReadableDate(log.event.firstSuspicion, this.lang, 'long');
    }

    if (log.event.block) {
      log.event.block = formatHumanReadableDate(log.event.block, this.lang, 'long');
    }

    if (log.event.endDate) {
      log.event.endDate = formatHumanReadableDate(log.event.endDate, this.lang, 'long');
    }

    if (log.event.startDate) {
      log.event.startDate = formatHumanReadableDate(log.event.startDate, this.lang, 'long');
    }

    return log;
  }

  trackEvent(row) {
    let end = moment(row.date).add(30, 'minutes');
    const now = DATE_NOW();

    if (end.isAfter(now)) {
      end = now;
    }

    this.sites[this.ctx].current.period = {
      start: moment(row.date).subtract(30, 'minutes'),
      end,
      autoRefresh: false,
      recomputeEndDateToNow: false,
    };

    let query = {
      state: {
        graphType: this.mapGraphTypeTrack[row.subtype],
        site: row.site,
        clusterName: row.cluster.name,
      },
    };

    if (row.event.ip) {
      query.state[FiltersEnum.IP] = row.event.ip;
    }

    this.router.navigate([MY_LOGS_ROUTE], query);
  }

  getRowClass(row) {
    return {
      'row-highlight': row.highlight,
    };
  }
}
