import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'app/shared/toastr/toastr.service';
import { PageView } from 'app/enums/enums';
import { AuthService } from '../../../services/auth.service';
import { Global } from '../../../global';

@Component({
  selector: 'app-cluster-type',
  templateUrl: './cluster-type.component.html',
  styleUrls: ['./cluster-type.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class ClusterTypeComponent implements OnInit {
  lang: string;

  showView: PageView = PageView.LIST;
  pageViews = PageView;
  user: any = {};
  clusterTypes: any = [];
  clusterType: any = {};
  @ViewChild('modal') modal: any;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private auth: AuthService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.user = this.auth.getUser();
    this.getTypeCluster();
  }

  getTypeCluster() {
    this.http
      .post(Global.baseUrl + 'clusterType/get', {
        data: {},
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.clusterTypes = res.items;
        }
      });
  }

  editClusterType(p: any) {
    this.showView = PageView.EDIT;
    this.clusterType = p;
  }

  updateClusterType(p) {
    this.http
      .post(Global.baseUrl + 'clusterType/update', {
        datas: [{ ...p }],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.toastr.success(this.translate.instant('MiseAJourReussie'));
          this.reset();
        } else {
          this.toastr.error(this.translate.instant('EnregistrementEchoue'));
        }
      });
  }

  createTemplate() {
    this.showView = PageView.CREATE;
  }

  createClusterType(p) {
    this.http
      .post(Global.baseUrl + 'clusterType/create', {
        datas: [{ ...p }],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.toastr.success(this.translate.instant('EnregistrementReussi'));
          this.reset();
        } else {
          this.toastr.error(this.translate.instant('EnregistrementEchoue'));
        }
      });
  }

  reset() {
    this.getTypeCluster();
    this.showView = PageView.LIST;
    this.clusterType = {};
  }
}
