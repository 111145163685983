import { BaseLogRequest, CONTEXT, ContextEnum, SitesService } from '../../services/sites.service';
import {
  computeInterval,
  Graph,
  TrafficHistogramType,
  TrafficHistogramTypes,
  TRAFIC,
} from '../../shared/highcharts/graph/graph';
import _ from 'lodash';
import { MakeOptional } from '../../shared/utils/type-utils';
import { BlockedIp, DateHistograms, LogsService, UrlStats } from '../../services/logs.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { Donut } from '../../shared/highcharts/donut/donut';
import { firstValueFrom } from 'rxjs';
import { BySite, CountryData, CountryGraphType } from './dashboard.component';

export abstract class BaseDashboardComponent {
  lang: string;

  ctx: ContextEnum = CONTEXT;
  tableDataSize = 50;

  blockAttack: any = [];

  totals: { [key in TrafficHistogramType]: number } = _(TrafficHistogramTypes)
    .map((t) => [t, 0])
    .fromPairs()
    .value() as unknown as { [key in TrafficHistogramType]: number };
  pays?: Record<CountryGraphType, Array<CountryData>>;

  audience: any = {};

  slowRequests: UrlStats[] = [];
  cpuConsumingRequests: UrlStats[] = [];
  topBlockedIps: BlockedIp[] = [];

  constructor(
    protected http: HttpClient,
    public sites: SitesService,
    protected logsService: LogsService,
    public translate: TranslateService,
    protected auth: AuthService,
    protected graph: Graph,
    protected donut: Donut,
  ) {
    this.lang = this.auth.getCurrentLanguage();
  }

  async getBlockAttack(request: BaseLogRequest) {
    this.blockAttack = [];

    const res = await firstValueFrom(this.logsService.getBlockedByBrain(request, this.ctx));
    if (res?.datas) {
      this.blockAttack = this.donut.formatTypesAttacks(res.datas);
    }
  }

  async getDateHistogram(request: BaseLogRequest): Promise<DateHistograms<TrafficHistogramType, BySite>> {
    let res = await firstValueFrom(
      this.logsService.getDateHistograms<TrafficHistogramType, BySite>(
        TRAFIC,
        {
          ...request,
          aggregationTimeBound: `${this.computeInterval(request)}s`,
        },
        this.ctx,
      ),
    );
    this.audience = this.graph.formatData[TRAFIC](res, true);
    this.totals = _.mapValues(res, (v) =>
      _(v.data)
        .map(([_, v]) => v)
        .sum(),
    );
    return res;
  }

  private computeInterval(request: BaseLogRequest): number {
    return computeInterval({ start: request.beginDate, end: request.endDate });
  }

  async getOrigineAttaques(request: BaseLogRequest) {
    this.pays = null;

    const res = await firstValueFrom(this.logsService.getTrafficByCountry(request, this.ctx));
    this.pays = _.mapValues(res, (datas) => datas.map((p) => [p.name, p.y] as CountryData));
  }

  async getTopBlockedIps(request: BaseLogRequest) {
    this.topBlockedIps = await firstValueFrom(
      this.logsService.getTopBlockedIps(
        {
          ...request,
          aggSize: this.tableDataSize,
        },
        this.ctx,
      ),
    );
  }

  async getTopConsumingRequest(request: BaseLogRequest) {
    this.cpuConsumingRequests = await firstValueFrom(
      this.logsService.getCpuConsumingUrls(
        {
          ...request,
          aggSize: this.tableDataSize,
        },
        this.ctx,
      ),
    );
  }

  async getSlowRequest(request: BaseLogRequest) {
    this.slowRequests = await firstValueFrom(
      this.logsService.getSlowUrls(
        {
          ...request,
          aggSize: this.tableDataSize,
        },
        this.ctx,
      ),
    );
  }
}
