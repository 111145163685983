import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ROBOT } from 'app/shared/highcharts/donut/donut';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrl: './dashboard-card.component.scss',
})
export class DashboardCardComponent {
  @Input() bgColor: string = '';
  @Input() title: string = '';
  @Input() action: string = '';
  @Input() totalKey: string = '';
  @Input() totals: any;
  @Input() breakdown: any;
  @Input() isLoading: boolean = false;

  @Output() selectSite = new EventEmitter<string>();
  @Output() trackAction = new EventEmitter<string>();

  ROBOT = ROBOT;

  constructor(private translate: TranslateService) {}
}
