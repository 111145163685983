import moment from 'moment';

export type DateFormat = 'long' | 'iso';

export function formatDuration(ms: number, lang: string): string {
  if (!ms || isNaN(ms)) {
    return '';
  }

  if (ms < 1) {
    return ms * 1000 + ' µs';
  } else if (ms < 1000) {
    return Math.round(ms) + ' ms';
  } else {
    return showSeconds(ms / 1000, lang);
  }
}

function showSeconds(value: number, lang: string): string {
  return (
    new Intl.NumberFormat(
      lang,
      value < 100000 ? { maximumSignificantDigits: 3, minimumSignificantDigits: 3 } : { maximumFractionDigits: 0 },
    ).format(value) + ' s'
  );
}

export function formatHumanReadableDate(date, lang, formatName: DateFormat): string {
  if (date === null || date === undefined || date === '') {
    return '';
  } else {
    return moment(date).format(getDateFormat(lang, formatName || 'long'));
  }
}

function getDateFormat(lang: string, formatName: DateFormat): string {
  if (formatName == 'iso') return 'YYYY-MM-DD HH:mm:ss';
  else return lang == 'fr' ? 'DD MMM YYYY HH:mm:ss' : 'MMM DD, YYYY h:mm:ss A';
}

export function formatNumber(size, lang): string {
  return new Intl.NumberFormat(lang).format(size);
}

export function copyObject(object): any {
  return JSON.parse(JSON.stringify(object));
}

export function copyMap<K, V>(originalMap: Map<K, V>): Map<K, V> {
  const copiedMap = new Map();

  originalMap.forEach((value, key) => {
    if (typeof value === 'object' && value !== null) {
      if (value instanceof Map) {
        const copiedInnerMap = new Map();
        value.forEach((innerValue, innerKey) => {
          copiedInnerMap.set(innerKey, copyMap(innerValue));
        });
        copiedMap.set(key, copiedInnerMap);
      } else {
        copiedMap.set(key, copyObject(value));
      }
    } else {
      copiedMap.set(key, value);
    }
  });

  return copiedMap;
}

// add /32 to single ipv4 or /64 to single ipv6
export function subnet(ip: string): string {
  if (!ip.includes('/')) {
    if (ip.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)) {
      return `${ip}/32`;
    } else {
      return `${ip}/64`;
    }
  } else {
    return ip;
  }
}

interface Username {
  firstName?: string;
  lastName?: string;
}

export function fullName(user: Username): string {
  const nonNullComponents = [user.firstName, user.lastName].filter((x) => x);
  return nonNullComponents.length > 0 ? nonNullComponents.join(' ') : null;
}

export function lowerFirstLetter(str: string): string {
  return str[0].toLowerCase() + str.slice(1);
}

export function upperFirstLetter(str: string): string {
  return str[0].toUpperCase() + str.slice(1);
}

export function defined(nb: number | string): boolean {
  return nb !== undefined && nb !== null && nb !== '';
}

export function sortAlphabetically(a: string, b: string): number {
  return a.localeCompare(b);
}

export const ONE_MINUTE = 60 * 1000;
export const EPOCH_MILLI_30_DAYS = 604800000;
export const EPOCH_MILLI_1_DAY = 86400000;
export const EPOCH_MILLI_4_HOURS = 21600000;
