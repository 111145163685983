<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
  <!-- Container-fluid starts -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <form (ngSubmit)="forgotPassword(form)" class="md-float-material" #form="ngForm">
            <div class="text-center">
              <img src="assets/images/logo.png" alt="logo.png" />
            </div>
            <div class="auth-box">
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h3 class="text-left">Récupérer le mot de passe</h3>
                </div>
              </div>
              <div class="input-group">
                <input ngModel name="email" class="form-control" placeholder="Email" email />
                <span class="md-line"></span>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Réinitiliaser le mot de passse</button>
                </div>
              </div>
              <a [routerLink]="['/' + AUTH_LOGIN_ROUTE]" style="color: #4099ff">← Revenir à Connexion.</a>
              <hr />
              <div class="row">
                <div class="col-md-10">
                  <p class="text-inverse text-left m-b-0">
                    <a [href]="EXTERNAL_COMPANY_WEBSITE_LINK" target="_blank">www.ogosecurity.com</a>
                  </p>
                </div>
              </div>
            </div>
          </form>
          <!-- end of form -->
        </div>
        <!-- Authentication card end -->
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container-fluid -->
</section>
