import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../../../global';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { AUTH_LOGIN_ROUTE, EXTERNAL_COMPANY_WEBSITE_LINK } from 'app/app-routing.module';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent {
  AUTH_LOGIN_ROUTE = AUTH_LOGIN_ROUTE;
  EXTERNAL_COMPANY_WEBSITE_LINK = EXTERNAL_COMPANY_WEBSITE_LINK;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.translate.use('fr');
  }

  forgotPassword(f: NgForm) {
    if (f.invalid === true) {
      this.toastr.error(this.translate.instant('VeuillezRenseignerUneAdresseMailValide'), this.translate.instant('Erreur'));
      return;
    }
    const request = {
      data: {
        email: f.value.email,
      },
    };
    this.http.post(Global.baseUrl + 'utilisateur/forgotPassword', request).subscribe((res: any) => {
      if (!res.hasError) {
        f.reset();
        this.toastr.success(this.translate.instant('IfUserExistsAMailHasBeenSent'), this.translate.instant('Succes'));
      }
    });
  }
}
