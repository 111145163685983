<div class="card p-3" style="overflow-x: auto">
  <div class="row">
    <div class="col-12">
      <h5 style="display: flex; align-items: center; justify-content: space-between">
        <div>
          {{ 'RewriteRules' | translate }}
          <label class="badge badge-sm bg-dark" placement="bottom" ngbTooltip="{{ 'RewriteRulestTooltip' | translate }}">?</label>
        </div>
      </h5>
      <p style="margin-top: 10px">{{ 'RewriteRulesDescription' | translate }}</p>
      <hr style="margin-bottom: 0.8rem" />
    </div>
    <div class="col-md-12" style="margin-top: 10px; min-width: 1120px">
      <div *ngIf="!hostModeService.isAdvanced(hote.mode)" class="bg-blue" style="margin-bottom: 10px; margin-top: -10px">{{ 'SubscribeToAdvanced' | translate }}</div>
      <div cdkDropList class="drag-list" (cdkDropListDropped)="dropRewrite($event)">
        <div class="add-site-table-header" id="rewrite-header">
          <span></span>
          <span
            >{{ 'Active' | translate }}&nbsp;
            <label class="badge badge-sm bg-dark" placement="right" ngbTooltip="{{ 'RuleActiveTooltip' | translate }}">?</label>
          </span>
          <span
            >{{ 'Priority' | translate }}&nbsp;
            <label class="badge badge-sm bg-dark" placement="right" ngbTooltip="{{ 'RulePriorityTooltip' | translate }}">?</label>
          </span>
          <span style="width: 66% !important; min-width: 560px">{{ 'RewriteRule' | translate }} </span>
          <span style="width: 30% !important">{{ 'Comments' | translate }}</span>
          <span style="width: 81px !important">Actions</span>
        </div>
        <div *ngFor="let rule of rewriteRules" class="drag-container">
          <div class="drag-box" cdkDrag [cdkDragDisabled]="isEditingRewriteRule() || rewriteRulesInProgress" [ngClass]="{ 'no-drag': isEditingRewriteRule() || rewriteRulesInProgress }">
            <span>
              <div *ngIf="!isEditingRewriteRule() || rewriteRulesInProgress" class="grip-container" style="top: 46px !important">
                <div class="grip-column">
                  <div class="grip"></div>
                  <div class="grip"></div>
                  <div class="grip"></div>
                </div>
                <div class="grip-column">
                  <div class="grip"></div>
                  <div class="grip"></div>
                  <div class="grip"></div>
                </div>
              </div>
            </span>
            <span>
              <div class="switch-container" style="position: relative; left: 7px; top: 29px">
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="rule.isActive" [ngStyle]="{ cursor: rule.editing ? 'pointer' : 'default' }" [disabled]="!rule.editing" />
                  <span class="slider round" [ngStyle]="{ opacity: rule.editing ? '1' : '0.5', cursor: rule.editing ? 'pointer' : 'default' }"></span>
                </label>
              </div>
            </span>
            <span title="{{ (isEditingRewriteRule() ? 'PriorityTooltipInEdit' : 'PriorityTooltipOutOfEdit') | translate }}">
              <span *ngIf="rule.priority" style="top: 29px">#{{ rule.priority }}</span>
            </span>
            <span style="width: 66% !important; min-width: 560px" class="flex-align-items">
              <i
                *ngIf="!isValidRewriteRegExp(rule.rewriteSource)"
                class="icofont icofont-warning"
                container="body"
                placement="left"
                ngbTooltip="{{ 'InvalidRegExp' | translate }}"
                style="position: relative; top: 10px; font-size: 25px; color: #e74c3c"
              ></i>

              <i
                *ngIf="isValidRewriteRegExp(rule.rewriteSource)"
                class="icofont icofont-check"
                container="body"
                placement="top"
                ngbTooltip="{{ 'ValidRegExp' | translate }}"
                style="position: relative; top: 10px; font-size: 25px; color: #4099ff"
              ></i>
              &nbsp;&nbsp;
              <div class="form-group">
                <label style="display: block">
                  {{ 'SourceURI' | translate }}
                  <label class="badge badge-sm bg-dark" placement="right" container="body" [ngbTooltip]="sourceURITempExp">?</label>
                  <ng-template #sourceURITempExp>
                    <div innerHTML="{{ 'SourceURITooltip' | translate }}"></div>
                  </ng-template>
                </label>
                <div ngbTooltip="{{ 'StartRegexpCircumflex' | translate }}" container="body" class="input-prefix">
                  <div style="position: relative; top: 5px">^</div>
                </div>
                <input
                  #rewriteRuleSourceElement
                  [(ngModel)]="rule.rewriteSource"
                  [disabled]="!rule.editing"
                  (input)="computeRegExp(rule)"
                  style="width: 200px; height: 33px; margin-right: 20px; padding-left: 36px"
                />
              </div>
              <i class="icofont icofont-long-arrow-right" style="margin-right: 20px; font-size: 35px; top: 8px"></i>
              <div class="form-group">
                <label style="display: block">
                  {{ 'DestinationURI' | translate }}
                  <label class="badge badge-sm bg-dark" placement="right" container="body" [ngbTooltip]="destinationURITempExp">?</label>
                  <ng-template #destinationURITempExp>
                    <div innerHTML="{{ 'DestinationURITooltip' | translate }}"></div>
                  </ng-template>
                </label>
                <input [(ngModel)]="rule.rewriteDestination" [disabled]="!rule.editing" (input)="computeRegExp(rule)" style="width: 200px; height: 33px" />
              </div>
            </span>
            <span style="width: 30% !important">
              <span [title]="rule.comments" *ngIf="!rule.editing">{{ rule.comments }}</span>
              <textarea
                *ngIf="rule.editing"
                placeholder="{{ 'CommentsOptionnal' | translate }}"
                [(ngModel)]="rule.comments"
                style="height: 100%; width: 100%; position: relative; top: -5px; border: 1px solid #dfdfdf; border-radius: 2px"
                maxlength="255"
              ></textarea>
            </span>
            <span style="width: 80px !important; padding-right: 0px; margin-right: 8px">
              <div class="flex-align-items rules-actions" style="top: 22px">
                <button
                  *ngIf="!rule.editing"
                  (click)="!isEditingRewriteRule() && !rewriteRulesInProgress && startRewriteEditing(rule)"
                  title="{{ 'LastUpdateAt' | translate }} : {{ rule.updatedAt | dateFormat }}"
                  [ngStyle]="{ color: isEditingRewriteRule() || rewriteRulesInProgress ? '#dbd9da' : '#4099ff', cursor: isEditingRewriteRule() || rewriteRulesInProgress ? 'default' : 'pointer' }"
                  style="font-size: 17px"
                  class="transparent-btn pointer icofont icofont-edit-alt mr-2"
                ></button>
                <button
                  *ngIf="rule.editing"
                  (click)="validateRewriteEditing(rule)"
                  title="{{ 'Validate' | translate }}"
                  [ngStyle]="{
                    color: rule.rewriteSource.length == 0 || rule.rewriteDestination.length == 0 ? '#dbd9da' : '#4099ff',
                    cursor: rule.rewriteSource.length == 0 || rule.rewriteDestination.length == 0 ? 'default' : 'pointer'
                  }"
                  style="font-size: 25px"
                  class="transparent-btn pointer icofont icofont-check"
                ></button>
                <button
                  *ngIf="!rule.editing"
                  (click)="(!isEditingRewriteRule() || rule.editing) && deleteRewriteRule(rule)"
                  title="{{ 'supprimer' | translate }}"
                  [ngStyle]="{
                    color: isEditingRewriteRule() || rewriteRulesInProgress ? '#dbd9da' : '#e74c3c',
                    cursor: isEditingRewriteRule() || rewriteRulesInProgress ? 'default' : 'pointer'
                  }"
                  style="font-size: 17px"
                  class="transparent-btn pointer icofont icofont-ui-delete mr-1"
                ></button>
                <button
                  *ngIf="rule.new"
                  (click)="(!isEditingRewriteRule() || rule.editing) && deleteRewriteRule(rule)"
                  title="{{ 'Annuler' | translate }}"
                  [ngStyle]="{ color: isEditingRewriteRule() && !rule.editing ? '#dbd9da' : '#e74c3c', cursor: isEditingRewriteRule() && !rule.editing ? 'default' : 'pointer' }"
                  style="font-size: 23px"
                  class="transparent-btn pointer icofont icofont-close mr-1"
                ></button>
                <button
                  *ngIf="rule.editing && !rule.new"
                  (click)="cancelRewriteEditing(rule)"
                  title="{{ 'CancelChanges' | translate }}"
                  style="color: #e74c3c; font-size: 23px"
                  class="transparent-btn pointer icofont icofont-close mr-1"
                ></button>
              </div>
            </span>
          </div>
          <div *ngIf="rule.editing" class="tester">
            <h6>{{ 'RewriteTester' | translate }}</h6>
            <div class="flex-align-items" style="margin-top: 20px">
              <input #rewriteTest placeholder="{{ 'URLToTest' | translate }}" (input)="computeRegExp(rule)" style="width: 400px; margin-right: 20px" />
              <i class="icofont icofont-long-arrow-right" style="margin-right: 20px; font-size: 35px"></i>
              <p>{{ 'Result' | translate }} :&nbsp;&nbsp;</p>
              <input [value]="rule.resultTest == undefined ? ('EnterURLToTest' | translate) : rule.resultTest" disabled style="width: 400px" />
            </div>
          </div>
        </div>
        <div *ngIf="!rewriteRules.length" style="text-align: center; padding: 15px">{{ 'NoRewriteRules' | translate }}</div>
      </div>
      <br />
      <div style="margin-bottom: 10px">
        <a *ngIf="!isEditingRewriteRule() && !isCreatingRewriteRule()" class="ogo-link" (click)="addRewriteRule()">+ {{ 'AddRule' | translate }}</a>
      </div>
    </div>
  </div>
</div>
