import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Highcharts from 'highcharts';
import $ from 'jquery';

@Component({
  selector: 'app-bar-horizontal',
  templateUrl: './bar-horizontal.component.html',
  styleUrls: ['./bar-horizontal.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class BarHorizontalComponent implements AfterViewInit, OnChanges {
  @ViewChild('chart') chart: ElementRef;

  @Input() data: any = {};

  @Output() barClicked = new EventEmitter<Object>();
  @Output() categoryClicked = new EventEmitter<string>();
  @Output() isRendered = new EventEmitter<void>();

  constructor(private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
      this.drawChart(this.data);
    }
  }

  ngAfterViewInit() {
    this.drawChart(this.data);
  }

  barClick = (event: any) => {
    this.barClicked.emit(event);
  };

  drawChart(data: any) {
    if (!this.chart?.nativeElement || !data?.chart) return;

    data = this.setGlobalOptions(data);

    const _categoryClicked = this.categoryClicked;

    // TODO: use arrow functions
    Highcharts.chart(data, function (chart) {
      $.each(chart.xAxis[0].ticks, function (i, tick) {
        tick.label?.on('click', function (el) {
          _categoryClicked.emit(el.target.textContent);
        });
      });
    });
  }

  setGlobalOptions(data) {
    data.chart.events = {
      render: () => {
        this.isRendered.emit();
      },
    };

    data.chart.renderTo = this.chart.nativeElement;

    data.plotOptions.series.cursor = 'pointer';
    data.plotOptions.series.events.click = this.barClick;

    data.lang = {
      noData: this.translate.instant('NoData'),
      printChart: this.translate.instant('PrintChart'),
      downloadPNG: this.translate.instant('DownloadPNG'),
      downloadJPEG: this.translate.instant('DownloadJPEG'),
      downloadSVG: this.translate.instant('DownloadSVG'),
      downloadCSV: this.translate.instant('DownloadCSV'),
      downloadXLS: this.translate.instant('DownloadXLS'),
    };

    return data;
  }
}
