import { Component, ElementRef, HostListener, ViewChild, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { createPopper } from '@popperjs/core';
import { TooltipMenuService } from './tooltip-menu.service'; // Import du service
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tooltip-menu',
  templateUrl: './tooltip-menu.component.html',
  styleUrls: ['./tooltip-menu.component.scss'],
})
export class TooltipMenuComponent implements OnInit, OnDestroy {
  @Output() clickOutside = new EventEmitter();
  @ViewChild('tooltipMenu') tooltipMenu!: ElementRef;
  isMenuOpen = false;
  popperInstance: any;
  private closeSubscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private tooltipMenuService: TooltipMenuService, // Injection du service
  ) {}

  ngOnInit(): void {
    // Écoute les notifications de fermeture de tous les tooltips
    this.closeSubscription = this.tooltipMenuService.closeAllTooltips$.subscribe(() => {
      this.isMenuOpen = false;
      this.destroyPopper();
    });
  }

  ngOnDestroy(): void {
    // Nettoyage de la souscription
    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
    }
  }

  toggleMenu(event: MouseEvent, ref: HTMLElement = null): void {
    event.stopPropagation(); // Empêche la propagation du clic vers le document

    if (!this.isMenuOpen) {
      // Ferme tous les autres tooltips avant d'ouvrir celui-ci
      this.tooltipMenuService.closeAllTooltips();
      this.isMenuOpen = true;
      this.createPopper(ref || (event.target as HTMLElement));
    } else {
      this.isMenuOpen = false;
      this.destroyPopper();
    }
  }

  createPopper(target: HTMLElement) {
    this.popperInstance = createPopper(target, this.tooltipMenu.nativeElement, {
      placement: 'auto-end',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-20, 8],
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['left'],
          },
        },
      ],
    });
  }

  destroyPopper() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    // Vérifie si le clic est en dehors de ce composant

    if (clickedElement.matches('.ng-value-icon') || clickedElement.matches('.ng-star-inserted')) return;

    if (this.isMenuOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.isMenuOpen = false;
      this.destroyPopper();

      if (!clickedElement.closest('.tooltip-item')) {
        this.clickOutside.emit();
      }
    }
  }
}
