import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import moment from 'moment';
import 'moment/locale/fr';
import { AuthService } from './services/auth.service';
import { Global } from './global';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { copyObject } from './shared/utils/data-utils';
import { AUTH_LOGIN_ROUTE } from './app-routing.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title: String = '.OGO Dashboard';
  baseUrl: string = Global.baseUrl;

  etag: string = null;
  promptNewDashboardDetected: boolean = false;
  currentPath: string = '';

  constructor(
    private router: Router,
    private auth: AuthService,
    private http: HttpClient,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = event.url;
      }
    });

    moment.locale(this.auth.getCurrentLanguage());
  }

  ngOnInit() {
    this.scrollTopBetweenNavigation();
    if (environment.detectVersionChange) {
      this.detectVersionChange();
    }
  }

  scrollTopBetweenNavigation() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  async detectVersionChange() {
    this.getEtag();

    window.addEventListener(
      'visibilitychange',
      () => {
        this.getEtag();
      },
      false,
    );
  }

  getEtag() {
    this.http
      .head<any>('/', { observe: 'response', headers: { 'Skip-Loader': 'true', 'Cache-Control': 'no-cache' } })
      .subscribe((res) => {
        const previousEtag = copyObject(this.etag);
        this.etag = res?.headers?.get('etag');

        if (previousEtag != null && previousEtag != this.etag) {
          if (this.currentPath == `/${AUTH_LOGIN_ROUTE}`) {
            this.reload();
          } else {
            this.promptNewDashboardDetected = true;
          }
        }
      });
  }

  reload() {
    window.location.reload();
  }
}
