import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { NgSelectComponent } from '@ng-select/ng-select';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Global } from 'app/global';
import { ACL_ORGANIZATION_MANAGE, ACL_ORGANIZATION_OWNER_MANAGE, AuthService } from '../../../services/auth.service';
import Organization from 'app/services/entities/organization';
import { EXTERNAL_HELP_ENGLISH_LINK, EXTERNAL_HELP_FRENCH_LINK } from 'app/app-routing.module';
import { copyObject } from 'app/shared/utils/data-utils';
import { validateEmail } from '../../../shared/validators/validators';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit, AfterViewInit {
  lang: string;

  @ViewChild('searchUsers') searchUsers: NgSelectComponent;

  editField = false;
  countries = [];
  users = [];
  organization: Organization;
  previousOrganization: Organization;

  ACL_ORGANIZATION_MANAGE = ACL_ORGANIZATION_MANAGE;
  ACL_ORGANIZATION_OWNER_MANAGE = ACL_ORGANIZATION_OWNER_MANAGE;
  EXTERNAL_HELP_FRENCH_LINK = EXTERNAL_HELP_FRENCH_LINK;
  EXTERNAL_HELP_ENGLISH_LINK = EXTERNAL_HELP_ENGLISH_LINK;

  constructor(
    public auth: AuthService,
    private http: HttpClient,
    public translate: TranslateService,
    private toastr: ToastrService,
  ) {
    this.lang = this.auth.getCurrentLanguage();

    this.http.get('/assets/data/countries_' + this.auth.getCurrentLanguage() + '.json').subscribe((data: any) => {
      this.countries = data.map((p: any) => {
        return {
          countryNameEn: p.name,
          countryNameFr: p.name,
          countryCode: p.alpha2,
        };
      });
    });
  }

  ngOnInit() {
    this.organization = copyObject(this.auth.currentOrganization);
    this.previousOrganization = copyObject(this.organization);
  }

  ngAfterViewInit(): void {
    if (this.searchUsers) {
      fromEvent(this.searchUsers.element, 'keyup')
        .pipe(debounceTime(300))
        .subscribe((evt: any) => {
          const value = evt.target.value;
          if (value.length >= 3) {
            this.loadUsers(value);
          }
        });
    }
  }

  updateOrganization() {
    if (!validateEmail(this.organization.owner.email)) {
      return this.toastr.error(this.translate.instant('InvalidEmail'));
    }

    const { companyName, owner, street, zipCode, city, countryCode } = this.organization;

    this.http
      .put(Global.baseUrl + `v2/organizations/${this.organization.id}`, {
        companyName,
        ownerEmail: owner.email,
        street,
        zipCode,
        city,
        countryCode,
      })
      .subscribe(
        (res: any) => {
          if (this.auth.userHasFeature(ACL_ORGANIZATION_OWNER_MANAGE) && owner.email != this.auth.user.email) {
            // change organization owner, so full update organization
            this.auth.init();
          } else {
            // soft update
            this.auth.currentOrganization = copyObject(this.organization);
          }

          this.previousOrganization = copyObject(this.organization);
          this.toastr.success(this.translate.instant('OperationSuccess'));
          this.editField = false;
        },
        (error) => {
          this.toastr.error(error.error);
        },
      );
  }

  onEditField = () => {
    this.editField = true;
  };

  loadUsers(searchTerm) {
    this.auth
      .getOrganizationUsers(this.auth.currentOrganization.id, 0, searchTerm)
      .then((res: any) => (this.users = res.content.map((item) => item.user)));
  }
}
