import { Pipe, PipeTransform } from '@angular/core';
import { DateFormat, formatDuration, formatHumanReadableDate, formatNumber } from '../utils/data-utils';
import { AuthService } from 'app/services/auth.service';

@Pipe({
  name: 'duration',
  pure: true, // false actually, since it's locale dependent. BUt it's OK since we refresh the page when lang is changed
})
export class DurationPipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(value: number): any {
    return formatDuration(value, this.auth.getCurrentLanguage());
  }
}

@Pipe({
  name: 'durationSeconds',
  pure: true, // false actually, since it's locale dependent. BUt it's OK since we refresh the page when lang is changed
})
export class DurationSecondsPipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(value: number): any {
    if (!value || isNaN(value)) {
      return value;
    }

    if (value < 60) {
      return Math.round(value) + ' s';
    } else if (value < 3600) {
      return (value / 60).toFixed(2).replace(/\.?0+$/, '') + ' min';
    } else if (value < 86400) {
      return (value / 3600).toFixed(2).replace(/\.?0+$/, '') + ' h';
    } else {
      return (value / 86400).toFixed(2).replace(/\.?0+$/, '') + (this.auth.getCurrentLanguage() == 'fr' ? ' J' : ' D');
    }
  }
}
@Pipe({
  name: 'dateFormat',
  pure: true, // false actually, since it's locale dependent. BUt it's OK since we refresh the page when lang is changed
})
export class DateFormatPipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(date: any, format?: DateFormat): string {
    return formatHumanReadableDate(date, this.auth.getCurrentLanguage(), format || 'long');
  }
}

@Pipe({
  name: 'numberFormat',
  pure: true, // false actually, since it's locale dependent. BUt it's OK since we refresh the page when lang is changed
})
export class NumberFormatPipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(size: any): string {
    return formatNumber(size, this.auth.getCurrentLanguage());
  }
}

@Pipe({
  name: 'callback',
  pure: false,
})
export class CallbackPipe implements PipeTransform {
  transform(items: any[], callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter((item) => callback(item));
  }
}
