<div class="overlay-hard complete">{{ 'GenerationInProgress' | translate }}</div>
<ng-container>
  <div class="page title-page">
    <img id="print-logo" src="assets/images/logo_big.png" />
    <h1>{{ 'ActivityReport' | translate }}</h1>
    <h3>{{ 'FromTo' | translate: { from: startDate, to: endDate } }}</h3>
    <h4 id="generated-by">{{ 'GeneratedBy' | translate: { userName: userName, date: today } }}</h4>
    <h4>{{ this.auth.currentOrganization.companyName }}</h4>
    <h4>Cluster {{ cluster?.name }}</h4>
    <ng-container *ngIf="siteNames?.length">
      <h4>Sites ({{ siteNames.length }}) :</h4>
      <p *ngFor="let host of siteNames" class="print-host">{{ host }}</p>
    </ng-container>
    <ng-container *ngIf="!siteNames?.length">
      <h4>{{ 'Organization' | translate }} {{ auth.currentOrganization.companyName }}</h4>
    </ng-container>
  </div>

  <div class="page">
    <h2>{{ 'TableOfContents' | translate }}</h2>
    <ul class="toc">
      <li>
        <span class="title">{{ 'RequestsAndRepartitions' | translate }}</span>
        <span class="chapter">3</span>
      </li>
      <li>
        <span class="title">{{ 'GeoOriginsOf' | translate }}&nbsp;</span>
      </li>
      <li>
        <span class="title subtitle">{{ 'requests' | translate }}</span>
        <span class="chapter">4</span>
      </li>
      <li>
        <span class="title subtitle">{{ 'robotRequests' | translate }}</span>
        <span class="chapter">5</span>
      </li>
      <li>
        <span class="title subtitle">{{ 'attackRequests' | translate }}</span>
        <span class="chapter">6</span>
      </li>
      <li>
        <span class="title">{{ 'FrequentsAndSlowsRequests' | translate }}</span>
        <span class="chapter">7-8</span>
      </li>
      <li>
        <span class="title">{{ 'Glossary' | translate }}</span>
        <span class="chapter">9</span>
      </li>
    </ul>
  </div>
  <div class="page">
    <h2>{{ 'RequestsAndRepartitions' | translate }}</h2>

    <div class="metrics">
      <p>{{ 'RequetesAnalysees' | translate }} : {{ totals[ANALYZED] | numberFormat }}</p>
      <p>{{ 'Bloquees' | translate }} : {{ totals[BLOCKED] | numberFormat }}</p>
      <p>{{ 'Suspectes' | translate }} : {{ totals[SUSPECT] | numberFormat }}</p>
      <p>{{ 'Robots' | translate }} : {{ totals[ROBOT] | numberFormat }}</p>
    </div>

    <app-donut
      *ngIf="!isLoading && blockAttack.length != 0"
      [print]="true"
      [data]="blockAttack"
      [fontSize]="'30px'"
      [title]="translate.instant('TypesAttaques')"
    />

    <app-graph [data]="audience" [fontSize]="'30px'" height="600px" />
  </div>

  <div class="page">
    <div class="title-map">
      <h3>{{ 'RequestsOrigins' | translate }}</h3>
      <p>{{ 'CartographyRequestsRepartitions' | translate }}</p>
    </div>
    <div class="world-map">
      <app-world-map
        [showTopCountryNames]="false"
        [origine]="TRAFIC"
        [data]="pays ? pays[TRAFIC] : null"
        [hideTitle]="true"
      />
    </div>
  </div>

  <div class="page">
    <div class="title-map">
      <h3>{{ 'BotsOrigins' | translate }}</h3>
      <p>{{ 'CartographyRobotRequestsRepartitions' | translate }}</p>
    </div>
    <div class="world-map">
      <app-world-map
        [showTopCountryNames]="false"
        [origine]="ROBOT"
        [data]="pays ? pays[ROBOT] : null"
        [hideTitle]="true"
      />
    </div>
  </div>

  <div class="page">
    <div class="title-map">
      <h3>{{ 'BlockedOrigins' | translate }}</h3>
      <p>{{ 'CartographyAttackRequestsRepartitions' | translate }}</p>
    </div>
    <div class="world-map">
      <app-world-map
        [showTopCountryNames]="false"
        [origine]="BLOCKED"
        [data]="pays ? pays[BLOCKED] : null"
        [hideTitle]="true"
      />
    </div>
  </div>

  <div class="page">
    <h2>{{ 'topBlockedIps' | translate }}&nbsp;{{ periodMoreThan7Days ? ('7LastDays' | translate) : '' }}</h2>
    <app-blocked-ips-table [print]="true" [topBlockedIps]="topBlockedIps" />
  </div>

  <div class="page">
    <h2>
      {{ 'FrequentsAndSlowsRequests' | translate }}&nbsp;{{ periodMoreThan7Days ? ('7LastDays' | translate) : '' }}
    </h2>
    <h4>{{ 'RequetesLentes' | translate }}</h4>
    <app-slow-requests-table [slowRequests]="slowRequests" [print]="true" />

    <h4 class="page-break">{{ 'PagesPlusVisitees' | translate }}</h4>
    <app-most-frequent-requests-table [cpuConsumingRequests]="cpuConsumingRequests" [print]="true" />
  </div>

  <div class="page">
    <h2>{{ 'Glossary' | translate }}</h2>

    <h4>{{ 'XSSInjection' | translate }}</h4>
    <p><b> Description : </b> {{ 'XSSInjectionDescription' | translate }}</p>
    <p><b> Impact : </b> {{ 'XSSInjectionImpact' | translate }}</p>

    <h4>{{ 'SQLInjection' | translate }}</h4>
    <p><b> Description : </b> {{ 'SQLInjectionDescription' | translate }}</p>
    <p><b> Impact : </b> {{ 'SQLInjectionImpact' | translate }}</p>

    <h4>{{ 'Bruteforce' | translate }}</h4>
    <p><b> Description : </b> {{ 'BruteforceDescription' | translate }}</p>
    <p><b> Impact : </b> {{ 'BruteforceImpact' | translate }}</p>

    <h4>{{ 'DOS' | translate }}</h4>
    <p><b> Description : </b> {{ 'DOSDescription' | translate }}</p>
    <p><b> Impact : </b> {{ 'DOSImpact' | translate }}</p>
  </div>
</ng-container>
