<div [hidden]="showView != pageViews.LIST" class="m-t-25">
  <div class="row">
    <div class="col-md-2">
      <button type="button" style="margin-bottom: 10px" class="btn btn-sm btn-primary pull-left" (click)="addOrganization()" [innerHtml]="'+&nbsp;' + ('AddOrganization' | translate)"></button>
    </div>
    <div class="col-md-5">
      <div class="input-group input-group-sm">
        <input type="text" #search class="form-control form-control-sm" placeholder="Organisation, Email" />
        <span class="input-group-prepend">
          <span class="input-group-text">
            <i class="icofont icofont-search"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div class="card">
    <div *ngIf="organizations.length" class="table-responsive">
      <table class="table table-striped table-condensed">
        <thead>
          <tr>
            <th [innerHtml]="'Organization' | translate"></th>
            <th [innerHtml]="'Code' | translate"></th>
            <th [innerHtml]="'AdresseMailOwner' | translate"></th>
            <th [innerHtml]="'NbSites' | translate"></th>
            <th [innerHtml]="'Creation' | translate"></th>
            <th [innerHtml]="'Actions' | translate"></th>
          </tr>
        </thead>
        <tbody>
          <ng-template
            ngFor
            let-item
            [ngForOf]="
              organizations
                | paginate
                  : {
                      itemsPerPage: pageSize,
                      currentPage: page,
                      totalItems: totalItems
                    }
            "
          >
            <tr>
              <td>
                <b>{{ item.companyName }}</b>
              </td>
              <td>
                <app-badge-organization [code]="item.code"></app-badge-organization>
              </td>
              <td>{{ item.owner.email }}</td>
              <td>{{ item.nbSites }}</td>
              <td>{{ item.createdAt | dateFormat }}</td>
              <td>
                <i (click)="connectAsOwner(item)" style="color: #4099ff; font-size: 20px" class="pointer icofont icofont-eye" title="{{ 'ConnectAsOwner' | translate }}"></i>&nbsp;&nbsp;
                <i (click)="editOrganization(item)" style="color: #4099ff; font-size: 20px" class="pointer icofont icofont-edit-alt" title="Editer"></i>&nbsp;&nbsp;
                <i (click)="modalRemove(item)" style="color: #e74c3c; font-size: 20px" class="pointer icofont icofont-ui-delete" title="Supprimer"></i>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <div class="float-right mr-2">
        <pagination-controls class="pull-right" (pageChange)="changePage($event)" previousLabel="{{ 'Previous' | translate }}" nextLabel="{{ 'Next' | translate }}"></pagination-controls>
        <div class="pull-right mr-4 total-admin">Total : {{ totalItems }}</div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showView != pageViews.LIST">
  <app-add-edit-organization [organization]="organization" (cancel)="cancelAddEdit()" [fromAdmin]="true"></app-add-edit-organization>
</div>

<!-- POPUP REMOVE organization -->
<app-modal-basic #remove [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small [innerHtml]="'Confirmation'"></small>
    </h4>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="label label-primary">{{ 'Organization' | translate }}</label>
          <p>{{ organization?.companyName }}</p>
        </div>
        <div class="form-group">
          <label class="label label-primary">{{ 'AdresseMailOwner' | translate }}</label>
          <p>{{ organization?.owner?.email }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button (click)="removeOrganization(organization?.id)" class="btn btn-sm btn-primary" [innerHtml]="'Supprimer'"></button>&nbsp;
    <button (click)="remove.hide()" class="btn btn-sm btn-danger" [innerHtml]="'Annuler'"></button>
  </div>
</app-modal-basic>
