import { Global } from '../../../../global';
import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../services/auth.service';
import { SitesService } from 'app/services/sites.service';
import { AUTH_LOGIN_ROUTE, EXTERNAL_COMPANY_WEBSITE_LINK } from 'app/app-routing.module';

const CODE_LENGTH = 6;
const FAIL_ATTEMPTS_TOLERATED = 5;
const TIME_BEFORE_CAN_SEND_NEW_CODE = 10;

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss', '../../../../../assets/icon/icofont/css/icofont.scss'],
})
export class MfaComponent implements OnInit, AfterViewInit, OnDestroy {
  token: string = '';
  email: string = '';
  code: string = '';
  statusCode: string = '';
  failCodeCount: number = 0;
  timeLeft: number = TIME_BEFORE_CAN_SEND_NEW_CODE;
  codeLength: number = CODE_LENGTH;
  loading: boolean = false;
  interval = null;
  @ViewChild('codeInput') codeInput: ElementRef;
  AUTH_LOGIN_ROUTE = AUTH_LOGIN_ROUTE;
  EXTERNAL_COMPANY_WEBSITE_LINK = EXTERNAL_COMPANY_WEBSITE_LINK;

  constructor(
    private router: Router,
    private http: HttpClient,
    private auth: AuthService,
    private sites: SitesService,
  ) {
    this.token = this.router?.getCurrentNavigation()?.extras?.state?.token;
    this.email = this.router?.getCurrentNavigation()?.extras?.state?.email;
  }

  ngOnInit() {
    if (this.token == null || this.email == null) {
      this.router.navigate([AUTH_LOGIN_ROUTE]);
    }

    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);

    document.addEventListener('paste', (e) => this.handlePaste(e));
  }

  handlePaste(e: any) {
    this.code = (e.clipboardData || window['clipboardData']).getData('text');
    if (this.code.length == 6) this.onCodeCompleted(this.code);
  }

  ngAfterViewInit() {
    this.codeInput['inputs'][0].focus();
    this.codeInput['inputs'].forEach((input) => {
      input.setAttribute('autocomplete', 'off');
      input.setAttribute('data-lpignore', 'true');
      input.setAttribute('data-form-type', 'other');
    });
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    document.removeEventListener('paste', this.handlePaste);
  }

  onCodeChanged(code: string) {
    this.code = code;
  }

  onCodeCompleted(code: string) {
    this.loading = true;
    setTimeout(() => this.login(code), 2000);
  }

  login(code) {
    this.http
      .post(Global.baseUrl + 'utilisateur/validateToken/mfa', {
        data: {
          email: this.email,
          token: this.token,
          mfa: code,
        },
      })
      .subscribe((res: any) => {
        this.loading = false;
        if (!res.hasError) {
          this.sites.purgeContext();
          this.auth.loginSuccess(res.item);
        } else {
          this.code = '';
          this.codeInput['inputs'][0].focus();
          this.failCodeCount++;
          this.statusCode = 'Code incorrect';
          if (this.failCodeCount >= FAIL_ATTEMPTS_TOLERATED) {
            this.router.navigate([AUTH_LOGIN_ROUTE]);
          }
        }
      });
  }

  sendCodeAgain() {
    this.code = '';
    this.http.post(Global.baseUrl + 'utilisateur/generateToken/mfa', { data: { email: this.email, token: this.token } }).subscribe((res: any) => {
      if (!res.hasError) {
        this.timeLeft = TIME_BEFORE_CAN_SEND_NEW_CODE;
        this.token = res.item.value;
        this.failCodeCount = 0;
        this.statusCode = 'Un nouveau code vous a été envoyé';
      } else {
        this.statusCode = 'Un problème a été rencontré';
      }
    });
  }
}
