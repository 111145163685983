import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import * as collection from '../../../assets/collection/collection.json';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-api-doc',
  templateUrl: './api-doc.component.html',
  styleUrls: ['./api-doc.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
})
export class ApiDocComponent implements OnInit {
  collection: any = {};
  apiUrl: string = '';
  apiKey: string = '';
  userEmail: string = '';
  header = '<code>x-ogo-organizationCode</code>';

  constructor(private auth: AuthService) {}

  ngOnInit() {
    this.collection = collection;
    this.apiUrl = `https://${location.host.replace(/^\w+([-\.])/i, 'api$1')}`;

    const user = this.auth.getUser();
    this.userEmail = user.email;
  }

  downloadApiDoc() {
    const data = new Blob([JSON.stringify(this.collection) as any], { type: 'json' });
    saveAs(data, 'OGO_API_Doc.json');
  }
}
