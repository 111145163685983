import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BasicLoginModule } from './basic-login/basic-login.module';
import { RegisterPasswordModule } from './register-password/register-password.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { MfaModule } from './email-mfa/mfa.module';

export const MFA_SUFFIX = 'mfa';
export const REGISTER_PASSWORD_SUFFIX = 'register-password';
export const RESET_PASSWORD = 'reset-password';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Login',
      status: false,
    },
    children: [
      {
        path: '',
        loadChildren: () => BasicLoginModule,
      },
      {
        path: MFA_SUFFIX,
        loadChildren: () => MfaModule,
      },
      {
        path: `${REGISTER_PASSWORD_SUFFIX}/:email/:token`,
        loadChildren: () => RegisterPasswordModule,
      },
      {
        path: `${RESET_PASSWORD}/:email/:token`,
        loadChildren: () => ResetPasswordModule,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
