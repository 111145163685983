import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { Global } from '../../global';
import { HostModeService } from '../../shared/hostMode/hostMode.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { AuthService } from 'app/services/auth.service';
import { EXTERNAL_HELP_ENGLISH_LINK, EXTERNAL_HELP_FRENCH_LINK, EXTERNAL_RFC_7234_CACHE_LINK } from 'app/app-routing.module';
import { firstValueFrom } from 'rxjs';

const PURGE_REGEXP = 'purge-regexp';
@Component({
  selector: 'app-cache',
  templateUrl: './cache.component.html',
  styleUrls: ['./cache.component.scss', '../../../assets/icon/icofont/css/icofont.scss', '../add-site.component.scss'],
})
export class CacheComponent implements OnInit {
  lang: string;

  @Input() hote: any = {};
  @Input() ruleUseCache: boolean;

  EXTERNAL_RFC_7234_CACHE_LINK = EXTERNAL_RFC_7234_CACHE_LINK;

  EXTERNAL_HELP_FRENCH_LINK = EXTERNAL_HELP_FRENCH_LINK;
  EXTERNAL_HELP_ENGLISH_LINK = EXTERNAL_HELP_ENGLISH_LINK;

  isAdvanced = false;
  purgeRegexp = '';

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
    private auth: AuthService,
    public hostModeService: HostModeService,
  ) {}

  ngOnInit(): void {
    this.lang = this.auth.getCurrentLanguage();
    this.purgeRegexp = localStorage.getItem(PURGE_REGEXP);
    this.isAdvanced = this.hostModeService.isAdvanced(this.hote.mode);
  }

  toggle(event) {
    event.preventDefault();

    const checked = event.target.checked;

    if (!checked && this.ruleUseCache) {
      this.hote.cacheEnabled = true;
      return this.toastr.error(this.translate.instant('RuleUseCache'));
    }

    Swal.fire({
      title: this.translate.instant('Confirmation'),
      html: this.translate.instant(checked ? 'EnableCache' : 'DisableCache'),
      ...this.alertOptions,
    } as SweetAlertOptions).then(async (result) => {
      if (result.value) {
        const res: any = await firstValueFrom(this.http.post(Global.baseUrl + 'hote/update', { datas: [{ id: this.hote.id, cacheEnabled: checked }] }));
        if (!res.hasError) {
          this.hote.cacheEnabled = checked;
          this.toastr.success(this.translate.instant('OperationSuccess'));
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }
      }
    });
  }

  async purge() {
    localStorage.setItem(PURGE_REGEXP, this.purgeRegexp);
    await firstValueFrom(this.http.post(Global.baseUrl + `v2/organizations/${this.auth.currentOrganization.id}/sites/${this.hote.nom}/purgeCache`, { path: this.purgeRegexp }));
    this.toastr.success(this.translate.instant('OperationSuccess'));
  }

  alertOptions = {
    showCancelButton: true,
    confirmButtonColor: '#4099ff',
    cancelButtonColor: '#d33',
    cancelButtonText: this.translate.instant('No'),
    confirmButtonText: this.translate.instant('YesConfirm'),
  };
}
