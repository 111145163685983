<div *ngIf="showView === pageViews.LIST" class="m-t-25">
  <div class="table-responsive">
    <div class="card">
      <button (click)="editEs(undefined)" class="btn btn-sm btn-primary">
        <strong [innerHtml]="'+ajouter' | translate"></strong>
      </button>
      <ngx-datatable
        [messages]="{ emptyMessage: 'NoData' | translate }"
        #myTable
        class="data-table table-responsive task-list-table"
        [rows]="elasticsearchs"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight]="50"
        [limit]="10"
        [rowHeight]="40"
      >
        <ngx-datatable-column [flexGrow]="2" name="Libellé">
          <ng-template let-column="column" ngx-datatable-header-template>
            <p class="dataheader" [innerHtml]="'ElasticSearch' | translate"></p>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a href="javascript:">{{ row.nom }}</a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [flexGrow]="3" name="Endpoint">
          <ng-template let-column="column" ngx-datatable-header-template>
            <p class="dataheader" [innerHtml]="'Endpoint' | translate"></p>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a href="javascript:" title="{{ row.endpoint }}">{{ row.endpoint }}</a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [flexGrow]="3" name="Endpoint">
          <ng-template let-column="column" ngx-datatable-header-template>
            <p class="dataheader" [innerHtml]="'EndpointES' | translate"></p>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a href="javascript:" title="{{ row.endpointEs }}">{{ row.endpointEs }}</a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [flexGrow]="3" name="Date">
          <ng-template let-column="column" ngx-datatable-header-template>
            <p class="dataheader">Date</p>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a href="javascript:">{{ row.createdAt | dateFormat }}</a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [flexGrow]="2" name="Actions">
          <ng-template let-column="column" ngx-datatable-header-template>
            <p class="dataheader" [innerHtml]="'Actions' | translate"></p>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <i (click)="editEs(row)" style="color: #4099ff; font-size: 20px" class="icofont icofont-edit-alt"></i>&nbsp;&nbsp;
            <i (click)="onDeleteEs(row)" style="color: #e74c3c; font-size: 20px" class="icofont icofont-ui-delete"></i>&nbsp;&nbsp;
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-curPage="curPage" let-pageSize="pageSize">
            <div *ngIf="lang == 'fr'">{{ elasticsearchs.length }} au total</div>
            <div *ngIf="lang == 'en'">
              {{ (curPage - 1) * 10 + 1 }} -
              {{ curPage * 10 >= rowCount ? rowCount : (curPage - 1) * 10 + 10 }}
              of
              {{ elasticsearchs.length }}
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'"
              [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              (change)="myTable.onFooterPage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</div>

<div *ngIf="showView === pageViews.CREATE" class="row">
  <div class="col-md-6 col-xs-12 offset-md-3">
    <div class="card">
      <div class="card-header">
        <h6 class="modal-title" *ngIf="elasticsearch.id !== undefined">{{ 'Modifier' | translate }} Es</h6>
        <h6 class="modal-title" *ngIf="elasticsearch.id === undefined">{{ 'Ajouter' | translate }} Es</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label [innerHtml]="'libelle' | translate"></label>
              <input [(ngModel)]="elasticsearch.nom" class="form-control" type="text" />
            </div>
            <div class="form-group">
              <label [innerHtml]="'Endpoint' | translate"></label>
              <textarea [(ngModel)]="elasticsearch.endpoint" rows="3" maxlength="255" class="form-control"></textarea>
            </div>
            <div class="form-group">
              <label [innerHtml]="'EndpointES' | translate"></label>
              <input [(ngModel)]="elasticsearch.endpointEs" class="form-control" type="text" />
            </div>
            <div class="form-group">
              <label>ES username credential</label>
              <input [(ngModel)]="elasticsearch.credentialUsername" class="form-control" type="text" />
            </div>
            <div class="form-group">
              <label>ES password credential</label>
              <input [(ngModel)]="elasticsearch.credentialPassword" class="form-control" type="text" />
            </div>
            <div class="form-group">
              <label [innerHtml]="'ClusterName' | translate"></label>
              <input [(ngModel)]="elasticsearch.clusterName" class="form-control" type="text" />
            </div>
            <div class="form-group">
              <label [innerHtml]="'IndexName' | translate"></label>
              <input [(ngModel)]="elasticsearch.indexName" class="form-control" type="text" />
            </div>
            <div class="form-group">
              <label [innerHtml]="'MaxClauseCount' | translate"></label>
              <input [(ngModel)]="elasticsearch.defaultClauseCount" class="form-control" type="text" />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button *ngIf="elasticsearch.id === undefined" (click)="onCreateEs(elasticsearch)" class="btn btn-mini btn-danger" [innerHtml]="'ajouter' | translate"></button>&nbsp;
        <button *ngIf="elasticsearch.id !== undefined" (click)="onUpdateEs(elasticsearch)" class="btn btn-mini btn-danger" [innerHtml]="'Modifier' | translate"></button>&nbsp;
        <button (click)="showView = pageViews.LIST" class="btn btn-mini btn-primary" [innerHtml]="'Annuler' | translate"></button>
      </div>
    </div>
  </div>
</div>
