<div class="card with-tabs">
  <div class="col-md-12">
    <div class="row" style="padding-top: 15px">
      <div class="col-md-12 mt-2">
        <h4 [innerHTML]="'MyNotifications' | translate"></h4>
      </div>
    </div>
    <hr />
    <div class="form-group flex-align-items space-between">
      <div>
        <label>{{ 'ReceiveMonthlyReport' | translate }}</label
        >&nbsp;
        <label
          class="badge badge-sm bg-dark"
          placement="top"
          container="body"
          ngbTooltip="{{ 'ReceiveMonthlyReportTooltip' | translate }}"
          >?</label
        >
      </div>
      <div class="switch-container">
        <span>{{ 'Désactivé' | translate }}</span>
        <label class="switch">
          <input
            type="checkbox"
            [(ngModel)]="notifications.monthlyReport"
            (change)="changeNotification('monthlyReport', $event.currentTarget.checked)"
          />
          <span class="slider round"></span>
        </label>
        <span>{{ 'Activé' | translate }}</span>
      </div>
    </div>
    <div class="form-group flex-align-items space-between">
      <div>
        <label>{{ 'AvertSecurityEventsFrom' | translate }}</label
        >&nbsp;
        <label
          class="badge badge-sm bg-dark"
          placement="top"
          container="body"
          ngbTooltip="{{ 'AvertSecurityEventsFromTooltip' | translate }}"
          >?</label
        >
      </div>
      <div class="switch-container">
        <select
          (change)="changeEventNotification('SECURITY', $event.target.value)"
          [(ngModel)]="notifications.eventNotificationMinSeverities.SECURITY"
          class="form-control form-control-sm"
          style="width: 175px"
        >
          <option value="null">{{ 'NoAlert' | translate }}</option>
          <option value="1">LOW</option>
          <option value="2" selected>MEDIUM</option>
          <option value="3">HIGH</option>
        </select>
      </div>
    </div>
    <div class="form-group flex-align-items space-between">
      <div>
        <label>{{ 'AvertMonitoringEventsFrom' | translate }}</label
        >&nbsp;
        <label
          class="badge badge-sm bg-dark"
          placement="top"
          container="body"
          ngbTooltip="{{ 'AvertMonitoringEventsFromTooltip' | translate }}"
          >?</label
        >
      </div>
      <div class="switch-container">
        <select
          (change)="changeEventNotification('MONITORING', $event.target.value)"
          [(ngModel)]="notifications.eventNotificationMinSeverities.MONITORING"
          class="form-control form-control-sm"
          style="width: 175px"
        >
          <option value="null">{{ 'NoAlert' | translate }}</option>
          <option value="1">LOW</option>
          <option value="2" selected>MEDIUM</option>
          <option value="3">HIGH</option>
        </select>
      </div>
    </div>
  </div>
</div>
