<div class="form-group flex-align-items m-t-20">
  <label class="mr-2" style="position: relative; top: 1px">
    <i class="icofont icofont-tags" id="icon-tags"></i>
    {{ translate.instant('Tags') }}
    <label class="badge badge-sm bg-dark" placement="right" [ngbTooltip]="translate.instant('TagsTooltip')">?</label>
  </label>
  <ng-select
    id="site-tags"
    [items]="tags"
    [multiple]="true"
    [closeOnSelect]="true"
    [placeholder]="translate.instant('TagsPlaceholder')"
    autocomplete="on"
    [(ngModel)]="selected"
    [addTagText]="translate.instant('NewTag')"
    [addTag]="true"
    [notFoundText]="translate.instant('NoTag')"
    (change)="addTag($event)"
  ></ng-select>
</div>
