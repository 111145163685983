<div class="container-fluid">
  <app-cluster-selector [cluster]="cluster" [clusters]="clusters" (selected)="clusterSelected($event)"></app-cluster-selector>

  <app-tabs>
    <app-tab [tabTitle]="'LogExport' | translate" [active]="activeTab == 'logExport'">
      <div class="card">
        <div class="col-md-12">
          <div class="row" style="padding-top: 15px">
            <div class="col-md-12 mt-2">
              <h4 class="mb-0">{{ 'LogExport' | translate }}</h4>
            </div>
          </div>
          <hr />
          <div class="form-group flex-align-items space-between">
            <div>
              <label>{{ 'PushLogRouteCluster' | translate }}</label
              >&nbsp;
            </div>
            <div class="switch-container" style="top: 3px">
              <span>{{ 'Désactivé' | translate }}</span>
              <label class="switch" placement="left" [ngbTooltip]="cluster.ownerId != user.id ? disabledNotOwner : !user.pushLog ? disabledNoPushLog : null">
                <ng-template #disabledNotOwner>
                  <div innerHTML="{{ 'OnlyClusterOwner' | translate }}"></div>
                </ng-template>
                <ng-template #disabledNoPushLog>
                  <div innerHTML="{{ 'NoPushLog' | translate }}"></div>
                </ng-template>
                <input [disabled]="cluster.ownerId != user.id || !user.pushLog" type="checkbox" [(ngModel)]="cluster.logExport" (change)="changeClusterPushLog($event)" />
                <span
                  [ngStyle]="{ opacity: cluster.ownerId == user.id && user.pushLog ? '1' : '0.5', cursor: cluster.ownerId == user.id && user.pushLog ? 'pointer' : 'not-allowed' }"
                  class="slider round"
                ></span>
              </label>
              <span>{{ 'Activé' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </app-tab>
  </app-tabs>
</div>
