<app-tabs>
  <app-tab [tabTitle]="'Parameters' | translate" [active]="activeTab == 'parameters'" [queryStringNav]="'parameters'">
    <app-organization></app-organization>
  </app-tab>
  <app-tab
    *ngIf="auth.userHasFeature(ACL_ORGANIZATION_USERS_MANAGE)"
    [tabTitle]="'utilisateurs' | translate"
    [active]="activeTab == 'users'"
    [queryStringNav]="'users'"
  >
    <app-organization-users></app-organization-users>
  </app-tab>
  <app-tab
    *ngIf="auth.userHasFeature(ACL_SITE_EDIT)"
    [tabTitle]="'SentShares' | translate"
    [active]="activeTab == 'sentShares'"
    [queryStringNav]="'sentShares'"
  >
    <app-sent-shares></app-sent-shares>
  </app-tab>
  <app-tab
    *ngIf="auth.userHasFeature(ACL_ORGANIZATION_MANAGE)"
    [tabTitle]="'ReceivedShares' | translate"
    [active]="activeTab == 'receivedShares'"
    [queryStringNav]="'receivedShares'"
  >
    <app-received-shares></app-received-shares>
  </app-tab>
  <app-tab
    *ngIf="auth.userHasFeature(ACL_PUSH_LOG_MANAGE)"
    [tabTitle]="'LogExport' | translate"
    [active]="activeTab == 'export'"
    [queryStringNav]="'export'"
  >
    <app-push-log></app-push-log>
  </app-tab>
</app-tabs>
