import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService, CLUSTER_DELEGATION_ADMIN } from '../../services/auth.service';
import _ from 'lodash';
import { Global } from 'app/global';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
})
export class MaintenanceComponent implements OnInit {
  user: any = {};
  activeTab = 'logExport';
  clusters = [];
  cluster: any = {};

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.user = this.auth.getUser();

    this.route.data.subscribe((data: { clusters: any }) => {
      this.clusters = data.clusters;
      this.clusterSelected(this.clusters[0]);
    });
  }

  changeClusterPushLog(event) {
    this.http.post(Global.baseUrl + 'cluster/setPushLog', { data: { id: this.cluster.id, logExport: event.currentTarget.checked } }).subscribe((res: any) => {
      if (!res.hasError) {
        this.cluster = res.items[0];
        this.toastr.success(this.translate.instant('OperationSuccess'));
      } else {
        this.toastr.error(this.translate.instant('OperationFailed'));
      }
    });
  }

  clusterSelected(cluster: any) {
    this.cluster = this.clusters.find((p: any) => p.id === Number(cluster.id)) || {};
  }
}
