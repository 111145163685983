<div class="card with-tabs p-3">
  <div class="row">
    <div class="col-md-12">
      <h5 class="flex-align-items">
        {{ 'Cache' | translate }}
        <label style="position: relative; top: 1px" class="badge badge-sm bg-dark ml-1"
          ><a
            style="color: inherit"
            target="_blank"
            [href]="
              lang == 'fr' ? EXTERNAL_HELP_FRENCH_LINK + '/cache-cdn' : EXTERNAL_HELP_ENGLISH_LINK + '/caching-cdn'
            "
            >?</a
          ></label
        >
      </h5>
      <hr />
      <div *ngIf="!isAdvanced" class="bg-blue mb-4" style="margin-top: -10px">
        {{ 'SubscribeToAdvanced' | translate }}
      </div>
      <div [ngStyle]="{ opacity: !isAdvanced ? '0.65' : '1' }">
        <div class="form-group flex-align-items space-between">
          <div>
            <div style="position: relative; top: 5px">
              {{ 'CacheDesc' | translate }}
              <a class="ogo-link" target="_blank" [href]="EXTERNAL_RFC_7234_CACHE_LINK"
                >RFC 7234<i class="icofont icofont-external-link ml-1"></i
              ></a>
            </div>
            &nbsp;
          </div>
          <div class="switch-container" style="top: 3px">
            <span>{{ 'Désactivé' | translate }}</span>
            <label class="switch" placement="left">
              <input
                type="checkbox"
                [(ngModel)]="hote.cacheEnabled"
                [disabled]="!isAdvanced"
                (click)="toggle($event)"
              />
              <span class="slider round"></span>
            </label>
            <span>{{ 'Activé' | translate }}</span>
          </div>
        </div>
        <div class="flex-align-items space-between">
          <div>
            <div>{{ 'PurgeCache' | translate }}</div>
            &nbsp;
          </div>
          <div class="flex-align-items">
            <input
              [(ngModel)]="purgeRegexp"
              [disabled]="!hote.cacheEnabled"
              class="form-control mr-2"
              placeholder="/static/.*"
              style="width: 250px"
              [disabled]="!isAdvanced"
            />
            <button
              class="btn btn-primary btn-sm"
              [disabled]="!hote.cacheEnabled"
              (click)="purge()"
              [disabled]="!isAdvanced"
            >
              {{ 'Purge' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
