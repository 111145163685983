import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { LoaderState } from './loader';

@Component({
  selector: 'app-http-loader',
  templateUrl: './http-loader.component.html',
  styleUrls: ['./http-loader.component.scss'],
})
export class HttpLoaderComponent implements OnInit, OnDestroy {
  show = false;
  compteur = 0;
  private subscription: Subscription;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
      this.compteur = state.show === true ? ++this.compteur : --this.compteur;
      if (this.compteur > 0) {
        this.show = true;
      } else {
        this.show = false;
        this.compteur = 0;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
