import moment from 'moment';

export interface DateRange {
  start: moment.Moment;
  end: moment.Moment;
}

export function duration(range: DateRange): moment.Duration {
  return moment.duration(range.end.diff(range.start));
}

export function durationMs(range: DateRange): number {
  return range.end.diff(range.start);
}

export function dateRangeStr(range: DateRange): string {
  return `[${range.start?.toISOString(true)},${range.end?.toISOString(true)}]`;
}
