<div class="container-fluid m-t-25 card p-3">
  <div *ngIf="brainTunables.length; else loader">
    <div class="row m-b-20">
      <div class="col-md-7"><b>Variable</b></div>
      <div class="col-md-2 m-r-20"><b>Valeur</b></div>
      <div class="col-md-2"><b>Visibilité</b></div>
    </div>
    <div *ngFor="let bt of brainTunables" class="row m-b-20">
      <div class="col-md-7 variable">
        {{ bt.actor }}
      </div>
      <input class="col-md-2 m-r-20 form-control" [(ngModel)]="bt.recommendedValue" [ngClass]="{ modified: bt.recommendedValue != bt.oldValue }" />
      <select class="col-md-2 form-control" [(ngModel)]="bt.visibility" [ngClass]="{ modified: bt.visibility != bt.oldVisibility }">
        <option value="expert">expert</option>
        <option value="adminCluster">adminCluster</option>
        <option value="admin">admin</option>
      </select>
    </div>
    <button type="button" (click)="setBrainTunable()" class="col-md-1 btn btn-primary" [innerHTML]="'Enregistrer' | translate"></button>
  </div>
  <ng-template #loader>
    <div class="theme-loader">
      <div class="loader-track">
        <div class="loader-bar"></div>
      </div>
    </div>
  </ng-template>
</div>
