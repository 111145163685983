import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'app/shared/toastr/toastr.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AdminService, OrganizationListItem } from 'app/admin.service';
import { PageView } from 'app/enums/enums';
import { AuthService } from '../../../services/auth.service';
import { copyObject } from 'app/shared/utils/data-utils';
import { RecursivePartial } from 'app/shared/utils/type-utils';
import Organization from 'app/services/entities/organization';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class OrganizationsComponent implements OnInit {
  @ViewChild('remove') remove: any;
  @ViewChild('search') search: ElementRef;
  lang: string;

  organization: Organization;
  organizations: OrganizationListItem[] = [];
  clusters: any = [];

  page: any = 1;
  pageSize: any = 20;
  totalItems: any = 0;

  pageViews = PageView;
  showView: PageView = PageView.LIST;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    public auth: AuthService,
    private admin: AdminService,
  ) {}

  async ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();
    await this.getOrganizations();
  }

  ngAfterViewInit(): void {
    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(debounceTime(300))
      .subscribe(async (evt: any) => {
        this.page = 0;
        await this.getOrganizations(evt.target.value);
      });
  }

  connectAsOwner(organization: OrganizationListItem) {
    this.admin.connectAsUser(organization.owner.id, organization.id);
  }

  resetIndex() {
    this.page = 1;
  }

  addOrganization() {
    this.organization = new Organization({ companyName: '', street: '', city: '', zipCode: '', countryCode: 'FR', owner: { email: '' } });
    this.showView = PageView.CREATE;
  }

  editOrganization(organization) {
    this.organization = copyObject(organization); // deep copy
    this.showView = PageView.EDIT;
  }

  async cancelAddEdit() {
    this.organization = null;
    await this.getOrganizations(this.search?.nativeElement?.value);
    this.showView = PageView.LIST;
  }

  modalRemove(p: any) {
    this.remove.show();
    this.organization = { ...p };
  }

  async changePage(evt: any) {
    this.page = evt;
    await this.getOrganizations(this.search?.nativeElement?.value);
  }

  async getOrganizations(searchTerm = '') {
    let res: any = null;
    try {
      res = await this.admin.getOrganizations(searchTerm, this.page - 1);
    } catch (error) {
      return this.toastr.error(error.error);
    }

    this.organizations = res?.content;
    this.totalItems = res?.totalElements;
  }

  async removeOrganization(id) {
    try {
      await this.admin.deleteOrganization(id);
    } catch (error) {
      return this.toastr.error(this.translate.instant(error?.error?.error || 'Erreur'));
    }

    this.toastr.success(this.translate.instant('SuppressionSucces'));
    this.remove.hide();
    await this.getOrganizations(this.search?.nativeElement?.value);
  }
}
