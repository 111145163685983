import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import _ from 'lodash';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService, CLUSTER_DELEGATION_ADMIN } from 'app/services/auth.service';
import { Global } from 'app/global';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { EXTERNAL_HELP_ENGLISH_LINK, EXTERNAL_HELP_FRENCH_LINK } from 'app/app-routing.module';
import { copyMap, copyObject, sortAlphabetically } from 'app/shared/utils/data-utils';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-ia-parameters',
  templateUrl: './ia-parameters.component.html',
  styleUrls: [
    './ia-parameters.component.scss',
    '../../add-site/expert/expert.component.scss',
    '../../../assets/icon/icofont/css/icofont.scss',
  ],
})
export class IAParametersComponent implements OnInit {
  lang: string;

  cluster: any = {};
  clusters: any = [];
  brainParams = [];
  brainTunables = [];
  previousBrainParams = [];
  collapsedGroups = [];
  editMode: boolean = false;
  errors = [];
  isLoading: boolean = false;
  adminView: boolean = false;
  driveSearch = '';

  EXTERNAL_HELP_FRENCH_LINK = EXTERNAL_HELP_FRENCH_LINK;
  EXTERNAL_HELP_ENGLISH_LINK = EXTERNAL_HELP_ENGLISH_LINK;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) {}

  ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();
    this.adminView = this.auth.userIsAdmin();
    this.route.data.subscribe((data: { clusters: any }) => {
      this.clusters = data.clusters;
      this.clusterSelected(this.clusters[0]);
    });
  }

  openAllGroups() {
    this.brainParams.forEach((group) => (group.open = true));
  }

  driveFilter = (drive: any) => {
    if (!this.driveSearch) return drive;
    return (
      drive.label.toLowerCase().includes(this.driveSearch.toLowerCase()) ||
      drive.desc.toLowerCase().includes(this.driveSearch.toLowerCase())
    );
  };

  clusterSelected(cluster: any) {
    this.cluster = cluster;
    this.brainParams = [];
    this.getBrainParams(cluster.id);
  }

  getBrainParams(clusterId) {
    let brainDrivesGroups = firstValueFrom(this.http.post(Global.baseUrl + 'brainDrivesGroup/get', {}));

    let brainTunables = firstValueFrom(this.http.post(Global.baseUrl + 'brainTunable/get', { data: { clusterId } }));

    Promise.all([brainTunables, brainDrivesGroups]).then((res: any) =>
      this.formatBrainParams(res[0].items || [], res[1].items || []),
    );
  }

  toggleEditMode() {
    if (this.editMode) {
      let datas = [];
      this.brainParams.forEach((g) =>
        g.drives.forEach((d) =>
          d.params.forEach((p) => {
            if (p.defaultValue != p.value) {
              if (p.value == '') {
                p.value = p.recommendedValue;
              }
              datas.push({
                actor: p.actor,
                defaultValue: p.defaultValue,
                value: p.actor.includes('Priority') ? (p.value === true ? 1 : 0) : p.value,
              });
            }
          }),
        ),
      );
      if (datas.length) {
        this.setBrainParameters(datas);
      } else {
        this.editMode = !this.editMode;
      }
    } else {
      this.editMode = !this.editMode;
    }
  }

  setBrainParameters(datas) {
    this.isLoading = true;
    this.http
      .post(Global.baseUrl + 'brainTunable/setClusterParameter', {
        datas,
        cluster: {
          id: this.cluster.id,
        },
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.editMode = !this.editMode;
          this.toastr.success(this.translate.instant('OperationSuccess'));
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }

        this.isLoading = false;
        this.getBrainParams(this.cluster.id);
      });
  }

  restoreRecommended() {
    this.triggerRestoreRecommended().then((result) => {
      if (result.value) {
        let datas = [];

        this.brainParams.forEach((g) =>
          g.drives.forEach((d) =>
            d.params.forEach((p) => {
              if (p.value && p.recommendedValue != p.value) {
                datas.push({ actor: p.actor, value: p.value });
              }
            }),
          ),
        );

        this.isLoading = true;
        this.http
          .post(Global.baseUrl + 'brainTunable/restoreRecommended', {
            datas,
            cluster: {
              id: this.cluster.id,
            },
          })
          .subscribe((res: any) => {
            if (!res.hasError) {
              this.editMode = !this.editMode;
              this.toastr.success(this.translate.instant('OperationSuccess'));
            } else {
              this.toastr.error(this.translate.instant('OperationFailed'));
            }

            this.isLoading = false;
            this.getBrainParams(this.cluster.id);
          });
      }
    });
  }

  formatBrainParams(brainTunables, brainDrivesGroups) {
    const isFrench = this.lang == 'fr';

    this.brainTunables = brainTunables;

    brainTunables.forEach((b) => {
      b.value = b.defaultValue;
    });

    const groupedByCodeAttack = _.groupBy(brainTunables, 'codesAttacks.id');
    const formattedParams = Object.keys(groupedByCodeAttack).map((index) => {
      const codeAttack = groupedByCodeAttack[index][0].codesAttacks;
      const hasModifiedParam = groupedByCodeAttack[index].some((p) => p.recommendedValue !== p.defaultValue);

      return {
        groupId: codeAttack.brainDrivesGroup.id,
        label: isFrench ? codeAttack.labelFr : codeAttack.labelEn,
        desc: isFrench ? codeAttack.descFr : codeAttack.descEn,
        uuid: codeAttack.driveUuid,
        hasModifiedParam,
        params: groupedByCodeAttack[index].map((p) => ({ label: isFrench ? p.labelFr : p.labelEn, ...p })),
      };
    });

    const groupedByGroupId = _.groupBy(formattedParams, 'groupId');
    this.brainParams = Object.keys(groupedByGroupId).map((index) => {
      const group = brainDrivesGroups.find((g) => g.id == groupedByGroupId[index][0].groupId);
      const countModifiedDrives = groupedByGroupId[index].reduce(
        (total, e) => (e.hasModifiedParam ? total + 1 : total),
        0,
      );

      const drives = groupedByGroupId[index].map((d) => ({
        ...d,
        params: _.orderBy(d.params, ['type', 'label'], ['desc', 'asc']).reverse(),
      }));

      drives.sort((a, b) => sortAlphabetically(b.label, a.label));

      return {
        id: group.id,
        groupName: isFrench ? group.labelFr : group.labelEn,
        open: true,
        countModifiedDrives,
        drives,
      };
    });

    this.previousBrainParams = copyObject(this.brainParams);
  }

  cancelUpdate() {
    this.editMode = false;
    this.brainParams = copyObject(this.previousBrainParams);
  }

  triggerRestoreRecommended() {
    return Swal.fire({
      title: 'Confirmation',
      html: this.translate.instant('RestoreRecommendedAlert'),
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
    } as SweetAlertOptions);
  }
}
