import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatatableComponent } from '@siemens/ngx-datatable';
import { DASHBOARD_ROUTE } from 'app/app-routing.module';
import { Cluster } from 'app/services/entities/organization';
import { LogsService } from 'app/services/logs.service';
import { BaseLogRequest, SitesService } from 'app/services/sites.service';
import { ANALYZED, BLOCKED, Donut, ROBOT, SUSPECT } from 'app/shared/highcharts/donut/donut';
import { Graph, TRAFIC } from 'app/shared/highcharts/graph/graph';
import moment, { Moment } from 'moment';
import { AuthService } from '../../services/auth.service';
import { BaseDashboardComponent } from '../dashboard/baseDashboardComponent';
import { copyObject } from 'app/shared/utils/data-utils';
import { DateRange } from '../../shared/utils/date-range';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
})
export class PrintComponent extends BaseDashboardComponent implements OnInit, OnDestroy {
  isLoading = true;
  period: DateRange;
  year: any = null;
  @ViewChild(DatatableComponent)
  ngxDatatable: DatatableComponent;
  userName = null;
  today = null;

  clusterId: string;
  cluster: Cluster = null;
  tags: string[] = [];
  siteNames: string[] = [];
  hasSiteFilter = false;
  startDate: string;
  endDate: string;

  get periodMoreThan7Days() {
    return this.period.end.diff(this.period.start, 'days') >= 7;
  }

  constructor(
    http: HttpClient,
    auth: AuthService,
    sites: SitesService,
    translate: TranslateService,
    logsService: LogsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    graph: Graph,
    donut: Donut,
    private titleService: Title,
  ) {
    super(http, sites, logsService, translate, auth, graph, donut);

    this.tableDataSize = 25;
    this.cluster = this.getRouterParam('cluster');
    this.tags = this.getRouterParam('tags');
    this.siteNames = this.getRouterParam('siteNames');
    this.hasSiteFilter = (this.siteNames?.length ?? 0) > 0;

    this.activatedRoute.queryParams.subscribe((params) => {
      this.period = {
        start: this.parseDateTime(params.start),
        end: this.parseDateTime(params.end),
      };
      this.clusterId = params.clusterId;
      if (params.organizationCode && this.auth.currentOrganization.code != params.organizationCode) {
        const organization = this.auth.organizations.find((o) => o.code == params.organizationCode);
        if (organization) this.auth.changeCurrentOrganization(organization.id);
        else console.error(`Cannot find organization with code ${params.organizationCode}`);
      }
    });
  }

  async ngOnInit() {
    document.title =
      (this.lang == 'fr' ? 'Rapport_OGO-' : 'OGO_Report-') +
      this.period.start.format('L') +
      '_' +
      this.period.end.format('L');

    await this.sites.load(this.ctx);
    if (!this.siteNames || this.siteNames.length == 0) {
      await this.loadHosts(this.clusterId);
    }

    await this.loadData();

    this.today = moment().format(this.lang == 'fr' ? 'DD/MM/YYYY HH:mm' : 'L LTS [GMT]');
    this.startDate = this.formatDateTimeGmt(this.period.start);
    this.endDate = this.formatDateTimeGmt(this.period.end);
    this.userName = `${this.auth.getUser().nom} ${this.auth.getUser().prenoms}`;

    setTimeout(() => this.print());
  }

  private print() {
    window.print();
    this.router.navigate([DASHBOARD_ROUTE]);
  }

  ngOnDestroy() {
    this.titleService.setTitle('.OGO Dashboard');
  }

  private formatDateTimeGmt(date: Moment) {
    return date.format(this.lang == 'fr' ? 'DD/MM/YYYY HH:mm' : 'L LTS [GMT]');
  }

  private parseDateTime(date: any): Moment {
    return typeof date === 'string' ? (/^[0-9]+$/.test(date) ? moment(parseInt(date)) : moment(date)) : moment(date);
  }

  async loadHosts(clusterId: string) {
    const clusters = this.sites[this.ctx].store.clusters;
    if (this.clusterId) this.cluster = clusters.find((c) => c.clusterId == clusterId);
    // TODO: remove after 2025-01-01 (Backward compat for existing URLs contained in monthly report emails)
    else this.cluster = this.sites[this.ctx].current.cluster;

    if (!this.cluster)
      throw new Error(
        `Cluster not found: ${clusterId}. Available clusters: ${clusters.map((c) => c.clusterId).join()}.`,
      );

    this.siteNames = this.sites[this.ctx].store.sitesList
      .filter((site) => site.cluster.clusterId == this.cluster.clusterId)
      .map((site) => site.name);
  }

  private buildRequestData(): BaseLogRequest {
    return {
      clusterId: this.cluster.id,
      tags: this.tags,
      sites: this.hasSiteFilter ? this.siteNames : null,
      beginDate: this.period.start,
      endDate: this.period.end,
    };
  }

  async loadData() {
    const request = this.buildRequestData();
    await Promise.all([this.getBlockAttack(request), this.getDateHistogram(request), this.getOrigineAttaques(request)]);

    const request7Days = copyObject(request);
    request7Days.beginDate = moment(this.period.end).subtract(7, 'days').startOf('day');

    await this.getTopConsumingRequest(request7Days);
    await this.getSlowRequest(request7Days);
    await this.getTopBlockedIps(request7Days);

    this.isLoading = false;
  }

  getRouterParam(param) {
    return this.router.getCurrentNavigation()?.extras?.state?.[param];
  }

  protected readonly BLOCKED = BLOCKED;
  protected readonly ROBOT = ROBOT;
  protected readonly TRAFIC = TRAFIC;
  protected readonly ANALYZED = ANALYZED;
  protected readonly SUSPECT = SUSPECT;
}
