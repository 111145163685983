import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Global } from '../global';
import { HostModeService } from '../shared/hostMode/hostMode.service';
import { ACL_PUSH_LOG_ADD_SITE, ACL_SITE_ACTIONS, AuthService } from '../services/auth.service';
import { SitesService } from '../services/sites.service';
import { validateDomainName } from '../shared/validators/validators';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss', '../../assets/icon/icofont/css/icofont.scss'],
})
export class AddSiteComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() hote: any = {};
  @Input() user: any = {};

  @Output() hoteAdded = new EventEmitter<boolean>();

  @ViewChild('domainName') domainName: ElementRef;
  @ViewChild('hostMode') hostMode: ElementRef;

  ACL_PUSH_LOG_ADD_SITE = ACL_PUSH_LOG_ADD_SITE;
  ACL_SITE_PUSH_LOG = ACL_SITE_ACTIONS.PUSH_LOG_SITE;

  activeTab: string = 'general';
  provAlias: boolean = false;
  provisionningInProgress: boolean = false;
  tags = [];
  selectedTags = [];
  isACertificateInError = false;
  diagnoseResult: DiagResult;
  diagnoseHttps = false;
  url: any = {};
  urlParam: any = {};
  clusters: any = [];
  rules = [];
  ruleUseCache = false;

  constructor(
    private http: HttpClient,
    public auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private sites: SitesService,
    public hostModeService: HostModeService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hote && changes.hote.currentValue) {
      this.hote = changes.hote.currentValue;
      this.show(this.hote);
      this.getTags();
    }
  }

  ngOnInit() {
    this.user = this.auth.getUser();

    if (this.hote.id) {
      // for update site, deduct Cluster from host
      this.hote.cluster = {
        id: this.hote.clusterId,
        ip: this.hote.clusterIp,
        ipv6: this.hote.clusterIpV6,
        supportsIpv6Origins: this.hote.clusterSupportsIpV6Origin,
        ipsToWhitelist: this.hote.clusterIPsToWhitelist,
      };

      // load access rules here because Cache & Access Rules tabs need it
      this.getRules();
    } else {
      this.getClusters(); // for add site, can chose any clusters
    }
  }

  ngAfterViewInit() {
    fromEvent(this.domainName.nativeElement, 'keyup')
      .pipe(debounceTime(1000))
      .subscribe((str: any) => {
        this.urlParam = {};
        this.checkDnsHandler(str.target.value);
      });
  }

  async show(h: any) {
    this.urlParam = {};
    this.hote = h ? Object.assign({}, h) : {};
    this.hote.initialHost = this.hote.nom;
    this.hote.destHostTmp = this.hote.destHost;
    this.hote.destination = this.hote.adresseIp;
    this.hote.destHostScheme = this.hote.destHostScheme || 'https';
    this.hote.dryRun = this.hote.dryRun === undefined ? false : this.hote.dryRun;
    this.hote.forceHttps = this.hote.forceHttps === undefined ? true : this.hote.forceHttps;
    this.hote.hsts = this.hote.hsts === undefined ? 'none' : this.hote.hsts;
    if (!this.hote.forceHttps) {
      this.hote.https = 'http';
    } else {
      this.hote.https = this.hote.hsts == 'none' ? 'https' : this.hote.hsts;
    }
    this.hote.noCopyXForwarded = this.hote.noCopyXForwarded === undefined ? false : this.hote.noCopyXForwarded;
    this.hote.trustSelfSigned = this.hote.trustSelfSigned === undefined ? true : this.hote.trustSelfSigned;
    this.hote.panicMode = this.hote.panicMode === undefined ? false : this.hote.panicMode;
    this.hote.cluster = h ? _.find(this.clusters, (p: any) => p.clusterId === h.clusterId) : undefined;
    this.selectedTags = this.hote.tags === undefined ? [] : this.hote.tags.map((tag) => tag.tag);

    this.checkDns(this.hote.nom);
  }

  checkDnsHandler(domain = ''): boolean {
    if (domain === undefined) {
      return;
    }

    if (!validateDomainName(domain)) {
      this.toastr.info(this.translate.instant('AdressNotValid'));
      return;
    }

    this.checkDns(domain);
  }

  checkDns(p: string) {
    if (p == null) {
      return;
    }
    this.urlParam = {};
    this.http
      .post(Global.baseUrl + 'hote/resolve', {
        data: { nom: p.trim() },
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.urlParam = res.items[0];
          if (!Object.keys(this.urlParam).length) {
            this.urlParam.noRecord = true;
            return this.toastr.warning(this.translate.instant('NoRecord'));
          }
          if (this.hote.id === undefined) {
            this.hote.destHost =
              this.urlParam.cname ||
              this.urlParam.ipv4Address ||
              (this.hote.cluster.supportsIpv6Origins && this.urlParam.ipv6Address ? this.urlParam.ipv6Address : null);
          }
        } else {
          this.toastr.error(this.translate.instant('Erreur'));
        }
      });
  }

  async createHost(host: any) {
    let hote = Object.assign({}, host);
    hote.destHost = hote.destHost.trim();
    hote.nom = hote.nom.toLowerCase();
    hote.clusterId = hote.cluster.id;
    hote.forceHttps = hote.https != 'http';
    hote.hsts = hote.https == 'http' || hote.https == 'https' ? 'none' : hote.https;
    hote.port = hote.port ? hote.port.toString() : null;
    hote.tags = this.selectedTags;
    if (this.hostMode) {
      hote.mode = this.hostMode.nativeElement.value;
    }

    const { https, cluster, ...h } = hote;

    let req = {
      datas: [h],
    };

    this.provisionningInProgress = true;

    this.http.post(Global.baseUrl + 'hote/create', req).subscribe(
      (res: any) => {
        if (!res.hasError) {
          this.toastr.success(this.translate.instant('SiteSuccesfullyAdded'));
          this.sites.purgeContext();
          this.hoteAdded.emit(true);
          return;
        } else {
          this.toastr.error(this.translate.instant(res.status.message));
          return;
        }
      },
      (err: any) => {
        console.log('Error on createHost : ', err);
      },
      () => {
        this.restoreDefault();
      },
    );
  }

  updateHost(host: any) {
    let hote = Object.assign({}, host);
    hote.destHost = hote.destHost.trim();
    hote.nom = hote.nom.toLowerCase();
    hote.forceHttps = hote.https != 'http';
    hote.hsts = hote.https == 'http' || hote.https == 'https' ? 'none' : hote.https;
    hote.port = hote.port ? hote.port.toString() : null;
    hote.tags = this.selectedTags;
    if (this.hostMode) {
      hote.mode = this.hostMode.nativeElement.value;
    }
    const { https, cluster, ...h } = hote;

    const req = {
      datas: [h],
    };

    this.provisionningInProgress = true;

    this.http.post(Global.baseUrl + 'hote/update', req).subscribe(
      (res: any) => {
        if (!res.hasError && res.items && res.items[0]) {
          this.toastr.success(this.translate.instant('SiteModifieSucc'));
          this.sites.purgeContext();
          this.hoteAdded.emit(true);
          return;
        } else if (res.status.code == '938') {
          this.hostMode.nativeElement.value = this.hote.mode;
          this.toastr.error(res.status.message);
          return;
        } else {
          this.toastr.error(this.translate.instant(res.status.message));
          return;
        }
      },
      (err: any) => {
        console.log('Error on updateHost : ', err);
      },
      () => {
        this.restoreDefault();
      },
    );
  }

  getClusters() {
    this.clusters = this.auth.currentOrganization.clusters.map((c) => c.cluster);
    this.hote.cluster = this.clusters.find((c) => c.id == this.hote.clusterId) || this.clusters[0];
    this.clusterChange();
  }

  clusterChange() {
    if (!this.hote.id) {
      this.hote.mode = this.hote.cluster.defaultSiteMode;
    }
  }

  diagnose(nom) {
    this.diagnoseResult = null;
    this.diagnoseHttps = this.hote.destHostScheme == 'https';
    this.http
      .post<DiagResult>(Global.baseUrl + 'v2/sites/diagnose', {
        nom,
        clusterId: this.hote.cluster.id,
        destHost: this.hote.destHost,
        port: this.hote.port ? this.hote.port.toString() : null,
        destHostScheme: this.hote.destHostScheme,
        trustSelfSigned: this.hote.trustSelfSigned,
      })
      .subscribe({
        next: (res) => (this.diagnoseResult = res),
        error: () => this.toastr.error(this.translate.instant('OriginInvalid')),
      });
  }

  onPasteDomain(event) {
    if (event.target.value.length == 0 || window.getSelection().toString() == event.target.value) {
      event.preventDefault();
      let paste = (event.clipboardData || window['clipboardData']).getData('text');
      paste = paste.trim();
      event.target.value = paste;
      this.hote.nom = paste;
    }
  }

  onPasteDestination(event) {
    if (event.target.value.length == 0 || window.getSelection().toString() == event.target.value) {
      event.preventDefault();
      let paste = (event.clipboardData || window['clipboardData']).getData('text');
      paste = paste.trim();
      if (paste.slice('-1') == '.') {
        event.target.value = paste.slice(0, -1);
      } else {
        event.target.value = paste;
      }
      this.hote.destHost = event.target.value;
    }
  }

  restoreDefault() {
    this.provAlias = false;
    this.provisionningInProgress = false;
  }

  validate() {
    return (
      !this.hote.destHost ||
      !this.hote.nom ||
      !this.hote.cluster ||
      this.provisionningInProgress ||
      (!this.urlParam.ipv4Address && this.urlParam.ipv6Address)
    );
  }

  async getTags() {
    this.tags = await this.sites.loadTags();
  }

  pushTags(event) {
    this.selectedTags = event;
  }

  getRules() {
    this.http
      .post(Global.baseUrl + 'rule/get', {
        data: {
          hoteId: this.hote.id,
        },
      })
      .subscribe(
        (res: any) => {
          this.rules = (res.items || []).reverse();
          this.rules.sort((a, b) => parseFloat(a.priority) - parseFloat(b.priority));

          if (this.rules.find((r) => r.cache)) {
            this.ruleUseCache = true;
          } else {
            this.ruleUseCache = false;
          }
        },
        (error: any) => {
          this.toastr.error(error);
        },
      );
  }

  siteHasAccessRight(right) {
    return this?.hote?.accessRights?.includes(right);
  }
}

interface DiagResult {
  IssueDetected: boolean;
  Backends: { [prop: string]: DiagBackend };
}

interface DiagBackend {
  ResolvStatus: DiagStatus;
  IPs: { [prop: string]: DiagIp };
}

interface DiagIp {
  HttpDiag: Diag;
  HttpsDiag: Diag;
  Http2Diag: Diag;
  TLSDiag: Diag;
}

interface Diag {
  Status: DiagStatus;
  Issues: string[];
}

type DiagStatus = 'OK' | 'KO' | 'NA';
