<div class="container-fluid">
  <app-cluster-selector [cluster]="cluster" [clusters]="clusters" (selected)="clusterSelected($event)"></app-cluster-selector>

  <div class="row">
    <div class="col-md-6">
      <div class="card" style="padding: 10px">
        <div id="gauge-1" appGauge [min]="0" [max]="maxRequest" [value]="data.rate || 0"></div>
        <h6 class="text-center">{{ 'Requêtes par seconde au 95ème percentile sur 7 jours' | translate }}</h6>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card" style="padding: 10px">
        <div id="gauge-2" appGauge [min]="0" [max]="maxSite" [value]="data.sitesCount || 0"></div>
        <h6 class="text-center">{{ 'Sites actifs' | translate }}</h6>
      </div>
    </div>
  </div>

  <div class="row" style="margin-top: 25px">
    <div class="col-md-6">
      <div class="card" style="padding: 10px">
        <div *ngIf="data.volumetry" class="count">{{ data.volumetry }} millions</div>
        <div *ngIf="!data.volumetry" class="count">0</div>
        <h6 class="text-center">{{ 'VolumetryLast30Days' | translate }}</h6>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card" style="padding: 10px">
        <div *ngIf="data.bandwidth" class="count">{{ data.bandwidth }} {{ 'GB' | translate }}</div>
        <div *ngIf="!data.bandwidth" class="count">0</div>
        <h6 class="text-center">{{ 'BandwidthLast30Days' | translate }}</h6>
      </div>
    </div>
  </div>
</div>
