import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TooltipMenuService {
  private closeAllTooltipsSubject = new Subject<void>();

  // Observable que les composants peuvent écouter pour fermer les tooltips
  closeAllTooltips$ = this.closeAllTooltipsSubject.asObservable();

  // Méthode pour déclencher la fermeture des tooltips
  closeAllTooltips() {
    this.closeAllTooltipsSubject.next();
  }
}
