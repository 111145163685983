import { Injectable } from '@angular/core';
import moment from 'moment';

export const DATE_NOW = () => moment();
export const DATE_LAST_15_MINUTES = () => moment().subtract(15, 'minutes');
export const DATE_LAST_HOUR = () => moment().subtract(1, 'hours');
export const DATE_LAST_4_HOURS = () => moment().subtract(4, 'hours');
export const DATE_TODAY = () => moment().startOf('day');
export const DATE_YESTERDAY_START = () => moment().subtract(1, 'days').startOf('day');
export const DATE_YESTERDAY_END = () => moment().subtract(1, 'days').endOf('day');
export const DATE_LAST_7_DAYS = () => moment().subtract(6, 'days');
export const DATE_LAST_30_DAYS = () => moment().subtract(29, 'days');
export const DATE_LAST_90_DAYS = () => moment().subtract(89, 'days');
export const DATE_ALL = () => moment.unix(0).startOf('day');

@Injectable({
  providedIn: 'root',
})
export class CalendarDataService {
  getRangesTranslations = (ranges, locale) => {
    let rangesTranslated = {};

    ranges.forEach((r) => {
      switch (r) {
        case '15M':
          rangesTranslated[locale == 'fr' ? '15 dernières minutes' : 'Last 15 minutes'] = [
            DATE_LAST_15_MINUTES(),
            DATE_NOW(),
          ];
          break;
        case '1H':
          rangesTranslated[locale == 'fr' ? 'Dernière heure' : 'Last hour'] = [DATE_LAST_HOUR(), DATE_NOW()];
          break;
        case '4H':
          rangesTranslated[locale == 'fr' ? '4 dernières heures' : 'Last 4 hours'] = [DATE_LAST_4_HOURS(), DATE_NOW()];
          break;
        case 'T':
          rangesTranslated[locale == 'fr' ? "Aujourd'hui" : 'Today'] = [DATE_TODAY(), DATE_NOW()];
          break;
        case 'Y':
          rangesTranslated[locale == 'fr' ? 'Hier' : 'Yesterday'] = [DATE_YESTERDAY_START(), DATE_YESTERDAY_END()];
          break;
        case '7':
          rangesTranslated[locale == 'fr' ? '7 derniers jours' : 'Last 7 days'] = [DATE_LAST_7_DAYS(), DATE_NOW()];
          break;
        case '30':
          rangesTranslated[locale == 'fr' ? '30 derniers jours' : 'Last 30 days'] = [DATE_LAST_30_DAYS(), DATE_NOW()];
          break;
        case '90':
          rangesTranslated[locale == 'fr' ? '90 derniers jours' : 'Last 90 days'] = [DATE_LAST_90_DAYS(), DATE_NOW()];
          break;
        case 'All':
          rangesTranslated[locale == 'fr' ? 'Tout' : 'All'] = [DATE_ALL(), DATE_NOW()];
          break;
      }
    });
    return rangesTranslated;
  };

  englishLocal: any = {
    format: 'MM DD, YYYY',
    separator: ' to ',
    applyLabel: 'Apply',
    cancelLabel: 'Cancel',
    fromLabel: 'From',
    toLabel: 'To',
    customRangeLabel: 'Edit',
    weekLabel: 'S',
    daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    firstDay: 1,
  };

  frenchLocal: any = {
    format: 'DD/MM/YYYY',
    separator: ' au ',
    applyLabel: 'Appliquer',
    cancelLabel: 'Annuler',
    fromLabel: 'De',
    toLabel: 'A',
    customRangeLabel: 'Modifier',
    weekLabel: 'S',
    daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    monthNames: [
      'Janvier',
      'Fevrier',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Decembre',
    ],
    firstDay: 1,
  };
}
