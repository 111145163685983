<section class="json-viewer">
  <section *ngFor="let segment of segments" [ngClass]="['segment', 'segment-type-' + segment.type]">
    <section
      (click)="toggle(segment)"
      [ngClass]="{
        'segment-main': true,
        expandable: isExpandable(segment),
        expanded: segment.expanded
      }"
    >
      <div *ngIf="isExpandable(segment)" class="toggler"></div>
      <span class="segment-key" [ngClass]="{ highlighted: isHighlighted(segment.parent ? segment.parent + '.' + segment.key : segment.key) }">{{ segment.key }}</span>
      <span class="segment-separator">: </span>
      <span *ngIf="!segment.expanded || !isExpandable(segment)" class="segment-value"> {{ segment.description }}</span>
    </section>
    <section *ngIf="segment.expanded && isExpandable(segment)" class="children">
      <json-viewer [json]="segment.value" [parentKey]="segment.key" [expanded]="expanded" [highlightedFields]="highlightedFields" [depth]="depth" [_currentDepth]="_currentDepth"></json-viewer>
    </section>
  </section>
</section>
