import { TranslateService } from '@ngx-translate/core';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-site-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
})
export class TagsComponent {
  @Input('tags') tags: string[];
  @Output() add = new EventEmitter<string>();
  @Input('selected') selected = [];

  constructor(public translate: TranslateService) {}

  addTag = (tag) => {
    this.add.emit(tag);
  };
}
