import { Env } from './env';

export const environment: Env = {
  URL: 'https://dashboard.ogosecurity.com/api/ogo-backend/',
  featureFlags: {},
  reloadOnError: true,
  silentConsole: true,
  detectVersionChange: true,
  enableGrooveHQ: true,
  version: '82fc20',
};
