import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@siemens/ngx-datatable';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AdminService } from 'app/admin.service';
import { PageView } from 'app/enums/enums';
import { UsersService } from 'app/services/users.service';
import { Global } from '../../../global';

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class UtilisateurComponent implements OnInit, AfterViewInit {
  lang: string;

  ColumnMode = ColumnMode;
  page: any = 1;
  pageSize: any = 10;
  totalItems: any = 0;
  profils: any = [];
  utilisateurs: any = [];
  clusters: any = [];
  utilisateur: any = {};
  pageViews = PageView;
  showView: PageView = PageView.LIST;
  @ViewChild('remove') remove: any;
  @ViewChild('search') search: ElementRef;
  user: any;

  apiKeyPlaceHolder = '******';

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
    private users: UsersService,
    public admin: AdminService,
  ) {}

  ngOnInit() {
    this.getProfil();
    this.getClusters();
  }

  ngAfterViewInit(): void {
    this.getUsers();
  }

  resetIndex() {
    this.page = 1;
  }

  editUser(u?: any) {
    this.showView = PageView.EDIT;
    this.utilisateur = {
      id: u.id,
      email: u.email,
      firstName: u.firstName,
      lastName: u.lastName,
      lang: u.lang,
      profile: u.profile,
      organizations: u.organizations,
      lastLoginDate: u.lastLoginDate,
      apiKey: u.hasApiKey ? this.apiKeyPlaceHolder : null,
    };
  }

  modalRemove(p: any) {
    this.remove.show();
    this.user = { ...p };
  }

  getProfil() {
    this.http
      .post(Global.baseUrl + 'profil/get', {
        data: {},
      })
      .subscribe((res: any) => {
        this.profils = res.items;
      });
  }

  changePage(evt: any) {
    this.page = evt;
    this.getUsers();
  }

  getUsers() {
    this.users.loadUsersPaginated(this.search.nativeElement.value, this.page - 1, this.pageSize).then((res: any) => {
      this.totalItems = res.totalElements;
      this.utilisateurs = res.content;
    });
  }

  getClusters() {
    this.http
      .post(Global.baseUrl + 'cluster/get', {
        data: {},
      })
      .subscribe((res: any) => {
        this.clusters = res.items;
      });
  }

  updateUser(p: any) {
    const req = { ...p };
    delete req.id;
    if (req.apiKey == this.apiKeyPlaceHolder) {
      delete req.apiKey;
      delete req.lastLoginDate;
    }
    this.http.put(Global.baseUrl + 'admin/users/' + this.utilisateur.id, req).subscribe((res: any) => {
      this.utilisateur = {};
      this.showView = PageView.LIST;
      this.getUsers();
    });
  }

  removeUser() {
    this.http.delete(Global.baseUrl + 'admin/users/' + this.user.id).subscribe((res: any) => {
      this.toastr.success(this.translate.instant('SuppressionSucces'));
      this.user = {};
      this.remove.hide();
      this.getUsers();
    });
  }

  resendRegistrationMail(user) {
    this.http
      .post(Global.baseUrl + 'utilisateur/generateToken/registration', {
        data: {
          email: user.email,
        },
      })
      .subscribe((res: any) => {
        if (res.hasError) {
          this.toastr.warning(this.translate.instant('OperationFailed'));
        } else {
          this.toastr.success(this.translate.instant('OperationSuccess'));
        }
      });
  }
}
