<div class="card with-tabs p-3" style="overflow-x: auto">
  <div class="row">
    <div class="col-md-12">
      <h5 class="modal-title" style="margin-bottom: 20px">
        {{ 'ParametreSSL' | translate }}
      </h5>
      <div>
        <table
          class="journal-table table table-striped table-bordered table-xs"
          style="min-width: 1000px; width: 100% !important"
        >
          <thead>
            <tr>
              <th>{{ 'Statut' | translate }}</th>
              <th>{{ 'Type de certificat' | translate }}</th>
              <th>{{ 'LastUpdate' | translate }}</th>
              <th>{{ 'Fin de validité' | translate }}</th>
              <th style="width: 158px">Actions</th>
              <th style="width: 30px"></th>
            </tr>
          </thead>
          <tbody>
            <ng-template ngFor let-item [ngForOf]="certificats" let-i="index">
              <tr>
                <td style="width: 50px">
                  <span *ngIf="item.hasCsr && !item.hasCert">
                    <label class="label" style="background: #f39c12 !important" p>{{
                      'FournirCert' | translate
                    }}</label>
                  </span>
                  <span *ngIf="item.hasCert && item.isActive">
                    <label class="label label-success">{{ 'Actif' | translate }}</label>
                  </span>
                  <span *ngIf="item.hasCert && !item.isActive">
                    <button
                      [disabled]="item.hasCert !== true"
                      class="btn btn-mini btn-outline-success"
                      (click)="activateCert(item)"
                    >
                      {{ 'Activer le certificat' | translate }}
                    </button>
                  </span>
                  <span *ngIf="item.error" style="margin-left: 5px" [ngbTooltip]="item.error">
                    <i class="icon-warning icofont icofont-warning"></i>
                  </span>
                </td>
                <td>
                  <span *ngIf="item.typeCertificatCode == 'CE'"> Client</span>
                  <span *ngIf="item.typeCertificatCode == 'LE'"> .OGO (Let's Encrypt R3)</span>
                </td>
                <td>
                  {{ item.updatedAt | dateFormat }}
                </td>
                <td>
                  {{ item.expiredAt ? (item.expiredAt | dateFormat) : '-' }}
                </td>
                <td>
                  <div class="flex-align-items cert-actions">
                    <i
                      (click)="downloadCertificate(item.fullChainCert, 'fullchain.crt')"
                      *ngIf="item.fullChainCert"
                      title="{{ 'DownloadFullchain' | translate }}"
                      class="pointer icon icon-download mr-3"
                      >FC</i
                    >
                    <i
                      *ngIf="item.typeCertificatCode == 'CE' && !item.hasCert"
                      (click)="downloadCertificate(item.csr, 'csr.txt')"
                      title="{{ 'DownloadCsr' | translate }}"
                      class="pointer icon icon-download mr-3"
                      >CSR</i
                    >
                    <i
                      *ngIf="
                        item.typeCertificatCode == 'CE' ||
                        (item.typeCertificatCode == 'LE' && item.error && !item.fullChainCert)
                      "
                      (click)="confirmDeleteCert(item)"
                      style="margin-left: auto; color: #e74c3c; position: relative; top: 3px"
                      class="pointer icofont icofont-ui-delete mr-1"
                    ></i>
                  </div>
                </td>
                <td>
                  <i
                    (click)="item.expanded = true"
                    *ngIf="item.typeCertificatCode == 'CE' && !item.hasCert"
                    [hidden]="item.expanded === true"
                    class="icon icon-angle-left"
                  ></i>
                  <i
                    (click)="item.expanded = false"
                    *ngIf="item.typeCertificatCode == 'CE' && !item.hasCert"
                    [hidden]="item.expanded !== true"
                    class="icon icon-angle-down"
                  ></i>
                </td>
              </tr>
              <tr [hidden]="item.expanded !== true" *ngIf="item.stepNbr < 2">
                <td colspan="5" style="border: none; padding: 0">
                  <table class="table table-xs cert-steps-table">
                    <tbody>
                      <tr>
                        <td>{{ 'Etape1' | translate }}</td>
                        <td>
                          {{ 'TelechargerCsr' | translate }}&nbsp;
                          <label
                            class="badge badge-sm bg-dark"
                            placement="top"
                            container="body"
                            ngbTooltip="{{ 'TelecharCsrFournisseur' | translate }}"
                            >?</label
                          >
                        </td>
                        <td>
                          <b
                            title="{{ 'DownloadCsr' | translate }}"
                            class="h4"
                            (click)="downloadCertificate(item.csr, 'csr.txt')"
                            ><i class="icon icon-download"></i></b
                          >&nbsp;&nbsp;
                        </td>
                        <td>
                          <b class="h4"><i class="icofont icofont-check-circled text-success"></i></b>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ 'Etape2' | translate }}</td>
                        <td>
                          {{ 'FournirCert' | translate }}&nbsp;
                          <label
                            class="badge badge-sm bg-dark"
                            placement="top"
                            container="body"
                            ngbTooltip="{{ 'TelecharCsrAutoriteCertif' | translate }}"
                            >?</label
                          >
                        </td>
                        <td>
                          <b title="{{ 'UploadFullchain' | translate }}" class="h4" (click)="showModalAddChain(item)"
                            ><i class="icon icon-export"></i></b
                          >&nbsp;&nbsp;
                        </td>
                        <td>
                          <b *ngIf="item.stepNbr >= 1" class="h4"
                            ><i class="icofont icofont-check-circled text-success"></i
                          ></b>
                        </td>
                      </tr>
                      <tr [attr.disabled]="item.hasCert !== true">
                        <td [style.text-decoration]="item.hasCert !== true ? 'line-through' : 'none'">
                          {{ 'Etape3' | translate }}
                        </td>
                        <td>
                          <button
                            [disabled]="item.hasCert !== true"
                            class="btn btn-sm btn-outline-success"
                            (click)="activateCert(item.id)"
                            [style.text-decoration]="item.hasCert !== true ? 'line-through' : 'none'"
                          >
                            {{ 'Activer le certificat' | translate }}
                          </button>
                        </td>
                        <td></td>
                        <td>
                          <b *ngIf="item.stepNbr >= 2" class="h4"
                            ><i class="icofont icofont-check-circled text-success"></i
                          ></b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-template>
            <tr *ngIf="!certificats.length">
              <td class="text-center" colspan="7">
                {{ 'NoCerts' | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12" style="margin-left: -13px">
        <button (click)="modalNewCsr.show()" class="btn btn-primary btn-sm pull-left">
          {{ 'CreerCsr' | translate }}
        </button>
        <button (click)="showModalPkcs12()" class="btn btn-primary btn-sm pull-left ml-3">
          {{ 'ImportPKCS12' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- AJOUT CSR -->
<app-modal-basic #modalNewCsr [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small>{{ 'Ajouter un nouveau CSR' | translate }}</small>
    </h4>
    <button type="button" class="close basic-close" (click)="modalNewCsr.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Common name</label>
          <input [(ngModel)]="csr.commonName" type="text" class="form-control form-control-sm" readonly />
        </div>
        <div class="form-group">
          <label>Country name</label>
          <input [(ngModel)]="csr.countryName" type="text" class="form-control form-control-sm" />
        </div>
        <div class="form-group">
          <label>State/Province name</label>
          <input [(ngModel)]="csr.stateOrProvinceName" type="text" class="form-control form-control-sm" />
        </div>
        <div class="form-group">
          <label>Locality name</label>
          <input [(ngModel)]="csr.localityName" type="text" class="form-control form-control-sm" />
        </div>
        <div class="form-group">
          <label>Organization name</label>
          <input [(ngModel)]="csr.organizationName" type="text" class="form-control form-control-sm" />
        </div>
        <div class="form-group">
          <label>Organization unit</label>
          <input [(ngModel)]="csr.organizationalUnit" type="text" class="form-control form-control-sm" />
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button (click)="modalNewCsr.hide()" class="btn btn-danger btn-sm pull-right mr-2">
      {{ 'Annuler' | translate }}
    </button>
    <button (click)="createCsr()" class="btn btn-primary btn-sm pull-right mr-2">
      {{
        'Valider et télécharger le
	CSR' | translate
      }}
    </button>
  </div>
</app-modal-basic>

<!-- AJOUT CERTIFICAT -->
<app-modal-basic #modalAddCchain [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small>{{ 'ChargerCert' | translate }}</small>
    </h4>
    <button type="button" class="close basic-close" (click)="modalAddCchain.hide(); clearInputCerts()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          {{ 'SelectCert' | translate }}
          <br />
          <button class="btn btn-block btn-primary btn-sm" (click)="clearCert.click()">
            {{ 'Import' | translate }}
            <input
              #clearCert
              id="hidden-cert"
              type="file"
              (change)="handleCert($event.target.files)"
              hidden
              class="form-control form-control-sm hide-input-file"
              multiple="false"
            />
          </button>
          <p *ngIf="crt.certName">
            <small class="text-primary">{{ crt.certName }}</small>
          </p>
        </div>
        <div class="form-group mt-3">
          {{ 'SelectCertInt' | translate }}
          <br />
          <button class="btn btn-block btn-primary btn-sm" (click)="clearChain.click()">
            {{ 'Import' | translate }}
            <input
              #clearChain
              id="hidden-chain"
              type="file"
              (change)="handleChain($event.target.files)"
              hidden
              class="form-control form-control-sm hide-input-file"
              multiple="false"
            />
          </button>
          <p *ngIf="crt.chainName">
            <small class="text-primary">{{ crt.chainName }}</small>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button (click)="confirmCreateCertCertChain()" [disabled]="!crt.certName" class="btn btn-primary btn-sm mr-2">
      {{ 'Enregistrer' | translate }}
    </button>
    <button (click)="modalAddCchain.hide(); clearInputCerts()" class="btn btn-danger btn-sm">
      {{ 'Fermer' | translate }}
    </button>
  </div>
</app-modal-basic>

<app-modal-basic #pkcs12 [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small [innerHTML]="'ImportCertificatInPKCS12' | translate"></small>
    </h4>
    <button type="button" class="close basic-close" (click)="pkcs12.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group bg-orange p-2">
          {{ 'DoNotForgetConcatIntermediates' | translate }}
        </div>
        <form>
          <div class="form-group">
            <label>{{ 'PKCS12File' | translate }}</label>
            <div class="">
              <button (click)="loadPkcs12.click()" class="btn btn-block btn-primary btn-sm">
                {{ 'ImportPKCS12' | translate }}
                <input
                  #loadPkcs12
                  id="loadPkcs12"
                  type="file"
                  name="loadPkcs12"
                  accept=".p12,.pfx"
                  hidden
                  (change)="handlePkcs12($event.target.files)"
                  multiple="false"
                />
              </button>
              <p *ngIf="p12File">
                <small class="text-primary">{{ p12File.name }}</small>
              </p>
            </div>
          </div>
          <div class="form-group">
            <label>{{ 'MotDePasse' | translate }}</label>
            <input
              type="password"
              [(ngModel)]="hote.p12Password"
              (keydown.enter)="handleKeyEnterP12($event)"
              name="p12Password"
              autocomplete="p12-password"
              class="form-control form-control-sm"
            />
          </div>
          <div class="form-group" style="display: flex; align-items: baseline">
            <input type="checkbox" [(ngModel)]="makeCertificateActive" name="makeCertificateActive" />
            <label class="form-check-label" for="makeCertificateActive" style="margin-left: 5px">{{
              'MakeCertificateActive' | translate
            }}</label>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button
      (click)="saveP12Certif()"
      class="btn btn-primary btn-sm"
      [disabled]="!p12File || !hote.p12Password"
      [innerHTML]="'Enregistrer' | translate"
    ></button>
    &nbsp;
    <button (click)="pkcs12.hide()" class="btn btn-danger btn-sm" [innerHTML]="'Fermer' | translate"></button>
    &nbsp;
  </div>
</app-modal-basic>
