import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ActionEnum,
  DateHistogramLogRequest,
  Filter,
  FiltersEnum,
  formatFiltersLogs,
  LogRequest,
  LogsService,
  Rps,
  SiteTrafficBreakdown,
  UrlBandwidth,
  UrlResponseCodesCount,
  UrlStats,
} from 'app/services/logs.service';
import { ContextEnum, SitesService } from 'app/services/sites.service';
import { DATE_LAST_90_DAYS, DATE_NOW } from 'app/shared/date-range-selector/calendar-data.service';
import { Bar } from 'app/shared/highcharts/bar-horizontal/bar';
import {
  BANDWIDTH,
  computeInterval,
  Graph,
  GraphType,
  RepartitionType,
  RESPONSE_TIME,
  RESPONSE_TIME_SUM,
  RESPONSES_CODES,
  TRAFIC,
  TRAFIC_SORT_BY_BLOCKED,
} from 'app/shared/highcharts/graph/graph';
import { ClickEvent } from 'app/shared/highcharts/graph/graph.component';
import { DateRange, durationMs } from 'app/shared/utils/date-range';
import { TrackEvent } from 'app/theme/dashboard/track.event';
import Highcharts from 'highcharts';
import _ from 'lodash';
import moment from 'moment';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-my-logs-graph',
  templateUrl: './my-logs-graphs.component.html',
  styleUrls: ['./my-logs-graphs.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class MyLogsGraphsComponent implements OnInit {
  @Input() filters: Filter[];
  @Input() ctx: ContextEnum;
  @Input() isLoading: boolean = false;

  @Output() track = new EventEmitter<TrackEvent>();
  @Output() getLogs = new EventEmitter<void>();
  @Output() onPeriodEmitted = new EventEmitter<DateRange>();

  @ViewChild('graphOverTimePanel') graphOverTimePanel: ElementRef;
  @ViewChild('graphRepartitionsPanel') graphRepartitionsPanel: ElementRef;
  @ViewChild('graphRepartitionsContainer') graphRepartitionsContainer: ElementRef;
  @ViewChild('graphRepartitions') graphRepartitions: ElementRef;

  breakpointSubscription: Subscription;

  graphType: GraphType = TRAFIC;
  repartitionsType: RepartitionType = TRAFIC;

  lang: string;
  Math = Math;

  TRAFIC: GraphType = TRAFIC;
  RESPONSE_TIME: GraphType = RESPONSE_TIME;
  RESPONSE_TIME_SUM: RepartitionType = RESPONSE_TIME_SUM;
  RESPONSES_CODES: GraphType = RESPONSES_CODES;
  BANDWIDTH: GraphType = BANDWIDTH;
  TRAFIC_SORT_BY_BLOCKED: RepartitionType = TRAFIC_SORT_BY_BLOCKED;

  graphOverTimeData: Partial<{ [property in keyof GraphType]: Highcharts.Chart }> = {};
  graphRepartitionsData: Partial<{ [property in keyof RepartitionType]: Highcharts.Chart }> = {};

  chartInterval = null;

  isRepartitionsPanelOpened = true;

  graphsRendered = {
    overTime: false,
    breakdown: false,
  };

  constructor(
    private auth: AuthService,
    private logsService: LogsService,
    public sites: SitesService,
    public translate: TranslateService,
    private router: Router,
    private graph: Graph,
    private bar: Bar,
    private renderer: Renderer2,
  ) {
    this.graphType = this.getRouterParam('graphType') || TRAFIC;
    this.repartitionsType = this.getRouterParam('graphSubType') || this.getRouterParam('graphType') || TRAFIC;
  }

  ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();
  }

  ngOnDestroy() {
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
    }
  }

  resetData() {
    this.graphOverTimeData = {};
    this.graphRepartitionsData = {};
  }

  onPeriodEmittedFromZoneSelected(p: DateRange) {
    this.onPeriodEmitted.emit(p);
  }

  toggleRepartitionsPanel() {
    this.graphRepartitionsPanel.nativeElement.classList.contains('collapsed')
      ? this.openRepartitionsPanel()
      : this.closeRepartitionsPanel();
  }

  openRepartitionsPanel() {
    this.isRepartitionsPanelOpened = true;
    if (!this.graphRepartitionsData[this.repartitionsType]?.series?.length) {
      this.loadGraphRepartitions();
    }
    this.graphRepartitionsPanel.nativeElement.classList.remove('collapsed');
    this.graphOverTimePanel.nativeElement.classList.remove('expanded');
    this.renderer.setStyle(this.graphRepartitions.nativeElement, 'min-width', 'unset');
  }

  closeRepartitionsPanel() {
    this.isRepartitionsPanelOpened = false;
    const minWidth = this.graphRepartitions.nativeElement.offsetWidth;
    this.renderer.setStyle(this.graphRepartitions.nativeElement, 'min-width', `${minWidth}px`); // chart doesn't collapse during opening with this hack
    this.graphOverTimePanel.nativeElement.classList.add('expanded');
    this.graphRepartitionsPanel.nativeElement.classList.add('collapsed');
  }

  async setGraphType(graphType: GraphType) {
    this.graphType = graphType;
    this.repartitionsType = graphType;
    return this.loadGraphs();
  }

  async loadGraphs() {
    return Promise.all([this.loadGraphOverTime(), this.loadGraphRepartitions()]);
  }

  async loadGraphOverTime() {
    const type = this.graphType;

    if (this.graphOverTimeData[type]?.series?.length) {
      // already have data for this selection, no need to reload
      return;
    }

    this.graphsRendered.overTime = false;
    try {
      let res: any = await firstValueFrom(
        this.logsService.getDateHistograms(type, this.getDateHistogramLogRequest(), this.ctx),
      );
      this.graphOverTimeData = { ...this.graphOverTimeData, [type]: this.graph.formatGraphData(type, res, false) };
    } catch (error) {
      console.error('Error during rendering graph', error);
    } finally {
      this.graphsRendered.overTime = true;
    }
  }

  async setRepartitionType(repartitionType: RepartitionType) {
    this.repartitionsType = repartitionType;
    return this.loadGraphRepartitions();
  }

  async loadGraphRepartitions() {
    if (!this.isRepartitionsPanelOpened) {
      return;
    }

    const type = this.repartitionsType;

    if (this.graphRepartitionsData[type]?.series?.length) {
      // already have data for this selection, no need to reload
      return;
    }

    this.graphsRendered.breakdown = false;
    try {
      this.graphRepartitionsData[type] = await graphBuilders[type](this.logsService, this.bar).buildGraph(
        this.getLogRequest(),
        this.ctx,
      );
    } catch (error) {
      console.error('Error during rendering graph', error);
    } finally {
      this.graphsRendered.breakdown = true;
    }
  }

  repartitionsCategoryClicked(url: string) {
    const decomposedUrl = url.split('/');
    this.sites.setSite(this.ctx, decomposedUrl.at(0));
    this.track.emit({ [FiltersEnum.PATH]: this.parsePath(decomposedUrl) });
  }

  repartitionsBarClicked(event) {
    const value = event?.point?.series?.userOptions?.custom?.slug;

    switch (this.graphType) {
      case TRAFIC: {
        this.sites.setSite(this.ctx, event?.point?.category);
        return this.track.emit({ [FiltersEnum.ACTION]: value });
      }

      case RESPONSE_TIME: {
        const decomposedUrl = event?.point?.category.split('/');
        this.sites.setSite(this.ctx, decomposedUrl.at(0));
        return this.track.emit({ [FiltersEnum.PATH]: this.parsePath(decomposedUrl) });
      }

      case RESPONSES_CODES: {
        const decomposedUrl = event?.point?.category.split('/');
        this.sites.setSite(this.ctx, decomposedUrl.at(0));
        return this.track.emit({ [FiltersEnum.PATH]: this.parsePath(decomposedUrl), [FiltersEnum.STATUS_CODE]: value });
      }

      case BANDWIDTH: {
        const decomposedUrl = event?.point?.category.split('/');
        this.sites.setSite(this.ctx, decomposedUrl.at(0));
        return this.track.emit({
          [FiltersEnum.PATH]: this.parsePath(decomposedUrl),
          [FiltersEnum.CACHE_STATUS]: value,
        });
      }
    }
  }

  parsePath(decomposedUrl) {
    return decomposedUrl.length > 1 ? '/' + decomposedUrl.slice(1).join('/') : null;
  }

  zoomOut() {
    const period = this.sites[this.ctx].current.period;
    const interval: number = durationMs(period);

    let start: moment.Moment = moment.max([
      moment(period.start).subtract(Math.round(interval / 2)),
      DATE_LAST_90_DAYS(),
    ]);
    const end: moment.Moment = moment.min([moment(start).add(interval * 2), DATE_NOW()]);
    start = moment.max([moment(end).subtract(interval * 2), DATE_LAST_90_DAYS()]);

    this.onPeriodEmitted.emit({ start, end });
  }

  strafe(direction: StrafeDirection) {
    const now = DATE_NOW();
    const period = this.sites[this.ctx].current.period;
    const interval = durationMs(period);
    const shift = Math.round(interval / 2);

    let start,
      end: moment.Moment = null;
    if (direction == 'right') {
      end = moment.min(now, moment(period.end).add(shift));
      start = moment(end).subtract(interval);
    } else if (direction == 'left') {
      start = moment.max(DATE_LAST_90_DAYS(), moment(period.start).subtract(shift));
      end = moment(start).add(interval);
    }

    this.onPeriodEmitted.emit({ start, end });
  }

  canGoForward(end) {
    return moment(end).isBefore(moment());
  }

  disableGoForward(end) {
    return moment(end).isSame(moment(), 'minute') || !this.canGoBack(end);
  }

  canGoBack(start) {
    return moment(start).isAfter(DATE_LAST_90_DAYS());
  }

  disableGoBack(start) {
    return moment(start).isSame(DATE_LAST_90_DAYS(), 'minute') || !this.canGoForward(start);
  }

  onColumnClick(event: ClickEvent) {
    switch (event.category) {
      case RESPONSE_TIME:
        return this.track.emit({ [FiltersEnum.RESPONSE_TIME_GTE]: event.slug });
      case TRAFIC:
        return this.track.emit({ [FiltersEnum.ACTION]: event.slug });
      case RESPONSES_CODES:
        return this.track.emit({ [FiltersEnum.STATUS_CODE]: event.slug });
      case BANDWIDTH:
        return this.track.emit({ [FiltersEnum.CONTENT_LENGTH_GTE]: event.slug });
    }
  }

  getDateHistogramLogRequest(): DateHistogramLogRequest {
    this.chartInterval = computeInterval(this.sites[this.ctx].current.period);
    return {
      ...this.getLogRequest(),
      aggregationTimeBound: `${this.chartInterval}s`,
    };
  }

  getLogRequest(): LogRequest {
    return { ...this.sites[this.ctx].buildRequestData(), ...formatFiltersLogs(this.filters) };
  }

  getRouterParam(param) {
    return this.router?.getCurrentNavigation()?.extras?.state?.[param];
  }

  noSitesFallback() {
    // if no sites, give to highcharts fake chart with empty data
    Object.keys(this.graphsRendered).forEach((key) => (this.graphsRendered[key] = true));
    [TRAFIC, TRAFIC_SORT_BY_BLOCKED, RESPONSE_TIME, RESPONSES_CODES, BANDWIDTH].forEach((t) => {
      this.graphOverTimeData[t] = { chart: {}, lang: {}, title: { text: null }, credits: { enabled: false } };
      this.graphRepartitionsData[t] = {
        chart: {},
        lang: {},
        title: { text: null },
        credits: { enabled: false },
        plotOptions: { series: { cursor: null, events: {} } },
      };
    });
  }
}

type StrafeDirection = 'left' | 'right';

abstract class BreakdownGraphBuilder<T> {
  constructor(
    protected readonly logs: LogsService,
    protected readonly bars: Bar,
  ) {}

  protected abstract getData(request: LogRequest, context: ContextEnum): Observable<T[]>;

  protected abstract formatData(data: T[]): Highcharts.Options;

  public async buildGraph(request: LogRequest, context: ContextEnum): Promise<Highcharts.Options> {
    const data = await firstValueFrom(this.getData(request, context));
    return this.formatData(data);
  }
}

class TrafficBreakdown extends BreakdownGraphBuilder<SiteTrafficBreakdown & Rps> {
  protected formatData(data: (SiteTrafficBreakdown & Rps)[]): Highcharts.Options {
    return this.bars.formatDataTrafic(data, 'total');
  }

  protected getData(request: LogRequest, context: ContextEnum): Observable<(SiteTrafficBreakdown & Rps)[]> {
    return this.logs.getTrafficBreakdown('traffic/top-sites/by-total', request, context);
  }
}

class TrafficByBlockedBreakdown extends BreakdownGraphBuilder<SiteTrafficBreakdown & Rps> {
  protected formatData(data: (SiteTrafficBreakdown & Rps)[]): Highcharts.Options {
    return this.bars.formatDataTrafic(data, ActionEnum.BLOCKED);
  }

  protected getData(request: LogRequest, context: ContextEnum): Observable<(SiteTrafficBreakdown & Rps)[]> {
    return this.logs.getTrafficBreakdown('traffic/top-sites/by-blocked', request, context);
  }
}

class ResponseCodeBreakdown extends BreakdownGraphBuilder<UrlResponseCodesCount> {
  protected formatData(data: UrlResponseCodesCount[]): Highcharts.Options {
    return this.bars.formatDataResponsesCodes(data);
  }

  protected getData(request: LogRequest, context: ContextEnum): Observable<UrlResponseCodesCount[]> {
    return this.logs
      .getUrlBreakdown<UrlResponseCodesCount>('top-urls-by-http-error', request, context)
      .pipe(map((items) => _.orderBy(items, [(item) => item.count4xx + item.count5xx], ['desc'])));
  }
}

class ResponseTimeBreakdown extends BreakdownGraphBuilder<UrlStats> {
  protected formatData(data: UrlStats[]): Highcharts.Options {
    return this.bars.formatDataResponseTime(data);
  }

  protected getData(request: LogRequest, context: ContextEnum): Observable<UrlStats[]> {
    return this.logs.getUrlBreakdown('slow-urls', request, context);
  }
}

class ResponseTimeSumBreakdown extends BreakdownGraphBuilder<UrlStats> {
  protected formatData(data: UrlStats[]): Highcharts.Options {
    return this.bars.formatDataResponseTimeSum(data);
  }

  protected getData(request: LogRequest, context: ContextEnum): Observable<UrlStats[]> {
    return this.logs.getUrlBreakdown('cpu-consuming-urls', request, context);
  }
}

class BandwidthBreakdown extends BreakdownGraphBuilder<UrlBandwidth> {
  protected formatData(data: UrlBandwidth[]): Highcharts.Options {
    return this.bars.formatDataBandwidth(data);
  }

  protected getData(request: LogRequest, context: ContextEnum): Observable<UrlBandwidth[]> {
    return this.logs.getUrlBreakdown('top-urls-by-bandwidth', request, context);
  }
}

type GraphOptionsBuilder = (logs: LogsService, bars: Bar) => BreakdownGraphBuilder<any>;
const graphBuilders: { [key in RepartitionType]: GraphOptionsBuilder } = {
  [TRAFIC]: (logs: LogsService, bars: Bar) => new TrafficBreakdown(logs, bars),
  [TRAFIC_SORT_BY_BLOCKED]: (logs: LogsService, bars: Bar) => new TrafficByBlockedBreakdown(logs, bars),
  [RESPONSE_TIME]: (logs: LogsService, bars: Bar) => new ResponseTimeBreakdown(logs, bars),
  [RESPONSE_TIME_SUM]: (logs: LogsService, bars: Bar) => new ResponseTimeSumBreakdown(logs, bars),
  [RESPONSES_CODES]: (logs: LogsService, bars: Bar) => new ResponseCodeBreakdown(logs, bars),
  [BANDWIDTH]: (logs: LogsService, bars: Bar) => new BandwidthBreakdown(logs, bars),
};
