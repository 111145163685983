<ng-container *ngIf="showView === pageViews.LIST">
  <div class="m-t-25">
    <div class="card">
      <button (click)="createTemplate()" class="btn btn-sm btn-primary">
        <strong [innerHtml]="'+ajouter' | translate"></strong>
      </button>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Type</th>
            <th>{{ 'Requêtes par seconde' | translate }}</th>
            <th>{{ 'Débit Mb/s' | translate }}</th>
            <th>Site</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of clusterTypes">
            <td>{{ item.name }}</td>
            <td>{{ item.requestPerSecMax }}</td>
            <td>{{ item.debitMax }}</td>
            <td>{{ item.activeSiteMax }}</td>
            <td><i (click)="editClusterType(item)" style="color: #4099ff; font-size: 20px" class="pointer icofont icofont-edit-alt"></i>&nbsp;&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showView === pageViews.CREATE || showView === pageViews.EDIT">
  <div class="col-md-6 col-xs-12 offset-md-3">
    <div class="card">
      <div class="card-header">
        <h6 class="modal-title" *ngIf="clusterType.id !== undefined">{{ 'Modifier' | translate }} {{ 'aClusterType' | translate }}</h6>
        <h6 class="modal-title" *ngIf="clusterType.id === undefined">{{ 'Ajouter' | translate }} {{ 'aClusterType' | translate }}</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label [innerHtml]="'Nom' | translate"></label>
              <input [(ngModel)]="clusterType.name" class="form-control" type="text" />
            </div>
            <div class="form-group">
              <label [innerHtml]="'Requêtes par seconde' | translate"></label>
              <input [(ngModel)]="clusterType.requestPerSecMax" class="form-control" type="text" />
            </div>
            <div class="form-group">
              <label [innerHtml]="'Débit Mb/s' | translate"></label>
              <input [(ngModel)]="clusterType.debitMax" rows="3" class="form-control" type="" text />
            </div>
            <div class="form-group">
              <label [innerHtml]="'Sites' | translate"></label>
              <input [(ngModel)]="clusterType.activeSiteMax" class="form-control" type="text" />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button *ngIf="clusterType.id === undefined" (click)="createClusterType(clusterType)" class="btn btn-mini btn-danger" [innerHtml]="'ajouter' | translate"></button>&nbsp;
        <button *ngIf="clusterType.id !== undefined" (click)="updateClusterType(clusterType)" class="btn btn-mini btn-danger" [innerHtml]="'Modifier' | translate"></button>&nbsp;
        <button (click)="clusterType = {} && (showView = pageViews.LIST)" class="btn btn-mini btn-primary" [innerHtml]="'Annuler' | translate"></button>
      </div>
    </div>
  </div>
</ng-container>
