import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Global } from '../../../global';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';

@Component({
  selector: 'app-ia-recommended-parameters',
  templateUrl: './ia-recommended-parameters.component.html',
  styleUrls: ['./ia-recommended-parameters.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class IaRecommendedParametersComponent implements OnInit {
  brainTunables = [];

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.getBrainTunable();
  }

  getBrainTunable() {
    this.http.post(Global.baseUrl + 'brainTunable/getRecommended', {}).subscribe((res: any) => {
      if (!res.hasError) {
        this.brainTunables = res.items;
        this.brainTunables.forEach((bt) => {
          bt.oldValue = bt.recommendedValue;
          bt.oldVisibility = bt.visibility;
        });
      } else {
        this.toastr.error(this.translate.instant('OperationFailed'));
      }
    });
  }

  setBrainTunable() {
    let datas = [];

    this.brainTunables.forEach((bt) => {
      if (bt.oldVisibility != bt.visibility || bt.oldValue != bt.recommendedValue) {
        datas.push(bt);
      }
    });

    this.http
      .post(Global.baseUrl + 'brainTunable/update', {
        datas,
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.toastr.success(this.translate.instant('OperationSuccess'));
          this.getBrainTunable();
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }
      });
  }
}
