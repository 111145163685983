import { TranslateService } from '@ngx-translate/core';
import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ADMIN_CONTEXT, ContextEnum, Site, SitesService } from 'app/services/sites.service';
import { Subject, debounceTime } from 'rxjs';
import { copyObject, ONE_MINUTE } from '../utils/data-utils';
import _ from 'lodash';
import { DateRange } from '../utils/date-range';

interface Options {
  showTags: boolean;
  showSites: boolean;
  showClusters: boolean;
  showDatePicker: boolean;
  canAutoRefresh: boolean;
}

@Component({
  selector: 'app-site-selector',
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
})
export class SiteSelectorComponent implements OnDestroy {
  @Input() ctx: ContextEnum;
  @Input() options: Options = {
    showTags: true,
    showSites: true,
    showClusters: true,
    showDatePicker: true,
    canAutoRefresh: false,
  };
  @Input() showSites = true;
  @Input() isLoading = false;
  @Input() datePickerSlug = ''; // have to pass a different HTML id to each date range picker between each page, otherwise it's buggy
  @Input() minDateDays = 90;
  @Input() ranges = ['15M', '1H', '4H', 'T', 'Y', '7', '30', '90'];
  @Output() refresh = new EventEmitter();
  @Output() change = new EventEmitter<Object>();
  @Output() onPeriodEmitted = new EventEmitter<DateRange>();

  refreshInterval: any = null;
  countdownProgressInterval: any = null;
  countNextRefresh = 0;

  private debounceSubject: Subject<any> = new Subject();
  private previousSitesList: Site[] = [];

  public ADMIN_CONTEXT = ADMIN_CONTEXT;

  constructor(
    public translate: TranslateService,
    public sites: SitesService,
  ) {
    this.debounceSubject.pipe(debounceTime(200)).subscribe(() => {
      this.change.emit();
    });
  }

  ngOnDestroy(): void {
    this.clearRefreshInterval();
  }

  setRefreshInterval() {
    if (this.refreshInterval) {
      return;
    }

    this.countdownProgressInterval = setInterval(() => this.countNextRefresh++, 1000);
    this.refreshInterval = setInterval(() => this.refresh.emit(), ONE_MINUTE);
  }

  clearRefreshInterval() {
    if (!this.refreshInterval) {
      return;
    }

    clearInterval(this.countdownProgressInterval);
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
    this.countNextRefresh = 0;
  }

  refreshSiteList(event) {
    this.sites.computeSiteList(this.ctx);
    this.hostChange(event);
  }

  hostChange(event) {
    // list hasn't changed
    if (this.previousSitesList.length && _.isEqual(this.previousSitesList, this.sites[this.ctx].current.selectedSites))
      return;

    this.previousSitesList = copyObject(this.sites[this.ctx].current.selectedSites);
    this.debounceSubject.next(event);
  }
}
