<div class="card with-tabs">
  <div class="col-md-12">
    <div class="row" style="padding-top: 15px">
      <div class="col-md-12 mt-2">
        <h4 class="mb-0">{{ 'SentShares' | translate }}</h4>
      </div>
    </div>
    <hr />
    <h5 class="mb-3">{{ 'SitesShares' | translate }}</h5>
    <div class="form-group flex-align-items">
      <label style="position: relative; top: 5px; margin-right: 10px">{{ 'Search' | translate }}</label>
      <input
        #search
        class="form-control"
        type="text"
        style="max-width: 400px"
        placeholder="{{ 'PlaceholderSearchShares' | translate }}"
      />
    </div>
    <div>
      <ngx-datatable
        [messages]="{ emptyMessage: translate.instant('NoData') }"
        [columnMode]="'force'"
        class="data-table table-responsive mb-3"
        [limit]="20"
        [rows]="shares"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="40"
      >
        <ngx-datatable-column [minWidth]="350" [maxWidth]="350" [resizeable]="false" [sortable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <p class="dataheader">Site</p>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span title="{{ row.site.name }}">{{ row.site.name }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [minWidth]="200" [resizeable]="false" [sortable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <p class="dataheader">{{ 'Receiver' | translate }}</p>
          </ng-template>
          <ng-template class="flex-align-items" let-row="row" ngx-datatable-cell-template>
            <span>{{ row.recipient.companyName }}</span
            >&nbsp;
            <app-badge-organization [code]="row.recipient.code"></app-badge-organization>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [minWidth]="120" [maxWidth]="120" [resizeable]="false" [sortable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <p class="dataheader">Type</p>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <label-share-role [role]="row.role" />
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [maxWidth]="85" [resizeable]="false" [sortable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <p class="dataheader">{{ 'Actions' | translate }}</p>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <i
              (click)="showModalDelete(row)"
              class="pointer icofont icofont-ui-delete ng-star-inserted refuse-icon"
              title="{{ 'Annuler' | translate }}"
            ></i>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-curPage="page" let-pageSize="size">
            <small class="ogo-ngx-datatable-footer"
              >Total : {{ total }}
              {{ total > 1 ? ('MesPartages' | translate | lowercase) + 's' : ('MesPartages' | translate | lowercase) }}
            </small>
            <datatable-pager
              [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'"
              [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'"
              [page]="page + 1"
              [size]="size"
              [count]="total"
              (change)="changePage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</div>
