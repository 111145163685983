<app-tabs>
  <app-tab [tabTitle]="'MyParameters' | translate" [active]="activeTab == 'parameters'" [queryStringNav]="'parameters'">
    <app-my-parameters></app-my-parameters>
  </app-tab>
  <app-tab
    [tabTitle]="'MyNotifications' | translate"
    [active]="activeTab == 'notifications'"
    [queryStringNav]="'notifications'"
  >
    <app-my-notifications></app-my-notifications>
  </app-tab>
</app-tabs>
