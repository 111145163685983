import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { HostModeService } from 'app/shared/hostMode/hostMode.service';

import { ToastrService } from 'app/shared/toastr/toastr.service';
import { SitesService } from 'app/services/sites.service';
import { AuthService } from '../../../services/auth.service';
import { Global } from '../../../global';
import { AdminService } from 'app/admin.service';

@Component({
  selector: 'app-cluster',
  templateUrl: './cluster.component.html',
  styleUrls: ['./cluster.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class ClusterComponent implements OnInit {
  lang: string;

  user: any = {};
  cluster: any = {};
  template: any = {};
  clusters: any = [];
  clustersTwin: any = [];
  clusterTypes: any = [];
  elasticsearchs: any = [];
  organizations: any = [];
  SHOW_VIEW = 'LIST_CLUSTER';
  @ViewChild('search') search: ElementRef;

  constructor(
    private http: HttpClient,
    private sites: SitesService,
    private translate: TranslateService,
    private auth: AuthService,
    private toastr: ToastrService,
    public hostModeService: HostModeService,
    private admin: AdminService,
  ) {}

  ngOnInit() {
    this.user = this.auth.getUser();
    this.getTypeCluster();
    this.getCluster();
    this.getElasticsearch();
  }

  async loadOrganizations(searchTerm) {
    if (searchTerm.length >= 2) {
      let res: any = null;
      try {
        res = await this.admin.getOrganizations(searchTerm);
      } catch (error) {
        return this.toastr.error(error.error);
      }

      res.content = res.content.map((o) => {
        return { companyNameAndCode: o.companyName + ' - ' + o.code, ...o };
      });
      this.organizations = res.content;
    }
  }

  editCluster(p: any) {
    this.SHOW_VIEW = 'EDIT_CLUSTER';
    this.clustersTwin = this.clusters.filter((c) => c.id != p.id);
    if (p) {
      this.cluster = _.clone(p);
      this.cluster.companyNameAndCode = p.ownerCompanyName + '-' + p.ownerCode;
      this.organizations = [{ companyNameAndCode: p.ownerCompanyName + '-' + p.ownerCode, id: p.ownerId }];
    } else {
      this.cluster = {};
    }
  }

  getCluster() {
    this.http
      .post(Global.baseUrl + 'cluster/get', {
        data: {},
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.clusters = res.items;
        }
      });
  }

  getElasticsearch() {
    this.http
      .post(Global.baseUrl + 'elasticsearch/get', {
        data: {},
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.elasticsearchs = res.items;
        }
      });
  }

  getTypeCluster() {
    this.http
      .post(Global.baseUrl + 'clusterType/get', {
        data: {},
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.clusterTypes = res.items;
        }
      });
  }

  onCreateCluster(p: any) {
    if (p.nom === undefined || p.nom.trim() === '') {
      this.toastr.error('Saisir le libellé');
      return;
    }
    if (p.ip === undefined || p.ip.trim() === '') {
      this.toastr.error("Saisir l'adresse IP");
      return;
    }
    if (p.endpoint === undefined || p.endpoint.trim() === '') {
      this.toastr.error("Saisir l'endpoint");
      return;
    }
    if (p.defaultSiteMode === undefined || p.defaultSiteMode.trim() === '') {
      this.toastr.error('Saisir le mode');
      return;
    }
    this.http
      .post(Global.baseUrl + 'cluster/create', {
        datas: [
          {
            nom: p.nom,
            ip: p.ip,
            ipv6: p.ipv6,
            clusterId: p.clusterId,
            ownerId: p.ownerId,
            endpoint: p.endpoint,
            clusterTwinId: p.clusterTwinId,
            elasticsearchId: p.elasticsearchId,
            clusterTypeId: p.clusterTypeId,
            isPublic: p.isPublic,
            defaultSiteMode: p.defaultSiteMode,
            supportsIpv6Origins: p.supportsIpv6Origins,
            supportsCache: p.supportsCache,
            trustedIps: p.trustedIps.map((ip) => (ip?.label ? ip.label : ip)),
          },
        ],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.getCluster();
          this.sites.purgeContext();
          this.SHOW_VIEW = 'LIST_CLUSTER';
          this.toastr.success(this.translate.instant('EnregistrementReussi'));
          this.cluster = {};
        } else if (res.status && res.status.message) {
          this.toastr.error(res.status.message);
        }
      });
  }

  onUpdateCluster(p: any) {
    this.http
      .post(Global.baseUrl + 'cluster/update', {
        datas: [
          {
            id: p.id,
            nom: p.nom,
            ip: p.ip,
            ipv6: p.ipv6,
            clusterId: p.clusterId,
            ownerId: p.ownerId,
            endpoint: p.endpoint,
            clusterTwinId: p.clusterTwinId,
            elasticsearchId: p.elasticsearchId,
            clusterTypeId: p.clusterTypeId,
            isPublic: p.isPublic,
            managed: p.managed,
            defaultSiteMode: p.defaultSiteMode,
            supportsIpv6Origins: p.supportsIpv6Origins,
            supportsCache: p.supportsCache,
            trustedIps: p.trustedIps.map((ip) => (ip?.label ? ip.label : ip)),
          },
        ],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.getCluster();
          this.SHOW_VIEW = 'LIST_CLUSTER';
          this.toastr.success(this.translate.instant('MiseAJourReussie'));
          this.cluster = {};
        } else {
          this.toastr.error(res.status.message);
        }
      });
  }
}
