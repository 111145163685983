import { Component, OnChanges, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface Segment {
  key: string;
  value: any;
  type: undefined | string;
  parent: null | string;
  description: string;
  expanded: boolean;
}

@Component({
  selector: 'json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss'],
})
export class JsonViewer implements OnChanges {
  @Input() json: any;
  @Input() expanded = true;
  @Input() parentKey = null;
  @Input() depth = -1;
  @Input() highlightedFields = [];
  @Input() _currentDepth = -1;

  segments: Segment[] = [];

  constructor(private translate: TranslateService) {}

  ngOnChanges() {
    this.segments = [];

    this.json = this.decycle(this.json);

    this._currentDepth++;

    if (typeof this.json === 'object') {
      Object.keys(this.json).forEach((key) => {
        this.segments.push(this.parseKeyValue(key, this.json[key], this.parentKey));
      });
    } else {
      this.segments.push(this.parseKeyValue(`(${typeof this.json})`, this.json, this.parentKey));
    }
  }

  isHighlighted(key) {
    if (this.highlightedFields && this.highlightedFields.length) {
      return this.highlightedFields.find((field) => field == key);
    }
  }

  isExpandable(segment: Segment) {
    return segment.type === 'object' || segment.type === 'array';
  }

  toggle(segment: Segment) {
    if (this.isExpandable(segment)) {
      segment.expanded = !segment.expanded;
    }
  }

  replaceOgoDeleted(data) {
    return data.replaceAll('_OGO_DELETED_', `[${this.translate.instant('Masked')}]`);
  }

  private parseKeyValue(key: any, value: any, parentKey: any): Segment {
    const segment: Segment = {
      key: key,
      value: value,
      type: undefined,
      parent: null,
      description: '' + value,
      expanded: this.isExpanded(),
    };

    switch (typeof segment.value) {
      case 'number': {
        segment.type = 'number';
        break;
      }
      case 'boolean': {
        segment.type = 'boolean';
        break;
      }
      case 'function': {
        segment.type = 'function';
        break;
      }
      case 'string': {
        segment.type = 'string';
        segment.description = '"' + this.replaceOgoDeleted(segment.value) + '"';
        break;
      }
      case 'undefined': {
        segment.type = 'undefined';
        segment.description = 'undefined';
        break;
      }
      case 'object': {
        if (segment.value === null) {
          segment.type = 'null';
          segment.description = 'null';
        } else if (Array.isArray(segment.value)) {
          segment.type = 'array';
          segment.description = 'Array[' + segment.value.length + '] ' + JSON.stringify(segment.value);
        } else if (segment.value instanceof Date) {
          segment.type = 'date';
        } else {
          segment.type = 'object';
          segment.description = 'Object ' + JSON.stringify(segment.value);
        }
        break;
      }
    }

    if (parentKey) {
      segment.parent = parentKey;
    }

    return segment;
  }

  private isExpanded(): boolean {
    return this.expanded && !(this.depth > -1 && this._currentDepth >= this.depth);
  }

  private decycle(object: any) {
    const objects = new WeakMap();
    return (function derez(value, path) {
      let old_path;
      let nu: any;

      if (
        typeof value === 'object' &&
        value !== null &&
        !(value instanceof Boolean) &&
        !(value instanceof Date) &&
        !(value instanceof Number) &&
        !(value instanceof RegExp) &&
        !(value instanceof String)
      ) {
        old_path = objects.get(value);
        if (old_path !== undefined) {
          return { $ref: old_path };
        }
        objects.set(value, path);

        if (Array.isArray(value)) {
          nu = [];
          value.forEach(function (element, i) {
            nu[i] = derez(element, path + '[' + i + ']');
          });
        } else {
          nu = {};
          Object.keys(value).forEach(function (name) {
            nu[name] = derez(value[name], path + '[' + JSON.stringify(name) + ']');
          });
        }
        return nu;
      }
      return value;
    })(object, '$');
  }
}
