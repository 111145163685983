import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FiltersEnum, UrlStats } from 'app/services/logs.service';
import { RESPONSE_TIME, RESPONSE_TIME_SUM } from 'app/shared/highcharts/graph/graph';
import { TrackEvent } from 'app/theme/dashboard/track.event';

@Component({
  selector: 'app-most-frequent-requests-table',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './most-frequent-requests-table.component.html',
  styleUrl: './most-frequent-requests-table.component.scss',
})
export class MostFrequentRequestsTableComponent {
  @Input() cpuConsumingRequests: UrlStats[];
  @Input() print: boolean = false;

  @Output() clickPath = new EventEmitter<TrackEvent>();

  constructor(public translate: TranslateService) {}

  track(row) {
    const { avg, path, site, ..._ } = row;

    return this.clickPath.emit({
      [FiltersEnum.RESPONSE_TIME_GTE]: Math.trunc(avg),
      [FiltersEnum.PATH]: path,
      graphType: RESPONSE_TIME,
      graphSubType: RESPONSE_TIME_SUM,
      site,
    });
  }
}
