import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { HostModeService } from 'app/shared/hostMode/hostMode.service';
import { ADMIN_CONTEXT, BaseLogRequest, SitesService } from '../../services/sites.service';

import { HttpClient } from '@angular/common/http';
import { Global } from '../../global';
import { ACL_SITE_ACTIONS, AuthService } from '../../services/auth.service';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, DatatableComponent } from '@siemens/ngx-datatable';
import _ from 'lodash';

import { ADMIN_CLUSTER_LOGS_ROUTE, MY_LOGS_ROUTE, MY_SITES_ROUTE, PRINT_ROUTE } from 'app/app-routing.module';
import { ActionEnum, CauseEnum, DateHistograms, FiltersEnum, LogsService } from 'app/services/logs.service';
import { DATE_LAST_30_DAYS, DATE_NOW } from 'app/shared/date-range-selector/calendar-data.service';
import { ANALYZED, BLOCKED, Donut, DonutType, DonutTypes, ROBOT, SUSPECT } from 'app/shared/highcharts/donut/donut';
import { computeInterval, Graph, TrafficHistogramType, TRAFIC } from 'app/shared/highcharts/graph/graph';
import { CountryClickEvent } from 'app/shared/highcharts/world-map/world-map.component';
import { SiteSelectorComponent } from 'app/shared/site-selector/site-selector.component';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { DateRange } from 'app/shared/utils/date-range';
import { CountryCode } from 'app/shared/utils/dto';
import { Items } from 'app/shared/utils/legacy-api';
import { firstValueFrom } from 'rxjs';
import { EPOCH_MILLI_30_DAYS, subnet } from '../../shared/utils/data-utils';
import { BaseDashboardComponent } from './baseDashboardComponent';
import { WhitelistIpEvent } from './blocked-ips-table/blocked-ips-table.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent extends BaseDashboardComponent implements AfterViewInit {
  @ViewChild('siteSelector') siteSelector!: SiteSelectorComponent;
  @ViewChild('myTable') set datatable(table: DatatableComponent) {
    if (table) {
      table.columnMode = ColumnMode.force; // make resizing table header working
    }
  }
  @ViewChild(DatatableComponent)
  ngxDatatable: DatatableComponent;
  breakdown: { [key in Exclude<TrafficHistogramType, 'traffic'>]: any };
  isLoading = true;
  isTableLoading = false;

  ActionEnum = ActionEnum;
  CauseEnum = CauseEnum;

  subscription: any;
  chartInterval: number;
  pageSelected = Table.TOP_BLOCKED_IPS;
  originSelected: CountryGraphType = 'traffic';

  ADMIN_CONTEXT = ADMIN_CONTEXT;

  get isPrintable(): boolean {
    return this.ctx != ADMIN_CONTEXT && !this.isLoading && this.sites[this.ctx]?.store.sites.length > 0;
  }

  get isMultiSitesSelected(): boolean {
    return this.sites[this.ctx]?.current.selectedSites.length != 1;
  }

  get isAdminClusterView(): boolean {
    return this.ctx == ADMIN_CONTEXT;
  }

  get isASiteSelected(): number {
    return this.sites[this.ctx].current.selectedSites.length;
  }

  get isAClusterSelected(): boolean {
    return !!this.sites[this.ctx].current.cluster;
  }

  get isNewOrga(): boolean {
    return (
      this.ctx != ADMIN_CONTEXT &&
      !this.isLoading &&
      this.auth.canProv() &&
      (this.sites[this.ctx]?.store.sitesList ?? []).length == 0
    );
  }

  constructor(
    http: HttpClient,
    logsService: LogsService,
    sites: SitesService,
    translate: TranslateService,
    private hostModeService: HostModeService,
    private router: Router,
    private route: ActivatedRoute,
    auth: AuthService,
    private toastr: ToastrService,
    graph: Graph,
    donut: Donut,
  ) {
    super(http, sites, logsService, translate, auth, graph, donut);
  }

  async ngOnInit() {
    this.route.data.subscribe((data) => {
      if (data.adminClusterView) {
        this.ctx = ADMIN_CONTEXT;
      }

      // If date range is more than 30 days, decrease to 30 days
      if (
        this.sites[<string>this.ctx].current.period.end - this.sites[<string>this.ctx].current.period.start >
        EPOCH_MILLI_30_DAYS
      ) {
        this.sites[this.ctx].current.period.start = DATE_LAST_30_DAYS();
        this.sites[this.ctx].current.period.end = DATE_NOW();
        this.sites[this.ctx].current.period.recomputeEndDateToNow = false;
        this.sites[this.ctx].current.period.autoRefresh = false;
      }
    });
    await this.sites.load(this.ctx);
    await this.refresh();
  }

  ngAfterViewInit(): void {
    // add scroll bar to ng-select inputs
    const ngSelects = document.querySelectorAll('.ng-select-container');

    ngSelects.forEach((s) => {
      s.classList.add('custom-scrollbar');
    });
  }

  async getDateHistogram(request: BaseLogRequest): Promise<DateHistograms<TrafficHistogramType, BySite>> {
    const res = await super.getDateHistogram(request);
    this.breakdown = _.mapValues(_.pick(res, DonutTypes), (v, donutType: DonutType) =>
      this.donut.formatRepartition(v.bySite, donutType),
    );
    return res;
  }

  async getOrigineAttaques(request: BaseLogRequest) {
    await super.getOrigineAttaques(request);
    if (this.sites[this.ctx]?.current?.selectedSites?.length == 1) {
      const selectedSite = this.sites[this.ctx].current.selectedSites[0];
      const res = await firstValueFrom(
        this.http.post<Items<CountryCode>>(Global.baseUrl + 'blacklistedCountry/get', {
          data: { siteId: selectedSite.id },
        }),
      );
      this.pays['blacklisted'] = res.items.map((p) => p.countryCode).map((b) => [b, 0]);
    }
  }

  async getTopBlockedIps(request: BaseLogRequest) {
    await super.getTopBlockedIps(request);

    const siteAccessRights = _.fromPairs(
      await Promise.all(
        _(this.topBlockedIps)
          .map((row) => row.site)
          .uniq()
          .map(async (site) => [site, (await this.sites.getAccessRights(site)).includes(ACL_SITE_ACTIONS.EDIT)])
          .value(),
      ),
    );
    return this.topBlockedIps.forEach((row) => (row['canWhitelistIp'] = siteAccessRights[row.site]));
  }

  async refresh() {
    this.sites[this.ctx].refreshPeriod();

    await this.loadData();
  }

  async loadData() {
    if (this.sites[this.ctx].current.period.autoRefresh) this.siteSelector.setRefreshInterval();
    else this.siteSelector.clearRefreshInterval();

    this.isLoading = true;

    try {
      if (!this.sites[this.ctx].current.cluster?.id) {
        console.warn('*** loadData called with no cluster selected');
        return;
      }

      const request = this.getRequestData();
      this.chartInterval = computeInterval(this.sites[this.ctx].current.period);

      await Promise.all([
        this.getBlockAttack(request),
        this.getDateHistogram(request),
        this.getOrigineAttaques(request),
      ]);

      // Bottom tables
      if (!this.isAdminClusterView || this.isASiteSelected) {
        this.topBlockedIps = null;
        this.cpuConsumingRequests = null;
        this.slowRequests = null;
        this.loadTableData(this.pageSelected);
      }
    } finally {
      this.isLoading = false;
    }
  }

  onCountryClick(event: CountryClickEvent) {
    const map = {
      [this.BLOCKED_COUNTRY]: { [FiltersEnum.CAUSE]: CauseEnum.GEO_BLOCKED },
      attack: { [FiltersEnum.CAUSE]: CauseEnum.ANALYZED_KO },
    };

    this.navigateToLogs({ [FiltersEnum.COUNTRY]: event.countryCode, ...map[event.graphType] });
  }

  onPeriodEmitted(p: DateRange) {
    this.sites[this.ctx].current.period.start = p.start;
    this.sites[this.ctx].current.period.end = p.end;

    this.refresh();
  }

  onPeriodEmittedFromZoneSelected(p: DateRange) {
    this.sites[this.ctx].current.period.recomputeEndDateToNow = false;
    this.sites[this.ctx].current.period.autoRefresh = false;
    this.onPeriodEmitted(p);
  }

  changeOrigine(type: CountryGraphType) {
    if (type == this.BLOCKED_COUNTRY) {
      if (!this.hostModeService.isAdvanced(this.sites[this.ctx].current.selectedSites.at(0).mode)) {
        this.hostModeService.promptSuscribeToMode('SuscribeToAdvanced');
        return;
      }
    }

    this.originSelected = type;
  }

  async selectTable(table: Table) {
    this.pageSelected = table;
    if (this.isAClusterSelected) {
      if (_.isNil(this[table])) {
        await this.loadTableData(table);
      }
    }
  }

  async loadTableData(t: Table) {
    this.isTableLoading = true;

    let request = this.getRequestData();
    switch (t) {
      case Table.TOP_BLOCKED_IPS:
        await this.getTopBlockedIps(request);
        break;
      case Table.CPU_CONSUMING_REQUESTS:
        await this.getTopConsumingRequest(request);
        break;
      case Table.SLOW_REQUESTS:
        await this.getSlowRequest(request);
        break;
    }

    return (this.isTableLoading = false);
  }

  selectSite(siteName: string) {
    this.sites.setSite(this.ctx, siteName);
    this.refresh();
  }

  trackAction(action: string) {
    let state: any = { [FiltersEnum.ACTION]: action };
    if (!action) state = {};
    this.navigateToLogs(state);
  }

  navigateToLogs(state) {
    state.clusterName = this.sites[this.ctx].current.cluster.name;
    this.router.navigate([this.isAdminClusterView ? ADMIN_CLUSTER_LOGS_ROUTE : MY_LOGS_ROUTE], { state });
  }

  toPrint() {
    const current = this.sites[this.ctx].current;
    this.router.navigate([PRINT_ROUTE], {
      state: { cluster: current.cluster, siteNames: current.selectedSites.map((s) => s.name) },
      queryParams: {
        start: current.period.start.valueOf(),
        end: current.period.end.valueOf(),
        clusterId: current.cluster.clusterId,
      },
    });
  }

  addWhitelistedIp(event: WhitelistIpEvent) {
    const request = {
      datas: [
        {
          site: event.site,
          ip: subnet(event.ip),
        },
      ],
    };

    this.http.post(Global.baseUrl + 'whitelistedIp/create', request).subscribe((res: any) => {
      if (!res.hasError) {
        this.toastr.success(this.translate.instant('IPWellAddedtoWhitelist'));
      } else if (res.status.code == '904') {
        this.toastr.error(this.translate.instant('AlreadyExists'));
      } else {
        this.toastr.error(this.translate.instant('EnregistrementEchoue'));
      }
    });
  }

  private getRequestData(): BaseLogRequest {
    return this.sites[this.ctx].buildRequestData();
  }

  newSite() {
    this.router.navigate([MY_SITES_ROUTE], { state: { function: 'newSite' } });
  }

  protected readonly BLOCKED_BY_BRAIN = BLOCKED;
  protected readonly SUSPECT = SUSPECT;
  protected readonly ROBOT = ROBOT;
  protected readonly ANALYZED = ANALYZED;
  protected readonly TRAFIC = TRAFIC;
  protected readonly BLOCKED = BLOCKED;
  protected readonly BLOCKED_COUNTRY = 'blockedCountry';
  protected readonly Table = Table;
}

export type CountryGraphType = 'traffic' | 'attack' | 'robot' | 'blockedCountry';

export type CountryData = [string, number];

export type BySite = { bySite: { [prop: string]: number } };

enum Table {
  TOP_BLOCKED_IPS = 'topBlockedIps',
  CPU_CONSUMING_REQUESTS = 'cpuConsumingRequests',
  SLOW_REQUESTS = 'slowRequests',
}
