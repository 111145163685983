import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
})
export class MyProfileComponent implements OnInit {
  activeTab = undefined;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length) {
        this.activeTab = params['tab'];
      } else {
        this.activeTab = 'parameters';
      }
    });
  }
}
