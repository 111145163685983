<div class="app-modal-header">
  <span [innerHTML]="hote.id === undefined ? ('NouveauSite' | translate) : ('ConfigurationSite' | translate)"></span>
  <span *ngIf="hote.nom">&nbsp;/&nbsp;</span><span *ngIf="hote.nom" class="text-primary">{{ hote.nom }}</span>
  <hr />
</div>

<app-tabs [hide]="!hote.id">
  <app-tab [tabTitle]="'General' | translate" [active]="activeTab == 'general'">
    <div class="app-modal-body">
      <div class="card with-tabs p-4">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label [innerHTML]="'NDD' | translate"></label>&nbsp;
              <label class="badge badge-sm bg-dark" placement="right" ngbTooltip="{{ 'VeuiEntrAdrSit' | translate }}"
                >?</label
              >
              <div class="flex-align-items">
                <input
                  id="textInput"
                  (paste)="onPasteDomain($event)"
                  placeholder="{{ 'RentrezURLSite' | translate }}"
                  [disabled]="hote.id"
                  [(ngModel)]="hote.nom"
                  name="site"
                  class="form-control"
                  type="text"
                  #domainName
                />
                <button
                  (click)="checkDns(hote.nom)"
                  class="btn btn-sm btn-outline-primary mb-2 ml-2"
                  [innerHTML]="'CheckDNS' | translate"
                  [disabled]="!hote.nom?.length"
                  style="position: relative; top: 3px; white-space: nowrap"
                ></button>
              </div>
            </div>
            <div class="form-group details resolve">
              <p>
                <b>{{ 'ResolveInfos' | translate }}:</b>
              </p>
              <span
                *ngIf="!urlParam.ipv4Address && !urlParam.ipv6Address && !urlParam.cname && !urlParam.noRecord"
                class="text-placeholder resolve"
                style="margin-left: 0px"
              >
                <span class="activity"></span>
              </span>
              <p *ngIf="urlParam.noRecord">
                {{ 'NoRecord' | translate }}
              </p>
              <p *ngIf="urlParam.cname">
                <strong>CNAME :</strong>
                <span>&nbsp;{{ urlParam.cname }}</span>
              </p>
              <p *ngIf="urlParam.ipv4Address">
                <strong>IPv4 :</strong>
                <span>&nbsp;{{ urlParam.ipv4Address }}</span>
              </p>
              <p *ngIf="urlParam.ipv6Address">
                <strong>IPv6 :</strong>
                <span>&nbsp;{{ urlParam.ipv6Address }}</span>
              </p>
              <p
                *ngIf="urlParam.ipv4Address && urlParam.ipv6Address && !hote.cluster.supportsIpv6Origins"
                class="warning"
              >
                <i class="icofont icofont-warning resolve-icon warning"></i>
                {{ 'NotIPv6Cluster' | translate }}
              </p>
              <p *ngIf="!urlParam.ipv4Address && urlParam.ipv6Address" class="error">
                <i class="icofont icofont-error resolve-icon error" style="top: 3px"></i>
                {{ 'OnlyIPv6OnNotIPv6Cluster' | translate }}
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label [innerHTML]="'Cluster' | translate"></label>&nbsp;
              <label class="badge badge-sm bg-dark" placement="right" ngbTooltip="{{ 'VeuilSelClust' | translate }}"
                >?</label
              >
              <select
                *ngIf="!hote.id"
                [(ngModel)]="hote.cluster"
                (change)="clusterChange()"
                name="cluster"
                class="form-control"
              >
                <option *ngFor="let cluster of clusters" [ngValue]="cluster">
                  {{ cluster.name }}
                </option>
              </select>
              <input
                *ngIf="hote.id"
                [(ngModel)]="hote.clusterNom"
                name="cluster"
                class="form-control"
                type="text"
                disabled
              />
            </div>
            <div class="form-group">
              <div class="flex-align-items">
                <div class="form-group" style="width: 100%">
                  <label [innerHTML]="'OriginServerIP' | translate"></label>&nbsp;
                  <label class="badge badge-sm bg-dark" placement="top" [ngbTooltip]="destinIsAddSite">?</label>
                  <ng-template #destinIsAddSite>
                    <div innerHTML="{{ 'DestinIsAddSite' | translate }}"></div>
                  </ng-template>
                  <input
                    id="proxy"
                    placeholder="{{ 'RentrezIPDest' | translate }}"
                    [(ngModel)]="hote.destHost"
                    (paste)="onPasteDestination($event)"
                    name="destination"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="form-group">
                  <label [innerHTML]="'Port' | translate" style="padding-left: 10px"></label>&nbsp;
                  <label class="badge badge-sm bg-dark" placement="left" [ngbTooltip]="portinIsAddSite">?</label>
                  <ng-template #portinIsAddSite>
                    <div innerHTML="{{ 'PortinIsAddSite' | translate }}"></div>
                  </ng-template>
                  <div class="flex-align-items">
                    <div>
                      <div
                        container="body"
                        class="input-prefix"
                        style="padding: 0px 10px 12px; margin-left: 10px"
                        [ngStyle]="{
                          border: hote.port && hote.port.length ? '1px solid #7bc5ff' : '',
                          'border-right': 'none'
                        }"
                      >
                        <div style="position: relative; top: 5px">:</div>
                      </div>
                      <input
                        id="port"
                        placeholder="{{ hote.destHostScheme == 'http' ? 80 : 443 }}"
                        [(ngModel)]="hote.port"
                        name="port"
                        class="form-control"
                        type="number"
                        #port
                        style="width: 110px; padding-left: 30px; margin-left: 10px"
                        [ngStyle]="{ border: hote.port && hote.port.length ? '1px solid #7bc5ff' : '' }"
                      />
                    </div>
                    <button
                      (click)="diagnose(hote.nom)"
                      [disabled]="!hote || !hote.nom || !hote.destHost"
                      class="btn btn-sm btn-outline-primary ml-2"
                      style="white-space: nowrap"
                      [innerHTML]="'DiagnoseOrigin' | translate"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div *ngIf="diagnoseResult" class="bg-blue">
              <div *ngIf="diagnoseResult.IssueDetected" class="flex-align-items mt-1" style="color: #d28c34">
                <i class="icofont icofont-warning icon-diag mr-2"></i>
                <label> {{ 'SiteMetIssues' | translate }} : </label>
              </div>
              <div *ngIf="!diagnoseResult.IssueDetected" class="flex-align-items mt-2" style="color: #0fb10f">
                <i class="icofont icofont-check icon-diag mr-2"></i>
                <label> {{ 'DiagnosticOk' | translate }} : </label>
              </div>
              <div *ngFor="let backend of diagnoseResult.Backends | keyvalue" style="text-align: left">
                <div>
                  {{ 'DNSResolve' | translate }} :
                  <i
                    *ngIf="backend.value.ResolvStatus == 'OK'"
                    class="icofont icofont-verification-check"
                    style="color: #0fb10f"
                  ></i
                  ><i
                    *ngIf="backend.value.ResolvStatus == 'KO'"
                    class="icofont icofont-warning"
                    style="color: #d28c34"
                  ></i>
                </div>
                <div class="mt-1" *ngFor="let ip of backend.value.IPs | keyvalue">
                  <b>{{ ip.key }}</b>
                  <div class="mt-1 ml-2">
                    <div>
                      HTTP :
                      <i
                        *ngIf="ip.value.HttpDiag.Status == 'OK'"
                        class="icofont icofont-verification-check"
                        style="color: #0fb10f"
                      ></i
                      ><i
                        *ngIf="ip.value.HttpDiag.Status == 'KO'"
                        class="icofont icofont-warning"
                        style="color: #d28c34"
                      ></i>
                      <div *ngIf="ip.value.HttpDiag.Issues && ip.value.HttpDiag.Issues.length" class="mb-2">
                        <p>{{ 'DetectedIssues' | translate }} :</p>
                        <div class="ml-2" *ngFor="let issue of ip.value.HttpDiag.Issues">
                          <i>{{ issue }}</i>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="diagnoseHttps">
                      HTTPS :
                      <i
                        *ngIf="ip.value.HttpsDiag.Status == 'OK'"
                        class="icofont icofont-verification-check"
                        style="color: #0fb10f"
                      ></i
                      ><i
                        *ngIf="ip.value.HttpsDiag.Status == 'KO'"
                        class="icofont icofont-warning"
                        style="color: #d28c34"
                      ></i>
                      <div *ngIf="ip.value.HttpsDiag.Issues && ip.value.HttpsDiag.Issues.length" class="mb-2">
                        <p>{{ 'DetectedIssues' | translate }} :</p>
                        <div class="ml-2" *ngFor="let issue of ip.value.HttpsDiag.Issues">
                          <i>{{ issue }}</i>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="diagnoseHttps">
                      HTTPS2 :
                      <i
                        *ngIf="ip.value.Http2Diag.Status == 'OK'"
                        class="icofont icofont-verification-check"
                        style="color: #0fb10f"
                      ></i
                      ><i
                        *ngIf="ip.value.Http2Diag.Status == 'KO'"
                        class="icofont icofont-warning"
                        style="color: #d28c34"
                      ></i>
                      <div *ngIf="ip.value.Http2Diag.Issues && ip.value.Http2Diag.Issues.length" class="mb-2">
                        <p>{{ 'DetectedIssues' | translate }} :</p>
                        <div class="ml-2" *ngFor="let issue of ip.value.Http2Diag.Issues">
                          <i>{{ issue }}</i>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="diagnoseHttps && !hote.trustSelfSigned">
                      TLS :
                      <i
                        *ngIf="ip.value.TLSDiag.Status == 'OK'"
                        class="icofont icofont-verification-check"
                        style="color: #0fb10f"
                      ></i
                      ><i
                        *ngIf="ip.value.TLSDiag.Status == 'KO'"
                        class="icofont icofont-warning"
                        style="color: #d28c34"
                      ></i>
                      <div *ngIf="ip.value.TLSDiag.Issues && $any(ip.value).TLSDiag.Issues.length" class="mb-2">
                        <p>{{ 'DetectedIssues' | translate }} :</p>
                        <div class="ml-2" *ngFor="let issue of $any(ip.value).TLSDiag.Issues">
                          <i>{{ issue }}</i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="ml-4">
          <div class="row" style="margin-left: -35px">
            <div class="col-xl-6 v-separator">
              <div class="form-group col-md-12 flex-align-items space-between">
                <div>
                  <label style="padding: 0 10px 0 0"
                    >{{ 'ProtocoleEntreUtilisateurEtOGO' | translate }} &nbsp;
                    <label
                      class="badge badge-sm bg-dark"
                      placement="right"
                      ngbTooltip="{{ 'ForcerEnHttpsDescr' | translate }}"
                      >?</label
                    ></label
                  >
                </div>
                <div class="switch-container" style="position: relative; top: -5px">
                  <select style="width: 174px" [(ngModel)]="hote.https" name="originehttps" class="form-control">
                    <option value="http">{{ 'Non' | translate }}</option>
                    <option selected value="https">{{ 'YesWithoutHSTS' | translate }}</option>
                    <option value="hsts">{{ 'YesSTS' | translate }}</option>
                    <option value="hstss">{{ 'YesSTSIS' | translate }}</option>
                    <option value="hstssp">{{ 'YesSTSISP' | translate }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-12 flex-align-items space-between">
                <div>
                  <label style="padding: 0 10px 0 0"
                    >{{ 'ForcerDestinationEn' | translate }}&nbsp;
                    <label class="badge badge-sm bg-dark" placement="right" ngbTooltip="{{ 'ForceHttps' | translate }}"
                      >?</label
                    ></label
                  >
                </div>
                <div class="switch-container" style="position: relative; top: -5px">
                  <select
                    style="width: 174px"
                    [(ngModel)]="hote.destHostScheme"
                    (change)="diagnoseResult = null"
                    name="originehttps"
                    class="form-control"
                  >
                    <option value="http">http</option>
                    <option value="https">https</option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-12 flex-align-items space-between">
                <div>
                  <label style="padding: 0 10px 0 0"
                    >{{ 'ModeAudit' | translate }}&nbsp;
                    <label
                      class="badge badge-sm bg-dark"
                      placement="right"
                      ngbTooltip="{{ 'ModeAuditBadge' | translate }}"
                      >?</label
                    ></label
                  >
                </div>
                <div class="switch-container">
                  <span>{{ 'Désactivé' | translate }}</span>
                  <label class="switch">
                    <input type="checkbox" [(ngModel)]="hote.dryRun" />
                    <span class="slider round"></span>
                  </label>
                  <span>{{ 'Activé' | translate }}</span>
                </div>
              </div>
              <div class="form-group col-md-12">
                <app-site-tags [tags]="tags" (add)="pushTags($event)" [selected]="selectedTags"></app-site-tags>
              </div>
            </div>
            <div class="col-xl-6" style="padding: 0">
              <div class="form-group col-md-12 flex-align-items space-between">
                <div>
                  <label style="padding: 0 10px 0 0"
                    >{{ 'SupprimerLesHeadersXForwarded' | translate }}&nbsp;
                    <label
                      class="badge badge-sm bg-dark"
                      placement="right"
                      ngbTooltip="{{ 'SupprimerHeaderXForwardDescr' | translate }}"
                      >?</label
                    ></label
                  >
                </div>
                <div class="switch-container">
                  <span>{{ 'Désactivé' | translate }}</span>
                  <label class="switch">
                    <input type="checkbox" [(ngModel)]="hote.noCopyXForwarded" />
                    <span class="slider round"></span>
                  </label>
                  <span>{{ 'Activé' | translate }}</span>
                </div>
              </div>
              <div class="form-group col-md-12 flex-align-items space-between">
                <div>
                  <label style="padding: 0 10px 0 0"
                    >{{ 'Ne jamais vérifier le certificat du serveur origine' | translate }}&nbsp;
                    <label
                      class="badge badge-sm bg-dark"
                      placement="right"
                      ngbTooltip="{{ 'JamaisVerifierCertifDescr' | translate }}"
                      >?</label
                    ></label
                  >
                </div>
                <div class="switch-container">
                  <span>{{ 'Désactivé' | translate }}</span>
                  <label class="switch">
                    <input type="checkbox" [(ngModel)]="hote.trustSelfSigned" (change)="diagnoseResult = null" />
                    <span class="slider round"></span>
                  </label>
                  <span>{{ 'Activé' | translate }}</span>
                </div>
              </div>
              <div class="form-group col-md-12 flex-align-items space-between">
                <div>
                  <label style="padding: 0 10px 0 0">{{ 'Mode Panique' | translate }}</label>
                  <label
                    class="badge badge-sm bg-dark"
                    placement="right"
                    ngbTooltip="{{ 'BypassOgoBrain' | translate }}"
                    >?</label
                  >
                </div>
                <div class="switch-container">
                  <span>{{ 'Désactivé' | translate }}</span>
                  <label class="switch">
                    <input type="checkbox" [(ngModel)]="hote.panicMode" />
                    <span class="slider round"></span>
                  </label>
                  <span>{{ 'Activé' | translate }}</span>
                </div>
              </div>
              <div class="form-group col-md-12" *ngIf="hote && hote.cluster && hote.cluster.isAdmin">
                <div class="form-group bg-blue" style="text-align: left">
                  <div class="admin-cluster-params">&nbsp;Admin cluster&nbsp;</div>
                  <label [innerHtml]="'Mode'"></label>&nbsp;
                  <label
                    class="badge badge-sm bg-dark"
                    style="background: #4099ff !important"
                    placement="top"
                    [ngbTooltip]="modeTooltip"
                    >?</label
                  >
                  <ng-template #modeTooltip>
                    <div innerHTML="{{ 'ModeTooltip' | translate }}"></div>
                  </ng-template>
                  <select #hostMode [ngModel]="hote.mode" class="form-control">
                    <option *ngFor="let m of hostModeService.modes" [value]="m.value">
                      {{ m.libelle | translate }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                *ngIf="
                  auth.currentOrganization.pushLog &&
                  (hote.id ? siteHasAccessRight(ACL_SITE_PUSH_LOG) : auth.userHasFeature(ACL_PUSH_LOG_ADD_SITE))
                "
                class="form-group col-md-12"
              >
                <div class="bg-blue flex-align-items space-between" style="text-align: left">
                  <div>
                    <div class="admin-cluster-params" style="position: absolute">
                      &nbsp;{{ 'OrgaOwnerOrSiteAdmin' | translate }}&nbsp;
                    </div>
                    <label class="mt-4">{{ 'LogExport' | translate }}</label
                    >&nbsp;
                    <label
                      class="badge badge-sm bg-dark"
                      style="background: #4099ff !important"
                      placement="top"
                      [ngbTooltip]="logExportTooltip"
                      >?</label
                    >
                    <ng-template #logExportTooltip>
                      <div innerHTML="{{ 'LogExportTooltip' | translate }}"></div>
                    </ng-template>
                  </div>
                  <div class="switch-container" style="position: relative; left: 12px; top: 11px">
                    <span>{{ 'Désactivé' | translate }}</span>
                    <label class="switch">
                      <input type="checkbox" [(ngModel)]="hote.logExport" />
                      <span class="slider round"></span>
                    </label>
                    <span>{{ 'Activé' | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="flex-align-items space-between" style="align-items: baseline">
          <div class="form-group bg-light mb-0 py-1 px-2" style="margin-right: 15px">
            <p>
              <span *ngIf="!hote.id || hote.statutHoteCouleur == '#f39c12'">
                <small>{{ 'BasculeDns' | translate }}</small>
                <small *ngIf="!!hote.cluster"
                  >&nbsp;<strong>{{ hote.cluster.ip }}.</strong></small
                >
                <small *ngIf="hote.cluster && !hote.cluster.ipv6">.</small>
                <small *ngIf="hote.cluster && !!hote.cluster.ipv6"
                  >&nbsp;{{ 'or' | translate }}&nbsp;<strong>{{ hote.cluster.ipv6 }}.</strong>&nbsp;{{
                    'toActivateIpv6' | translate
                  }}.</small
                >
                <br />
              </span>
              <small>
                {{ 'IPsToWhitelist' | translate }} :
                <strong *ngIf="hote?.cluster?.ipsToWhitelist?.length"
                  >{{ hote.cluster.ipsToWhitelist.join(', ') }}.</strong
                >
                <span *ngIf="!hote?.cluster?.ipsToWhitelist?.length" class="text-placeholder resolve">
                  <span class="activity"></span>
                </span>
              </small>
            </p>
          </div>
          <div class="pull-right">
            <button
              name="validate-site"
              [disabled]="validate()"
              *ngIf="hote.id === undefined"
              (click)="createHost(hote)"
              class="btn btn-sm btn-primary"
              [ngClass]="{ disabled: validate() === true }"
              [innerHTML]="'valider' | translate"
            ></button
            >&nbsp;
            <button
              name="validate-site"
              [disabled]="validate()"
              *ngIf="hote.id !== undefined"
              (click)="updateHost(hote)"
              [ngClass]="{ disabled: validate() === true }"
              class="btn btn-sm btn-primary"
              [innerHTML]="'valider' | translate"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </app-tab>
  <app-tab
    [tabTitle]="'Certificat' | translate"
    [active]="activeTab == 'certificat'"
    [error]="isACertificateInError"
    [errorTooltip]="'CertificateError' | translate"
  >
    <app-certificates
      [hote]="hote"
      [user]="user"
      (isACertificateInError)="isACertificateInError = true"
    ></app-certificates>
  </app-tab>
  <app-tab [tabTitle]="'Exceptions' | translate" [active]="activeTab == 'exceptions'">
    <app-exceptions [hote]="hote"></app-exceptions>
  </app-tab>
  <app-tab [tabTitle]="'Geoblocking' | translate" [active]="activeTab == 'geoblock'">
    <app-geoblock [hote]="hote"></app-geoblock>
  </app-tab>
  <app-tab [tabTitle]="'AccessControl' | translate" [active]="activeTab == 'accessControl'">
    <app-access-control-rules [hote]="hote" [rules]="rules" (getRules)="getRules()"></app-access-control-rules>
  </app-tab>
  <app-tab [tabTitle]="'Rewrite' | translate" [active]="activeTab == 'rewrite'">
    <app-rewrite-rules [hote]="hote"></app-rewrite-rules>
  </app-tab>
  <app-tab
    *ngIf="hote.clusterSupportsCache"
    [beta]="true"
    [betaTranslation]="'BETA' | translate"
    [tabTitle]="'Cache' | translate"
    [active]="activeTab == 'cache'"
  >
    <app-cache [hote]="hote" [ruleUseCache]="ruleUseCache"></app-cache>
  </app-tab>
  <app-tab [tabTitle]="'Expert' | translate" [active]="activeTab == 'tuning'">
    <app-expert [hote]="hote"></app-expert>
  </app-tab>
</app-tabs>
