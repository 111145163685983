import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginModule } from './login/login.module';
import { ForgotModule } from './forgot/forgot.module';

export const FORGOT_SUFFIX = 'forgot';
export const LOGIN_SUFFIX = 'login';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Authentication',
      status: false,
    },
    children: [
      {
        path: 'login',
        loadChildren: () => LoginModule,
      },
      {
        path: 'forgot',
        loadChildren: () => ForgotModule,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
