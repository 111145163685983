<div class="col-12">
  <div class="card">
    <div class="card-header" style="padding-bottom: 0">
      <h4 class="modal-title">
        {{ (isUpdate() ? 'EditOrganization' : 'AddOrganization') | translate }}
        <label class="badge badge-sm bg-dark"
          ><a style="color: inherit" target="_blank" [href]="lang == 'fr' ? EXTERNAL_HELP_FRENCH_LINK + '/utilisateurs-et-organisations' : EXTERNAL_HELP_ENGLISH_LINK + '/users-and-organisations'"
            >?</a
          ></label
        >
      </h4>
      <p *ngIf="!fromAdmin" style="margin-top: 10px">{{ 'AddAccountTooltip' | translate }}</p>
      <hr />
    </div>
    <div class="card-body" style="padding-top: 0">
      <div class="row">
        <div class="col-md-6">
          <div *ngIf="fromAdmin && isUpdate()" class="form-group">
            <label [innerHtml]="'Code' | translate"></label><br />
            <app-badge-organization [code]="organization.code"></app-badge-organization>
          </div>
          <div class="form-group">
            <label class="star" [innerHtml]="'Nom' | translate"></label>
            <input [(ngModel)]="organization.companyName" type="text" class="form-control" />
          </div>
          <div *ngIf="fromAdmin" class="form-group">
            <label>{{ 'UseDelegations' | translate }}</label>
            <ng-select
              (change)="filterAvailableCluster()"
              [items]="availableClusters"
              [multiple]="true"
              [closeOnSelect]="true"
              bindLabel="nom"
              placeholder="Clusters"
              [(ngModel)]="clustersRead"
              autocomplete="off"
            ></ng-select>
          </div>
          <div *ngIf="fromAdmin" class="form-group">
            <label>{{ 'AdminDelegations' | translate }}</label>
            <ng-select
              (change)="filterAvailableCluster()"
              [items]="availableClusters"
              [multiple]="true"
              [closeOnSelect]="true"
              bindLabel="nom"
              placeholder="Clusters"
              [(ngModel)]="clustersAdmin"
              autocomplete="off"
            ></ng-select>
          </div>
          <div class="form-group">
            <label [innerHtml]="'Pays' | translate"></label>
            <select name="countries" class="form-control" [(ngModel)]="organization.countryCode">
              <option *ngFor="let p of countries" [value]="p.countryCode">
                {{ lang == 'fr' ? p.countryNameFr : p.countryNameEn }}
              </option>
            </select>
          </div>
          <div *ngIf="fromAdmin" class="form-group">
            <label class="star" [innerHtml]="'AdresseMailOwner' | translate"></label>
            <input [(ngModel)]="organization.owner.email" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label [innerHtml]="'Rue' | translate"></label>
            <input [(ngModel)]="organization.street" type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label [innerHtml]="'Ville' | translate"></label>
            <input [(ngModel)]="organization.city" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label [innerHtml]="'CodePostal' | translate"></label>
            <input [(ngModel)]="organization.zipCode" type="text" class="form-control" />
          </div>
        </div>
        <div *ngIf="isUpdate()" class="col-md-12 mt-3 mb-2">
          <h5>{{ 'utilisateurs' | translate }}</h5>
        </div>
        <div *ngIf="isUpdate()" class="col-md-12">
          <ngx-datatable
            #myTable
            class="data-table table-responsive task-list-table"
            [rows]="organization.users"
            [columnMode]="'flex'"
            [headerHeight]="50"
            [footerHeight]="50"
            [limit]="10"
            [rowHeight]="40"
          >
            <ngx-datatable-column [flexGrow]="2" name="{{ 'NomPrénom(s)' | translate }}">
              <ng-template let-column="column" ngx-datatable-header-template>
                <p class="dataheader" [innerHtml]="'NomPrénom(s)' | translate"></p>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template> {{ row.user.firstName }} {{ row.user.lastName }} </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="3" name="{{ 'Email' | translate }}">
              <ng-template let-column="column" ngx-datatable-header-template>
                <p class="dataheader" [innerHtml]="'Email' | translate"></p>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.user.email | translate }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="3" name="{{ 'role' | translate }}">
              <ng-template let-column="column" ngx-datatable-header-template>
                <p class="dataheader" [innerHtml]="'role' | translate"></p>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.role }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="3" name="{{ 'Actions' | translate }}">
              <ng-template let-column="column" ngx-datatable-header-template>
                <p class="dataheader" [innerHtml]="'Actions' | translate"></p>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <i
                  (click)="admin.connectAsUser(row.user.id, organization.id)"
                  style="color: #4099ff; font-size: 20px"
                  class="pointer icofont icofont-eye"
                  title="{{ 'ConnectAsThisUser' | translate }}"
                ></i
                >&nbsp;&nbsp;
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template let-curPage="page" let-pageSize="size">
                <small class="ogo-ngx-datatable-footer">Total : {{ total }} {{ total > 1 ? ('users' | translate) : ('user' | translate) }} </small>
                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="page + 1"
                  [size]="size"
                  [count]="total"
                  (change)="changePage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button *ngIf="isCreate()" [disabled]="!canValidate()" (click)="createOrganization(organization)" class="btn btn-sm btn-primary mr-2" [innerHtml]="'ajouter' | translate"></button>
      <button *ngIf="isUpdate()" [disabled]="!canValidate()" (click)="updateOrganization(organization)" class="btn btn-sm btn-primary mr-2" [innerHtml]="'Modifier' | translate"></button>
      <button (click)="organization = emptyOrganization(); back()" class="btn btn-sm btn-danger" [innerHtml]="'Annuler' | translate"></button>
    </div>
  </div>
</div>
