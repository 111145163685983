import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { Global } from '../../global';
import _ from 'lodash';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss', '../../../assets/icon/icofont/css/icofont.scss', '../add-site.component.scss'],
})
export class CertificatesComponent implements OnInit {
  @Input() hote: any = {};
  @Input() user: any = {};

  @Output() isACertificateInError = new EventEmitter();

  @ViewChild('clearCert') clearCert: ElementRef;
  @ViewChild('clearChain') clearChain: ElementRef;
  @ViewChild('loadPkcs12') loadPkcs12: any;
  @ViewChild('pkcs12') modalPkcs12: any;
  @ViewChild('modalNewCsr') modalNewCsr: any;
  @ViewChild('modalAddCchain') modalAddCchain: any;

  certificats: any = [];
  csr: any = {};
  crt: any = {};
  p12File: any = null;
  makeCertificateActive = true;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    if (this.hote.id) {
      this.getCertificat();
      this.csr.commonName = this.hote.nom;
    }
  }

  activateCert(p: any) {
    if (Date.now() > p.expiredAt) {
      this.toastr.error(this.translate.instant('CertificatExpire'));
      return;
    }
    this.http
      .post(Global.baseUrl + 'certificat/update', {
        datas: [
          {
            hoteId: this.hote.id,
            id: p.id,
            isActive: true,
          },
        ],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.getCertificat();
          this.toastr.success(this.translate.instant('ActivationCertificatSucces'));
        } else {
          this.toastr.error(this.translate.instant('ActivationCertificatEchouee'));
        }
      });
  }

  openCertificats() {
    this.csr = {
      commonName: this.hote.nom,
      email: this.user.email,
    };
    this.getCertificat();
  }

  getCertificat() {
    this.certificats = [];
    this.http
      .post(Global.baseUrl + 'certificat/get', {
        data: {
          hoteId: this.hote.id,
        },
      })
      .subscribe((res: any) => {
        this.certificats = _.map(res.items, (a: any) => {
          a.hasCsr = a.csr ? true : false;
          a.hasCert = a.fullChainCert ? true : false;
          a.stepNbr = 0;
          a.stepNbr = a.fullChainCert ? ++a.stepNbr : a.stepNbr;
          a.stepNbr = a.isActive ? ++a.stepNbr : a.stepNbr;
          return a;
        });
        if (this.certificats.some((c) => c.error)) {
          this.isACertificateInError.emit(true);
        }
      });
  }

  handlePkcs12(files: FileList) {
    const file = files.item(0) as File;
    this.p12File = file;
  }

  showModalAddChain(h: any) {
    this.clearInputCerts();
    this.modalAddCchain.show();
    this.crt = h;
  }

  confirmDeleteCert(p: any) {
    return Swal.fire({
      html: this.translate.instant('VoulezVousSupprimerCeCertificat'),
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
      title: this.translate.instant('Confirmation'),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        this.deleteCert(p.id);
      }
    });
  }

  deleteCert(id: number) {
    this.http
      .post(Global.baseUrl + 'certificat/delete', {
        datas: [
          {
            hoteId: this.hote.id,
            id: id,
          },
        ],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.getCertificat();
        } else {
          this.toastr.error(this.translate.instant('ImpossibleSupprimerCertificat'));
        }
      });
  }

  createCsr() {
    this.http
      .post(Global.baseUrl + 'certificat/create', {
        datas: [
          {
            hoteId: this.hote.id,
            dataCsr: {
              commonName: this.csr.commonName,
              countryName: this.csr.countryName,
              stateOrProvinceName: this.csr.stateOrProvinceName,
              localityName: this.csr.localityName,
              organizationName: this.csr.organizationName,
              organizationalUnit: this.csr.organizationalUnit,
            },
          },
        ],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.modalNewCsr.hide();
          this.csr = { commonName: this.hote.nom };
          this.downloadCertificate(res.items[0].csr, 'csr.txt');
          this.getCertificat();
        }
      });
  }

  showModalPkcs12() {
    this.modalPkcs12.show();
  }

  handleKeyEnterP12(event) {
    event.preventDefault();
    this.saveP12Certif();
  }

  saveP12Certif() {
    const file = this.p12File;
    const formData = new FormData();
    formData.append('fileP12ToUpload', file, file.name);
    formData.append('hoteId', this.hote.id);
    formData.append('makeCertificateActive', this.makeCertificateActive ? 'true' : 'false');

    if (this.hote.p12Password) {
      formData.append('passwordForP12File', this.hote.p12Password);
    }
    this.http
      .post(Global.baseUrl + 'certificat/uploadP12File', formData, {
        headers: { formData: 'true' },
        reportProgress: true,
        observe: 'events',
      })
      .subscribe((res: any) => {
        this.hote.p12Password = null;
        if (res.body && !res.body.hasError) {
          this.modalPkcs12.hide();
          this.loadPkcs12.nativeElement.value = '';
          this.p12File = null;
          this.getCertificat();
          this.toastr.success(this.translate.instant('CertificatAjoutSucces'));
        } else if (res.body && res.body.hasError) {
          if (res.body.status.message == 'WRONG_P12_PASSWORD') {
            this.toastr.error(this.translate.instant('WrongP12Password'));
          } else if (res.body.status.message == 'CERT_EXPIRED') {
            this.toastr.error(this.translate.instant('CertificatExpire'));
          } else if (res.body.status.message.includes('NOT_SUITABLE')) {
            this.toastr.error(this.translate.instant('CertificateDoesNotFit') + this.hote.nom);
          }
        }
      });
  }

  confirmCreateCertCertChain() {
    const fullChain = `${this.crt.cert || ''}${this.crt.chain ? `\n${this.crt.chain}` : ''}`;
    if (fullChain === '') {
      return;
    }
    this.createFullChain(this.crt.id, fullChain);
  }

  clearInputCerts() {
    this.clearCert.nativeElement.value = '';
    this.crt.certName = '';
    this.clearChain.nativeElement.value = '';
    this.crt.chainName = '';
  }

  handleCert(files: FileList) {
    this.crt.certName = files.item(0).name;
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.crt.cert = fileReader.result;
    };
    fileReader.readAsText(files.item(0));
  }

  handleChain(files: FileList) {
    this.crt.chainName = files.item(0).name;
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.crt.chain = fileReader.result;
    };
    fileReader.readAsText(files.item(0));
  }

  createFullChain(id: number, chain: any) {
    const request = {
      datas: [
        {
          hoteId: this.hote.id,
          id: id,
          fullChainCert: chain,
        },
      ],
    };
    this.http.post(Global.baseUrl + 'certificat/update', request).subscribe((res: any) => {
      if (!res.hasError) {
        this.getCertificat();
        this.crt = {};
        this.modalAddCchain.hide();
        this.toastr.success(this.translate.instant('AjoutCertificatSucces'));
      } else {
        this.toastr.error(res.status.message);
        this.crt = {};
      }
      this.clearInputCerts();
      this.modalAddCchain.hide();
    });
  }

  downloadCertificate(str: string, name: string) {
    if (str == null || str === '') {
      this.toastr.info(this.translate.instant('ElementTelechargeVide'));
      return;
    }
    const blob = new Blob([str], {
      type: 'text/plain;charset=utf-8',
    });
    saveAs(blob, name);
  }
}
