import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BasicLoginComponent } from './basic-login.component';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  {
    path: '',
    component: BasicLoginComponent,
    data: {
      title: 'Simple Login',
    },
  },
];

@NgModule({
  imports: [FormsModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BasicLoginRoutingModule {}
