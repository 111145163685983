<div class="card with-tabs">
  <form #profileForm="ngForm">
    <div class="col-md-12">
      <div class="row" style="padding-top: 15px">
        <div class="col-md-8 mt-2">
          <h4 [innerHTML]="'MyParameters' | translate"></h4>
        </div>
        <div class="col-md-4">
          <button
            type="button"
            (click)="updateUser()"
            [hidden]="!editField"
            class="btn btn-primary btn-sm pull-right"
            [innerHTML]="'Enregistrer' | translate"
          ></button>
          <button
            type="button"
            (click)="cancelEditUser()"
            [hidden]="!editField"
            class="btn btn-danger btn-sm pull-right mr-2"
            [innerHTML]="'Annuler' | translate"
          ></button>
          <button
            type="button"
            (click)="onEditField()"
            [hidden]="editField"
            class="btn btn-primary btn-sm pull-right"
            [innerHTML]="'Modifier' | translate"
          ></button>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-8 mt-2 mb-3">
          <h5 [innerHTML]="'PersonalInformations' | translate"></h5>
        </div>
        <div class="col-md-12">
          <div class="form-group row">
            <label class="col-sm-6 col-form-label" [innerHTML]="'Nom' | translate"></label>
            <div class="col-sm-6">
              <input type="text" [(ngModel)]="user.lastName" name="nom" [disabled]="!editField" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label" [innerHTML]="'Prenom' | translate"></label>
            <div class="col-sm-6">
              <input
                type="text"
                [(ngModel)]="user.firstName"
                name="prenoms"
                [disabled]="!editField"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label" [innerHTML]="'AdresseMail' | translate"></label>
            <div class="col-sm-6">
              <input type="text" [(ngModel)]="user.email" name="email" [disabled]="!editField" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label" [innerHTML]="'Langue' | translate"></label>
            <div class="col-sm-6">
              <select [(ngModel)]="user.lang" name="lang" [disabled]="!editField" class="form-control">
                <option value="fr">{{ 'FR' | translate }}</option>
                <option value="en">{{ 'EN' | translate }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-2 mb-3">
          <h5 [innerHTML]="'Authentication' | translate"></h5>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <div class="flex-align-items space-between">
            <label>{{ 'MFAEmail' | translate }}</label>
            <div class="switch-container">
              <span>{{ 'Désactivé' | translate }}</span>
              <label class="switch">
                <input
                  type="checkbox"
                  [(ngModel)]="user.emailAuthentication"
                  name="emailAuthentication"
                  [ngStyle]="{ cursor: editField ? 'pointer' : 'not-allowed' }"
                  [disabled]="!editField"
                />
                <span
                  class="slider round"
                  [ngStyle]="{ opacity: editField ? '1' : '0.5', cursor: editField ? 'pointer' : 'not-allowed' }"
                ></span>
              </label>
              <span>{{ 'Activé' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-8 col-form-label">
          {{ 'API Key' | translate }}
          <label class="badge badge-sm bg-dark" placement="bottom" ngbTooltip="{{ 'APIKeyTooltip' | translate }}"
            >?</label
          >
        </label>
        <div class="col-sm-4 flex-align-items">
          <input type="text" [(ngModel)]="apiKey" name="apiKey" [disabled]="true" class="form-control" />
          <div
            *ngIf="hasApiKey"
            (click)="showKey()"
            ngbTooltip="{{ 'ShowKey' | translate }}"
            placement="top"
            container="body"
            class="input-prefix pointer"
            style="right: 16px"
          >
            <div style="position: relative; top: 5px"><i class="icofont icofont-eye"></i></div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-8 mt-2">
          <h4>{{ 'ChangPassw' | translate }}</h4>
        </div>
        <div class="col-md-4">
          <button
            type="button"
            (click)="onEditPassword()"
            [hidden]="editPassword"
            class="btn btn-primary btn-sm pull-right"
            [innerHTML]="'Modifier' | translate"
          ></button>
          <button
            *ngIf="editPassword"
            type="button"
            (click)="resetPassword()"
            [hidden]="!editPassword"
            class="btn btn-primary btn-sm pull-right"
            [innerHTML]="'Enregistrer' | translate"
            [disabled]="pswd.password !== pswd.password2 || !pswd.ancienPassword || !pswd.password || !pswd.password2"
          ></button>
          <button
            type="button"
            (click)="editPassword = false"
            [hidden]="!editPassword"
            class="btn btn-danger btn-sm pull-right mr-2"
            [innerHTML]="'Annuler' | translate"
          ></button>
        </div>
        <div class="col-md-12">
          <hr />
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label [innerHTML]="'AncienMotDePasse' | translate"></label>
            <input
              type="password"
              [(ngModel)]="pswd.ancienPassword"
              name="ancienPpassword"
              class="form-control"
              [disabled]="!editPassword"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label [innerHTML]="'NouveauMotDePasse' | translate"></label>
            <input
              type="password"
              [(ngModel)]="pswd.password"
              name="password"
              class="form-control"
              [disabled]="!editPassword"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label [innerHTML]="'ConfirmationNouveauMotdePasse' | translate"></label>
            <input
              type="password"
              [(ngModel)]="pswd.password2"
              name="password2"
              class="form-control"
              [disabled]="!editPassword"
            />
            <div *ngIf="editPassword">
              <div *ngIf="pswd.password == pswd.password2; else passwordNot">
                <small [hidden]="pswd.password == undefined || pswd.password2 == undefined">{{
                  'PasswordsOK' | translate
                }}</small>
              </div>
              <ng-template #passwordNot>
                <small>{{ 'PasswordsNOK' | translate }}</small>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="editPassword" class="row">
        <div class="col-md-12 form-group">
          {{ 'PasswordRestrictions' | translate }}
        </div>
      </div>
    </div>
  </form>
</div>
