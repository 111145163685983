import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts/highmaps';
import { CountryGraphType } from 'app/theme/dashboard/dashboard.component';
import { WorldMapService } from 'app/services/world-map.service';
import { TRAFIC } from 'app/shared/highcharts/graph/graph';
import { BLOCKED, ROBOT } from 'app/shared/highcharts/donut/donut';
import _ from 'lodash';
import { CountryService } from 'app/services/country.service';
import { AuthService } from 'app/services/auth.service';

export interface CountryClickEvent {
  countryCode: string;
  graphType: CountryGraphType;
}

@Component({
  selector: 'app-world-map',
  templateUrl: './world-map.component.html',
  styleUrls: ['./world-map.component.scss'],
})
export class WorldMapComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() hideTitle: boolean;
  @Input() origine: CountryGraphType;
  @Input() showTopCountryNames = true;
  @Input() height: string = null;

  @Output() countryClick = new EventEmitter<CountryClickEvent>();

  Highcharts: typeof Highcharts = Highcharts;
  options: Highcharts.Options;

  constructor(
    private translate: TranslateService,
    private worldMap: WorldMapService,
    private countryService: CountryService,
    private auth: AuthService,
  ) {}

  async ngOnInit() {
    await this.worldMap.init();
    if (!this.options) {
      this.buildOptions();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.data?.currentValue) {
      return;
    }

    this.buildOptions();
  }

  buildOptions() {
    if (!this.worldMap.getMap()) {
      return;
    }

    const countries = this.countryService.getCountries();
    const worldMap = this.worldMap.getMap();
    const lang = this.auth.getCurrentLanguage();

    // translate countries names if french language
    if (lang == 'fr' && countries?.length) {
      worldMap.features.forEach((c) => {
        if (c.properties.name) {
          c.properties.name = countries.find((country) => country.code == c.properties['iso-a2'])?.name;
        }
      });
    }

    this.options = {
      accessibility: {
        enabled: false,
      },
      chart: {
        map: worldMap,
        borderRadius: 5,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: this.translate.instant(`Origins.title.${this.origine}`),
        style: {
          display: this.hideTitle ? 'none' : 'block',
        },
      },
      legend: {
        align: 'right',
        padding: 20,
        y: 30,
      },
      colorAxis: {
        min: 0,
        stops: stops[this.origine],
      },
      mapNavigation: {
        enabled: true,
        enableMouseWheelZoom: false,
        buttonOptions: {
          verticalAlign: 'bottom',
        },
      },
      plotOptions: {
        map: {
          states: {
            hover: {
              borderWidth: 1,
              borderColor: '#c3c1c1',
            },
          },
        },
        series: {
          point: {
            events: {
              mouseOver: function () {
                if (this.value !== null && typeof this.value !== 'undefined') {
                  this.series.chart.container.style.cursor = 'pointer';
                } else {
                  this.series.chart.container.style.cursor = 'default';
                }
              },
              mouseOut: function () {
                this.series.chart.container.style.cursor = 'default';
              },
              click: (event) =>
                this.countryClick.emit({
                  countryCode: event.point['iso-a2'],
                  graphType: this.origine,
                }),
            },
          },
        },
      },
      series: [
        {
          type: 'map',
          data: this.data,
          joinBy: 'iso-a2',
          keys: ['iso-a2', 'value'],
          name: this.translate.instant(`Graph.seriesName.${this.origine}`),
          dataLabels: {
            allowOverlap: true,
            enabled: this.showTopCountryNames && this.data?.length,
            formatter: function () {
              const topCountries = this.series.data.sort((a, b) => b.y - a.y).slice(0, 5);
              const index = topCountries.findIndex((country) => country['iso-a2'] === this.point['iso-a2']);
              return index !== -1 ? `${index + 1}. ${this.point.name}` : null;
            },
            nullFormat: '',
          },
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            y: -15,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXLS',
            ],
          },
        },
      },
      lang: {
        printChart: this.translate.instant('PrintChart'),
        downloadPNG: this.translate.instant('DownloadPNG'),
        downloadJPEG: this.translate.instant('DownloadJPEG'),
        downloadSVG: this.translate.instant('DownloadSVG'),
        downloadCSV: this.translate.instant('DownloadCSV'),
        downloadXLS: this.translate.instant('DownloadXLS'),
      },
    };

    if (this.height) this.options.chart.height = this.height;
  }
}

const stops: { [prop: string]: [number, string][] } = {
  [TRAFIC]: [
    [0, '#d2efea'],
    [0.2, '#b3ebe0'],
    [0.4, '#93e6d5'],
    [0.6, '#6ee1c9'],
    [0.8, '#3fdbbc'],
  ],
  [ROBOT]: [
    [0, '#fff4cc'],
    [0.2, '#ffeeb3'],
    [0.4, '#fee380'],
    [0.6, '#fed84d'],
    [0.8, '#fed330'],
  ],
  [BLOCKED]: [
    [0, '#d2d100'],
    [0.2, '#e0b200'],
    [0.4, '#e99100'],
    [0.6, '#ed6c00'],
    [0.8, '#eb4112'],
  ],
  blacklisted: [
    [0, '#F60'],
    [1e-30, '#666666'],
    [0.2, '#4D4D4D'],
    [0.4, '#333333'],
    [0.6, '#1A1A1A'],
    [0.8, '#000000'],
  ],
};
