import { ACL_PUSH_LOG_MANAGE, ACL_ORGANIZATION_MANAGE, ACL_ORGANIZATION_USERS_MANAGE, ACL_SITE_EDIT } from '../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-my-organization',
  templateUrl: './my-organization.component.html',
  styleUrls: ['./my-organization.component.scss'],
})
export class MyOrganizationComponent implements OnInit {
  activeTab = 'parameters';

  ACL_SITE_EDIT = ACL_SITE_EDIT;
  ACL_PUSH_LOG_MANAGE = ACL_PUSH_LOG_MANAGE;
  ACL_ORGANIZATION_MANAGE = ACL_ORGANIZATION_MANAGE;
  ACL_ORGANIZATION_USERS_MANAGE = ACL_ORGANIZATION_USERS_MANAGE;

  constructor(
    private route: ActivatedRoute,
    public auth: AuthService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length) {
        this.activeTab = params['tab'];
      } else {
        this.activeTab = 'parameters';
      }
    });
  }
}
