import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { capitalize } from 'app/shared/utils/strings';
import {
  ActionEnum,
  BandwidthHistograms,
  CacheStatus,
  CacheStatusFilters,
  DateHistograms,
  ResponseTimeHistograms,
} from 'app/services/logs.service';
import { ANALYZED, BLOCKED, ROBOT, SUSPECT } from 'app/shared/highcharts/donut/donut';
import { DateRange } from 'app/shared/utils/date-range';

export const TRAFIC = 'traffic';
export const TRAFIC_SORT_BY_BLOCKED = 'traficSortByBlocked';
export const RESPONSE_TIME = 'responseTime';
export const RESPONSE_TIME_SUM = 'responseTimeSum';
export const RESPONSES_CODES = 'responsesCodes';
export const BANDWIDTH = 'bandwidth';

const legendYx = 7;
const timeIntervals = [1, 5, 10, 30, 60, 300, 600, 1800, 3600, 10800, 21600, 43200, 86400, 172800, 604800];
const intervalsBarOptimum = 50;
const baseChart: any = {
  chart: {
    type: 'spline',
    shadow: false,
    borderWidth: 0,
    spacing: [40, 30, 20, 20],
    zoomType: 'x',
    style: {
      cursor: 'crosshair',
    },
  },
  title: null,
  animation: false,
  zooming: {
    resetButton: {
      theme: {
        style: {
          display: 'none',
        },
      },
    },
  },
  noData: {
    style: {
      fontWeight: 'normal',
      fontSize: '24px',
      color: 'rgb(34, 34, 34)',
    },
  },
  accessibility: {
    enabled: false,
  },
  time: {
    useUTC: false,
  },
  xAxis: {
    type: 'datetime',
    currentDateIndicator: true,
  },
  legend: {
    enabled: true,
    itemStyle: {
      fontSize: '0.7em',
      textOverflow: 'ellipsis',
    },
    sort: (a, b) => a.name.localeCompare(b.name),
  },
  plotOptions: {
    series: {
      animation: false,
      connectNulls: false,
    },
    spline: {},
    line: {
      showInLegend: true,
    },
    column: {
      showInLegend: true,
      stacking: 'normal',
      maxPointWidth: 10,
    },
  },
  credits: {
    enabled: false,
  },
};

export function computeInterval(periode: DateRange): number {
  const interval = Math.abs(periode.end.valueOf() - periode.start.valueOf()) / 1000;
  return _.minBy(timeIntervals, (i) => Math.abs(intervalsBarOptimum - interval / i));
}

export type GraphType = typeof TRAFIC | typeof RESPONSE_TIME | typeof RESPONSES_CODES | typeof BANDWIDTH;

export type RepartitionType =
  | typeof TRAFIC
  | typeof TRAFIC_SORT_BY_BLOCKED
  | typeof RESPONSE_TIME
  | typeof RESPONSE_TIME_SUM
  | typeof RESPONSES_CODES
  | typeof BANDWIDTH;

export const TrafficHistogramTypes = ['traffic', 'attack', 'robot', 'suspect', 'analyzed'] as const;
export type TrafficHistogramType = (typeof TrafficHistogramTypes)[number];

@Injectable()
export class Graph {
  constructor(private translate: TranslateService) {}

  formatGraphData(type: GraphType, data: DateHistograms<any, any> | any, showTitle: boolean) {
    return this.formatData[type](data, showTitle);
  }

  formatData = {
    [TRAFIC]: (histograms: DateHistograms<TrafficHistogramType, any>, showTitle: boolean) =>
      this.formatDataTrafic(histograms, showTitle),
    [RESPONSE_TIME]: (histograms: ResponseTimeHistograms, showTitle: boolean) =>
      this.formatDataResponseTime(histograms, showTitle),
    [RESPONSES_CODES]: (histograms: DateHistograms<TrafficHistogramType, any>, showTitle: boolean) =>
      this.formatDataResponsesCodes(histograms, showTitle),
    [BANDWIDTH]: (histograms: BandwidthHistograms, showTitle: boolean) =>
      this.formatDataBandwidth(histograms, showTitle),
  };

  getBaseChart() {
    return JSON.parse(JSON.stringify(baseChart));
  }

  formatDataTrafic(histograms: DateHistograms<TrafficHistogramType, any>, showTitle: boolean) {
    let { chart } = this.getBaseChart();
    chart.borderRadius = 5;
    chart.type = 'column';

    return {
      ...baseChart,
      chart,
      tooltip: {
        valueSuffix: `&nbsp${this.translate.instant('requests').charAt(0).toLowerCase()}${this.translate.instant('requests').substring(1)}`,
      },
      title: {
        text: showTitle ? this.translate.instant('AnalyseTrafic') : null,
        align: 'center',
        y: -10,
        style: {
          fontSize: '9px',
        },
      },
      yAxis: [
        {
          allowDecimals: false,
          title: {
            text: this.translate.instant(`Graph.seriesName.${TRAFIC}`),
            x: legendYx * -1,
          },
        },
        {
          allowDecimals: false,
          opposite: true,
          title: {
            text: this.translate.instant(`Graph.seriesName.${BLOCKED}`),
            x: legendYx,
          },
          labels: {
            style: {
              color: '#FF5370',
            },
          },
        },
      ],
      series: [
        {
          id: TRAFIC,
          zIndex: 1,
          yAxis: 0,
          color: '#c3c3c3',
          type: 'area',
          name: this.translate.instant(`Graph.seriesName.${TRAFIC}`),
          data: histograms.traffic.data,
          opacity: 0.5,
        },
        {
          id: BLOCKED,
          zIndex: 3,
          yAxis: 1,
          color: '#FF5370',
          type: 'column',
          name: this.translate.instant(`Graph.seriesName.${BLOCKED}`),
          data: histograms.attack.data,
          custom: {
            category: TRAFIC,
            slug: ActionEnum.BLOCKED,
          },
        },
        {
          id: ROBOT,
          zIndex: 3,
          yAxis: 0,
          color: '#fed330',
          type: 'line',
          name: this.translate.instant(`Graph.seriesName.${ROBOT}`),
          data: histograms.robot.data,
        },
        {
          id: SUSPECT,
          zIndex: 3,
          yAxis: 1,
          color: '#f7a35c',
          type: 'column',
          name: this.translate.instant(`Graph.seriesName.${SUSPECT}`),
          data: histograms.suspect.data,
          custom: {
            category: TRAFIC,
            slug: ActionEnum.SUSPICIOUS,
          },
        },
        {
          id: ANALYZED,
          zIndex: 2,
          yAxis: 0,
          color: '#3fdbbc',
          type: 'line',
          name: this.translate.instant(`Graph.seriesName.${ANALYZED}`),
          data: histograms.analyzed.data,
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            align: 'right',
            x: 10,
            y: -10,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXLS',
            ],
          },
        },
      },
    };
  }

  formatDataResponseTime(histograms: ResponseTimeHistograms, showTitle: boolean) {
    const translateKey = 'Percentile';
    const seriesData = [
      {
        type: 'area',
        zIndex: 1,
        yAxis: 0,
        color: '#c3c3c3',
        name: this.translate.instant('requests'),
        data: sortTimeSeries(histograms.traffic.data),
        opacity: 0.5,
        tooltip: {
          valueSuffix: `&nbsp${capitalize(this.translate.instant('requests'))}`,
        },
      },
      ..._.toPairs(histograms.responseTime).map(([name, histogram]) => ({
        type: 'spline',
        zIndex: 3,
        custom: { percentile: name, category: RESPONSE_TIME },
        name: this.translate.instant(translateKey, { p: name }),
        color: percentileColors[name],
        data: sortTimeSeries(histogram.data.map(([date, value]) => [date, Math.trunc(value)])),
        yAxis: 1,
        tooltip: {
          valueSuffix: '&nbsp;ms',
        },
      })),
    ];

    return {
      ...baseChart,
      title: {
        text: showTitle ? this.translate.instant('ResponseTime') : null,
      },
      yAxis: [
        {
          allowDecimals: false,
          title: {
            text: this.translate.instant('requests'),
            x: legendYx * -1,
          },
        },
        {
          crosshair: true,
          opposite: true,
          title: {
            text: this.translate.instant('ResponseTime') + ' (ms)',
            x: legendYx,
          },
        },
      ],
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            align: 'right',
            x: 10,
            y: -10,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXLS',
            ],
          },
        },
      },
    };
  }

  formatDataBandwidth(data: BandwidthHistograms, showTitle: boolean) {
    const translateKey = 'Percentile';
    const seriesData = [
      ..._.toPairs(data.contentLength).map(([name, histogram]) => ({
        type: 'spline',
        zIndex: 2,
        custom: { percentile: name, category: BANDWIDTH },
        name: this.translate.instant(translateKey, { p: name }),
        color: percentileColors[name],
        data: sortTimeSeries(histogram.data.map(([date, value]) => [date, Math.trunc(value)])),
        yAxis: 1,
        tooltip: {
          valueSuffix: '&nbsp;' + this.translate.instant('bytes'),
        },
      })),
      ...cacheStatusOrder.reverse().map((cacheStatus) => ({
        zIndex: 1,
        yAxis: 0,
        color: cacheStatusColors[cacheStatus],
        type: 'area',
        stacking: 'normal',
        name: this.translate.instant(`CacheStatus.${cacheStatus}`),
        data: sortTimeSeries(data.cacheStatus[cacheStatus].data),
        opacity: 0.5,
        tooltip: {
          valueSuffix: ` ` + this.translate.instant('bytes'),
        },
        lineWidth: 0,
        marker: {
          enabled: false,
        },
      })),
    ];

    return {
      ...baseChart,
      title: {
        text: showTitle ? this.translate.instant('BandwidthReport.title') : null,
      },
      yAxis: [
        {
          allowDecimals: false,
          title: {
            text: this.translate.instant('BandwidthReport.OutputSizeTitle'),
            x: legendYx * -1,
          },
        },
        {
          crosshair: true,
          opposite: true,
          title: {
            text: this.translate.instant('BandwidthReport.ContentLengthTitle'),
            x: legendYx,
          },
        },
      ],
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            align: 'right',
            x: 10,
            y: -10,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXLS',
            ],
          },
        },
      },
    };
  }

  formatDataResponsesCodes(histograms: DateHistograms<string, {}>, showTitle: boolean) {
    const codesColorMap = {
      1: 'blue',
      2: '#2ed8b6',
      3: 'yellow',
      4: '#f7a35c',
      5: '#ff5370',
    };

    const seriesData = _.map(histograms, (histogram, code) => ({
      name: code,
      color: codesColorMap[code.charAt(0)],
      custom: {
        category: RESPONSES_CODES,
        slug: code,
      },
      grouping: false,
      data: histogram.data,
    }));

    let { chart } = this.getBaseChart();
    chart.type = 'column';
    chart.spacing = [40, 60, 20, 20];

    return {
      ...baseChart,
      chart,
      title: {
        text: showTitle ? this.translate.instant('StatusCodes') : null,
      },
      tooltip: {
        valueSuffix: `&nbsp${this.translate.instant('requests').charAt(0).toLowerCase()}${this.translate.instant('requests').substring(1)}`,
      },
      yAxis: {
        title: {
          text: this.translate.instant('requests'),
          x: legendYx * -1,
        },
      },
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            align: 'right',
            x: 40,
            y: -10,
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXLS',
            ],
          },
        },
      },
    };
  }
}

function sortTimeSeries(data) {
  return _.sortBy(data, ([date]) => date);
}

const percentileColors = { '95': '#f94e3f', '75': '#f6b352', '50': '#efdc05' };
const cacheStatusColors: { [key in CacheStatus]: string } = { NONE: '#c3c3c3', MISS: 'grey', HIT: 'green' };
const cacheStatusOrder: CacheStatus[] = [CacheStatusFilters.HIT, CacheStatusFilters.MISS, CacheStatusFilters.NONE];
