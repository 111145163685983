<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
  <!-- Container-fluid starts -->
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="login-card card-block auth-body mr-auto ml-auto">
          <form class="md-float-material">
            <div class="text-center" class="ogo-logo-login">
              <img src="assets/images/logo.png" alt=".OGO Security" />
            </div>
            <div class="auth-box">
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h3 class="text-left txt-primary">Connexion</h3>
                  <p class="text-inverse text-left">Veuillez rentrer le code de validation reçu par mail</p>
                </div>
              </div>
              <code-input #codeInput [code]="code" [codeLength]="codeLength" (codeChanged)="onCodeChanged($event)" (codeCompleted)="onCodeCompleted($event)"> </code-input>
              <div class="m-t-20" *ngIf="timeLeft == 0 && !loading">
                <p class="ogo-link" (click)="sendCodeAgain()">
                  <i class="icon-reload"></i>
                  Envoyer un code à nouveau
                </p>
              </div>
              <div class="m-t-20">
                <p class="ogo-link" style="cursor: default" *ngIf="loading">
                  <i class="icofont icofont-spinner mfa-loader"></i>
                  Vérification...
                </p>
              </div>
              <div class="m-t-20">
                <p [ngStyle]="{ color: failCodeCount > 0 ? '#f90000' : '#222222' }">{{ statusCode }}</p>
              </div>
              <div class="row m-t-40">
                <a [routerLink]="['/' + AUTH_LOGIN_ROUTE]" style="color: #4099ff; margin-left: auto; margin-right: 17px">← Revenir</a>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-10">
                  <p class="text-inverse text-left m-b-0">
                    <a [href]="EXTERNAL_COMPANY_WEBSITE_LINK" target="_blank">www.ogosecurity.com</a>
                  </p>
                </div>
              </div>
            </div>
          </form>
          <!-- end of form -->
        </div>
        <!-- Authentication card end -->
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container-fluid -->
</section>
