<div class="row">
  <div class="col-md-12 ml-2 mb-2" style="padding-left: 8px">
    <div class="btn-group" role="group">
      <button
        *ngFor="let item of clusters"
        type="button"
        (click)="change(item)"
        class="btn"
        [class.btn-secondary]="cluster.id !== item.id"
        [class.btn-primary]="cluster.id === item.id"
        [style.background]="item.nom == 'admin' && '#44a437'"
        [style.boxShadow]="cluster.id === item.id && 'inset 0px 0px 0px 1px #6b6b6b'"
      >
        {{ item.nom }}
      </button>
    </div>
  </div>
</div>
