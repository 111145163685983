<app-auto-logout></app-auto-logout>

<div
  id="pcoded"
  class="pcoded"
  [attr.nav-type]="navType"
  [attr.theme-layout]="themeLayout"
  [attr.vertical-placement]="verticalPlacement"
  [attr.vertical-layout]="verticalLayout"
  [attr.pcoded-device-type]="pcodedDeviceType"
  [attr.vertical-nav-type]="verticalNavType"
  [attr.vertical-effect]="verticalEffect"
  [attr.vnavigation-view]="vnavigationView"
  [attr.fream-type]="freamType"
  [attr.sidebar-img]="sidebarImg"
  [attr.sidebar-img-type]="sidebarImgType"
  [attr.layout-type]="layoutType"
  (window:resize)="onResize($event)"
>
  <div class="pcoded-overlay-box"></div>
  <div class="pcoded-container navbar-wrapper">
    <nav
      class="navbar header-navbar pcoded-header"
      [attr.header-theme]="headerTheme"
      [attr.pcoded-header-position]="pcodedHeaderPosition"
    >
      <div class="navbar-wrapper">
        <div class="navbar-logo">
          <a
            class="mobile-menu"
            id="mobile-collapse"
            (click)="toggleOpened()"
            [exclude]="'#main_navbar'"
            (clickOutside)="onClickedOutsideSidebar($event)"
          >
            <i class="icon-menu"></i>
          </a>
          <a class="ogo-logo" [routerLink]="['/' + DASHBOARD_ROUTE]">
            <img style="width: 35%" class="img-fluid" src="assets/images/logo.png" alt="Theme-Logo" />
          </a>
          <a class="mobile-options" (click)="toggleHeaderNavRight()">
            <i class="icon-more"></i>
          </a>
        </div>
        <div class="navbar-container container-fluid">
          <ul class="nav-left">
            <li>
              <div class="sidebar_toggle">
                <a>
                  <i class="icon-menu"></i>
                </a>
              </div>
            </li>
            <li>
              <div class="search-results"></div>
            </li>
            <li></li>
            <a
              *ngIf="auth.userHasFeature(ACL_SITE_EDIT) && !hideAddDomain && auth.currentOrganization.clusters.length"
              class="add-domain"
              style="line-height: 54px; margin-top: 15px; color: #fff"
              (click)="addSite()"
            >
              <strong [innerHTML]="'AjouterUnSite' | translate"></strong>&nbsp;
              <i class="btn-add-domain icofont icofont-plus-circle" style="font-size: 20px"></i>
            </a>
          </ul>
          <ul [@mobileHeaderNavRight]="navRight" [ngClass]="navRight" class="nav-right">
            <li
              (click)="toggleChangeOrganization()"
              (clickOutside)="notificationOutsideClick('organization')"
              class="organization user-profile header-notification"
              [ngClass]="changeOrganizationnizationClass"
            >
              {{ auth.currentOrganization?.companyName }}
              <ul [@notificationBottom]="changeOrganization" class="show-notification profile-notification">
                <ng-template ngFor let-organization [ngForOf]="auth.organizations" let-i="index">
                  <li
                    *ngIf="auth.currentOrganization?.id != organization.id"
                    class="flex-align-items"
                    (click)="changeCurrentOrganization(organization.id)"
                  >
                    <span>{{ organization.companyName }}</span>
                  </li>
                </ng-template>
                <li [routerLink]="['/' + ADD_ORGANIZATION_ROUTE]" class="flex-align-items pointer ogo-link">
                  + {{ 'CreateNewOrganization' | translate }}
                </li>
              </ul>
            </li>
            <li
              (click)="toggleProfileNotification()"
              (clickOutside)="notificationOutsideClick('profile')"
              class="user-profile header-notification"
              [ngClass]="profileNotificationClass"
            >
              <a class="profile-dropdown">
                <span>{{
                  auth?.user?.nom && auth?.user?.prenoms ? auth.user.nom + ' ' + auth.user.prenoms : auth?.user?.email
                }}</span>
                <i class="icon-angle-down"></i>
              </a>
              <ul [@notificationBottom]="profileNotification" class="show-notification profile-notification">
                <li
                  [routerLink]="['/' + MY_PROFILE_ROUTE]"
                  [queryParams]="{ tab: 'parameters' }"
                  class="flex-align-items pointer"
                >
                  <i class="icon-user"></i><span [innerHTML]="'MyProfile' | translate"></span>
                </li>
                <li
                  [routerLink]="['/' + MY_ORGANIZATION_ROUTE]"
                  [queryParams]="{ tab: 'parameters' }"
                  class="flex-align-items pointer"
                >
                  <i class="icon-briefcase" style="font-size: 13px"></i
                  ><span [innerHTML]="'MyOrganization' | translate"></span>
                </li>
                <li class="flex-align-items pointer" (click)="goToDocumentation()">
                  <i class="icon-book"></i><span [innerHTML]="'Documentation' | translate"></span>
                </li>
                <li (click)="logout()" class="flex-align-items pointer" style="color: #ff5370">
                  <i class="icon-power-off"></i><span [innerHTML]="'Deconnexion' | translate"></span>
                </li>
              </ul>
            </li>
            <li>
              <ul class="nav navbar-nav navbar-right">
                <li>
                  <a>
                    <span
                      [ngClass]="{
                        'selected-lang': auth.getCurrentLanguage() == 'en'
                      }"
                      (click)="changeLang('en')"
                      class="lang-selector"
                      >EN</span
                    >
                    |
                    <span
                      [ngClass]="{
                        'selected-lang': auth.getCurrentLanguage() == 'fr'
                      }"
                      (click)="changeLang('fr')"
                      class="lang-selector"
                    >
                      FR</span
                    >
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="pcoded-main-container" [style.margin-top]="headerFixedMargin">
      <div class="pcoded-wrapper">
        <nav
          id="main_navbar"
          class="pcoded-navbar"
          (clickOutside)="onClickedOutsideSidebar($event)"
          [exclude]="'#mobile-collapse'"
          [attr.pcoded-header-position]="pcodedHeaderPosition"
          [attr.navbar-theme]="navBarTheme"
          [attr.active-item-theme]="activeItemTheme"
          sub-item-theme="theme2"
          active-item-style="style0"
          [attr.pcoded-navbar-position]="pcodedSidebarPosition"
        >
          <div class="pcoded-inner-navbar main-menu" appAccordion>
            <span *ngFor="let asideItems of menuItems.getAll()" class="d-color">
              <div class="pcoded-navigation-label" [attr.menu-title-theme]="menuTitleTheme">
                {{ asideItems.label }}
              </div>
              <div>
                <ul
                  class="pcoded-item pcoded-left-item"
                  [attr.item-border]="itemBorder"
                  [attr.item-border-style]="itemBorderStyle"
                  [attr.subitem-border]="subItemBorder"
                  *ngFor="let asideItem of asideItems.main"
                  appAccordionLink
                  group="{{ asideItem.state }}"
                >
                  <li
                    [routerLinkActive]="['active']"
                    [appAccess]="asideItem.acl"
                    *ngIf="asideItem.type === 'link'"
                    appAccordionLink
                    group="{{ asideItem.state }}"
                    class="menu-item"
                  >
                    <a
                      (click)="windowWidth < 992 && toggleOpened()"
                      [routerLink]="['/', asideItem.main_state, asideItem.state]"
                      target="{{ asideItem.target ? '_blank' : '_self' }}"
                      appAccordionToggle
                      *ngIf="asideItem.main_state; else mainContent"
                    >
                      <span class="pcoded-micon"
                        ><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span
                      >
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span
                        *ngFor="let asideBadge of asideItem.badge"
                        class="pcoded-badge label label-{{ asideBadge.type }}"
                        >{{ asideBadge.value }}</span
                      >
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <ng-template #mainContent>
                      <a
                        *ngIf="asideItem.state"
                        (click)="windowWidth < 992 && toggleOpened()"
                        [routerLink]="['/', asideItem.state]"
                        target="{{ asideItem.target ? '_blank' : '_self' }}"
                        appAccordionToggle
                      >
                        <span class="pcoded-micon"
                          ><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span
                        >
                        <span class="pcoded-mtext">
                          {{ asideItem.name }}
                          <label *ngIf="asideItem.beta" class="badge badge-sm bg-dark mx-2" style="top: -1px">{{
                            'BETA' | translate
                          }}</label>
                        </span>
                        <span
                          *ngFor="let asideBadge of asideItem.badge"
                          class="pcoded-badge label label-{{ asideBadge.type }}"
                          >{{ asideBadge.value }}</span
                        >
                        <span class="pcoded-mcaret"></span>
                      </a>
                      <a
                        *ngIf="!asideItem.state"
                        href="{{ asideItem.href }}"
                        target="{{ asideItem.target ? '_blank' : '_self' }}"
                        appAccordionToggle
                      >
                        <span class="pcoded-micon"
                          ><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span
                        >
                        <span class="pcoded-mtext">{{ asideItem.name }}</span>
                        <span
                          *ngFor="let asideBadge of asideItem.badge"
                          class="pcoded-badge label label-{{ asideBadge.type }}"
                          >{{ asideBadge.value }}</span
                        >
                        <span class="pcoded-mcaret"></span>
                      </a>
                    </ng-template>
                  </li>
                  <li
                    [routerLinkActive]="['active']"
                    [appAccess]="asideItem.acl"
                    [hidden]="asideItem.type !== 'sub'"
                    class="pcoded-hasmenu"
                    [attr.dropdown-icon]="dropDownIcon"
                    [attr.subitem-icon]="subItemIcon"
                    appAccordionLink
                    group="{{ asideItem.state }}"
                  >
                    <a [routerLinkActive]="['active']" appAccordionToggle class="menu-item">
                      <span class="pcoded-micon">
                        <i class="{{ asideItem.icon }}"></i>
                        <b>{{ asideItem.short_label }} </b>
                      </span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span
                        *ngFor="let asideBadge of asideItem.badge"
                        class="pcoded-badge label label-{{ asideBadge.type }}"
                        >{{ asideBadge.value }}</span
                      >
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <ul class="pcoded-submenu">
                      <ng-template ngFor let-asideChildren [ngForOf]="asideItem.children">
                        <li
                          [routerLinkActive]="['active']"
                          [appAccess]="asideChildren.acl"
                          *ngIf="asideChildren.type !== 'sub'"
                        >
                          <a
                            *ngIf="asideItem.state"
                            (click)="windowWidth < 992 && toggleOpened()"
                            [routerLink]="['/', asideItem.state, asideChildren.state]"
                            target="{{ asideChildren.target ? '_blank' : '_self' }}"
                          >
                            <span class="pcoded-micon">
                              <i class="icon-angle-right"></i>
                            </span>
                            <span class="pcoded-mtext">{{ asideChildren.name }} </span>
                            <span
                              *ngFor="let asideChildrenBadge of asideChildren.badge"
                              class="pcoded-badge label label-{{ asideChildrenBadge.type }}"
                              >{{ asideChildrenBadge.value }}</span
                            >
                            <span class="pcoded-mcaret"></span>
                          </a>
                        </li>
                      </ng-template>
                    </ul>
                  </li>
                </ul>
              </div>
            </span>
          </div>
        </nav>
        <div class="pcoded-content">
          <div class="pcoded-inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <router-outlet> </router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
